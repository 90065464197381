import { motion } from "framer-motion";

const LoadingText = () => {
    return (
        <div className="flex items-center justify-between bg-white p-4 rounded-lg gap-3 dark:dark-surface-300">
            <motion.div
                animate={{ rotate: "360deg" }}
                transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                className="border-4 border-gray-200/50  block rounded-full border-t-[#CC3B3B] size-8 dark:border-t-[#5e43f3]"
            />
            <div className="avx-p">Загрузка...</div>
        </div>
    );
};

export default LoadingText;
