import CloseIcon from "@mui/icons-material/Close";
import WestIcon from "@mui/icons-material/West";
import InnerSettings from "./InnerSettings";
import { settingsItems } from "../utils";
import ThemeSwitcher from "../../../../../../../components/ThemeSwitcher";

interface MobileSettingsInterface {
    tab: "" | "main" | "privacy" | "linked";
    setTab: Function;
    setOpen: Function;
}

const MobileSettings = ({ tab, setTab, setOpen }: MobileSettingsInterface) => {
    return (
        <div className="my-modal rounded-3xl relative h-[550px] w-[87vw] sm:w-[60vw] lg:w-[45vw]">
            <div className="absolute top-0 inset-x-0">
                <div className={`absolute flex bottom-3 inset-x-0 ${!!tab ? "justify-between" : "justify-end"}`}>
                    {!!tab && (
                        <div className="modal-action-icon" onClick={() => setTab("")}>
                            <WestIcon />
                        </div>
                    )}
                    <div className="modal-action-icon" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>
            </div>
            {!tab ? (
                <div className="settings-base h-full flex flex-col">
                    <span className="settings-header">Настройки профиля</span>
                    <div className="flex justify-center items-center  basis-full">
                        <div className="flex flex-col items-center gap-4">
                            {settingsItems.map((el) => (
                                <div
                                    key={el.name}
                                    className="bg-[#E3EBF8] dark:dark-primary-400 rounded-2xl text-[16px] font-bold w-[245px] text-center px-2 py-2"
                                    onClick={() => setTab(el.name)}
                                >
                                    {el.text}
                                </div>
                            ))}
                            <ThemeSwitcher />
                        </div>
                    </div>
                </div>
            ) : (
                <InnerSettings tab={tab} setOpen={setOpen} />
            )}
        </div>
    );
};

export default MobileSettings;
