import axios from "axios";
import { API_URL } from "../http";

export default class AuthService {
    static async login(username: string, password: string) {
        return axios.post(`${API_URL}/token/`, { username: username, password: password });
    }

    static async loginBySSO(next: string = "/") {
        return axios.get(`${API_URL}/auth/sso/login/`, { params: { next } });
    }
}
