import { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SearchUserStore, UserInterface, CompanyStructureStore } from "../store";
import { observer } from "mobx-react-lite";

interface SearchUserInterface {
    store: SearchUserStore;
    treeStore: CompanyStructureStore;
}

const SearchUser = ({ store, treeStore }: SearchUserInterface) => {
    const loading = store.open && store.options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        const fetchUsers = async () => {
            await store.fetchUsers();
        };

        fetchUsers();
        return () => {
            active = false;
        };
    }, [store.open]);

    return (
        <Autocomplete
            className="bg-white w-[350px] dark:dark-surface-300"
            disabled={store.searchLoading || treeStore.loading}
            open={store.open}
            onOpen={() => store.setOpen(true)}
            onClose={() => store.setOpen(false)}
            loading={loading}
            options={store.options}
            renderInput={(params) => {
                return <TextField {...params} color="secondary" label="Поиск сотрудника" />;
            }}
            getOptionKey={(option) => (option.id)}
            value={store.value}
            onChange={(event: any, newValue: UserInterface | null) => {
                store.setValue(newValue);
            }}
            getOptionLabel={(option) => option.name}
            loadingText="Загрузка"
            clearText="Очистить"
            noOptionsText="Нет совпадений"
            size="small"
        />
    );
};

export default observer(SearchUser);
