import { useState, useEffect } from "react";
import MainLayout from "../../../layouts/MainLayout";
import AllNewsStore from "./store";
import { observer } from "mobx-react-lite";
import NewsCard from "./components/NewsCard";
import NewsContext from "./context";
import UpperBlock from "./components/UpperBlock";
import { useInView } from "react-intersection-observer";
import LoadingNewsSkeleton from "./components/LoadingNewsSkeleton";
import { Loading, NoResults, EndOfList } from "../../../components/ListFeedback";

const AllNews = () => {
    const [store] = useState(() => new AllNewsStore());
    const cardHeight = 382;
    const cardRadius = 35;
    let tagsBlockMaxWidth;
    if (window.innerWidth >= 1900) {
        tagsBlockMaxWidth = 900;
    } else if (window.innerWidth >= 1500) {
        tagsBlockMaxWidth = 550;
    } else {
        tagsBlockMaxWidth = 350;
    }
    const gridStyles =
        "grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-7 2xl:grid-cols-4 2xl:gap-x-[46px] 2xl:gap-y-[38px]";

    const { ref, inView } = useInView({
        threshold: 0.7,
    });

    useEffect(() => {
        const fetchNews = async () => {
            await store.fetchNews();
        };
        const fetchTags = async () => {
            await store.fetchTags();
        };
        fetchNews();
        fetchTags();
    }, []);

    useEffect(() => {
        if (inView && store.hasMore && !store.loadingMore) {
            store.setPage(store.page + 1);
        }
    }, [inView]);

    const renderContentBlock = () => {
        if (store.loadingNews) {
            return <LoadingNewsSkeleton gridStyles={gridStyles} />;
        } else if (store.news.length) {
            return (
                <div>
                    <div className={gridStyles}>
                        {store.news.map((el, idx) => (
                            <div key={el.id} ref={idx + 1 === store.news.length ? ref : null}>
                                <NewsCard news={el} />
                            </div>
                        ))}
                    </div>
                    <div className="mt-5 flex justify-center">{store.hasMore ? <Loading /> : <EndOfList />}</div>
                </div>
            );
        }
        return <NoResults />;
    };

    return (
        <MainLayout>
            <NewsContext.Provider
                value={{
                    store: store,
                    cardHeight: cardHeight,
                    cardRadius: cardRadius,
                    tagsBlockMaxWidth: tagsBlockMaxWidth,
                }}
            >
                <UpperBlock />
                {renderContentBlock()}
            </NewsContext.Provider>
        </MainLayout>
    );
};

export default observer(AllNews);
