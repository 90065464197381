import { useContext, useEffect, useState, useRef } from "react";
import MainLayout from "../../../layouts/MainLayout";
import NewsService from "../../../services/NewsService";
import { MyNewsInterface } from "./interfaces";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import DrawIcon from "@mui/icons-material/Draw";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useInView } from "react-intersection-observer";
import api from "../../../http";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageDiv from "./ImageDiv";
import AuthContext from "../../../context/AuthContext";
import CreateNewsSvg from "./CreateNewsSvg";
import { observer } from "mobx-react-lite";
import UpperBlock from "./UpperBlock";
import DOMPurify from "dompurify";
import { Loading, NoResults, EndOfList } from "../../../components/ListFeedback";

const MyNews = () => {
    const navigate = useNavigate();
    const { domainStore } = useContext(AuthContext);
    const pageSize = 10;
    const [news, setNews] = useState<Array<MyNewsInterface>>([]);
    const [search, setSearch] = useState<string>("");
    const [currentTab, setCurrentTab] = useState<string>("");
    const [needScroll, setNeedScroll] = useState<boolean>(false);

    const { ref, inView } = useInView({
        threshold: 0.5,
    });
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (inView && hasMore && !loadingMore) {
            setPage(page + 1);
        }
    }, [inView]);

    useEffect(() => {
        if (page !== 1) {
            setLoadingMore(true);
            const fetchMyNews = async () => {
                try {
                    const res = await NewsService.myNews({
                        search: search,
                        page: page,
                        page_size: pageSize,
                        filter: currentTab,
                    });
                    setHasMore(res.data.next ? true : false);
                    setNews([...news, ...res.data.results]);
                } catch (e) {
                    enqueueSnackbar("Ошибка запроса новостей");
                    console.error(e);
                } finally {
                    setLoadingMore(false);
                }
            };
            setTimeout(() => fetchMyNews(), 0);
        }
    }, [page]);

    useEffect(() => {
        setPage(1);
        let cancel: any;
        api.get(`/news/mine?${currentTab}`, {
            params: { search: search, page: 1, page_size: pageSize },
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((res) => {
                setNews(res.data.results);
                setHasMore(res.data.next ? true : false);
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
            });
        return () => cancel();
    }, [search, currentTab]);

    useEffect(() => {
        scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentTab]);

    useEffect(() => {
        if (page === 1) scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, [page]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollHeight !== scrollRef.current.clientHeight
                ? setNeedScroll(true)
                : setNeedScroll(false);
        }
    }, [news]);

    const clickNewsLink = (newsItem: MyNewsInterface) => {
        navigate(`/news/edit/${newsItem.id}`);
    };

    const statusIcon = (codeName: string) => {
        let icon;
        let color;
        const size = "small";
        switch (codeName) {
            case "draft":
                icon = <DrawIcon fontSize={size} />;
                color = "bg-gray-400";
                break;
            case "on_moderation":
                icon = <HourglassBottomIcon fontSize={size} />;
                color = "bg-yellow-400";
                break;
            case "declined":
                icon = <CloseIcon fontSize={size} />;
                color = "bg-red-500";
                break;
            default:
                icon = <CheckIcon  />;
                color = "bg-green-500";
        }
        return <div className={`${color} p-2 text-white rounded-[17px] ms-2 xl:ms-[25px] xl:p-[17px]`}>{icon}</div>;
    };

    return (
        <MainLayout>
            <div className="xl:flex xl:gap-10">
                <div className="basis-1/2 overflow-y-auto ">
                    <UpperBlock
                        search={search}
                        setSearch={setSearch}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                    />
                    <div
                        id="newsInfinite"
                        ref={scrollRef}
                        className={`mt-4 flex flex-col gap-3 items-center overflow-y-auto xl:mt-7 xl:h-[800px] xl:gap-5 ${
                            needScroll ? "xl:pr-[23px]" : "xl:pr-[29px]"
                        }`}
                    >
                        {news.length > 0 ? (
                            news.map((el, idx) => {
                                return (
                                    <div
                                        className="p-2 rounded-3xl bg-white dark:dark-surface-300 flex items-center w-full xl:rounded-[40px] xl:p-[20px]"
                                        key={el.id}
                                        ref={idx + 1 === news.length ? ref : null}
                                    >
                                        <div className="flex gap-2 items-center border-r-2 basis-full pe-1 xl:pe-[25px] xl:gap-5">
                                            <ImageDiv
                                                src={el.title_image}
                                                onClick={() => clickNewsLink(el)}
                                            />
                                            <div
                                                className="overflow-y-hidden relative card-inner"                                                
                                            >
                                                <div
                                                    className="font-bold text-xs hover:cursor-pointer text-el xl:text-[17px]"
                                                    onClick={() => clickNewsLink(el)}
                                                >
                                                    {el.title}
                                                </div>
                                                <div className="text-xs text-[#9AACEC] font-medium text-el xl:text-[15px] xl:mt-1.5 xl:mb-1">
                                                    {!domainStore.userIsLoading && domainStore.isModerator
                                                        ? `${el.author.last_name} ${el.author.first_name} - ${dayjs(
                                                              el.created_at
                                                          ).format("LLL")}`
                                                        : dayjs(el.created_at).format("LLL")}
                                                </div>
                                                <div
                                                    className="hidden whitespace-pre-wrap break-all font-medium text-sm sm:block xl:text-base"
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(el.text) }}
                                                />
                                                <div className="hidden absolute bottom-0 left-0 h-[60px] bg-gradient-to-b from-white/0 to-white/100 dark:from-[#3F3F3F]/0 dark:to-[#3F3F3F]/100 w-full sm:block"></div>
                                            </div>
                                        </div>
                                        <div title={el.status.name}>{statusIcon(el.status.code_name)}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <NoResults/>
                        )}
                        {news.length > 0 && !hasMore && (
                            <EndOfList/>
                        )}
                        {hasMore && (
                            <Loading/>
                        )}
                    </div>
                </div>
                <div className="hidden basis-1/2 justify-center items-center xl:flex">
                    <div className="flex flex-col justify-center items-center gap-10">
                        <div className="cursor-pointer" onClick={() => navigate("/news/create")}>
                            <CreateNewsSvg />
                        </div>
                        <img src="/CreateNews.jpg" alt="Создать новость" />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default observer(MyNews);
