import Title from "../Login/Title";

const NotFound = () => {
    return (
        <div className="w-full h-[700px] flex justify-center items-center">
            <div className="flex flex-col items-center">
                <div className="mb-5">
                    <Title />
                </div>
                <div className="text-9xl font-extrabold mb-2">404</div>
                <div className="text-2xl">Страница не найдена</div>
                <a className="avilex-color mt-3 hover:underline text-lg" href="/">На главную</a>
            </div>
        </div>
    );
};

export default NotFound;
