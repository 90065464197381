import api from "../http";

interface MyNewsInterface {
    filter: string;
    search?: string;
    page_size?: number;
    page?: number;
}

export default class NewsService {
    static async createNews(formData: FormData, config: object) {
        return api.post("/news-create", formData, config);
    }

    static async updateNews(pk: number, formData: FormData, config: object) {
        return api.put(`/news/edit/${pk}`, formData, config);
    }

    static async performAction(action: "decline", pk: number, refusal_reason: string): Promise<any> {
        switch (action) {
            case "decline":
                return api.patch(`/news-action/${pk}/decline/`, { refusal_reason });
        }
    }

    static async getPublishedNews(page: number, pageSize: number, search: string, tagsFilter: Array<number>) {
        return api.get("/news/published", {
            params: { search: search, page: page, page_size: pageSize, tags: tagsFilter },
            paramsSerializer: {
                indexes: null,
            },
        });
    }

    static async getPublishedNewsItem(pk: number) {
        return api.get(`/news/published/${pk}`);
    }

    static async swapLike(pk: number) {
        return api.patch(`/news-action/${pk}/swap-like/`);
    }

    static async getTags() {
        return api.get("/tags");
    }

    static async myNews({ search, page, page_size = 4, filter }: MyNewsInterface) {
        return api.get(`/news/mine?${filter}`, { params: { search: search, page: page, page_size: page_size } });
    }

    static async myNewsItemRetrieve(pk: number) {
        return api.get(`/news/edit/${pk}`);
    }

    static async sendComment(text: string, news: number | null, parent: number | null) {
        const data = { text } as any;
        if (news) data.news = news;
        if (parent) data.parent = parent;

        return api.post("/comments/", data);
    }

    static async editComment(id: number, text: string) {
        return api.patch(`/comments/${id}/edit/`, { text });
    }

    static async fetchComments(news: number | null, parent: number | null, page: number, page_size: number) {
        const params = { page, page_size } as any;
        if (news) params.news = news;
        if (parent) params.parent = parent;

        return api.get("/comments/", { params: params });
    }

    static async deleteComment(id: number) {
        return api.patch(`/comments/${id}/delete/`);
    }

    static async blockComment(id: number) {
        return api.patch(`/comments/${id}/block/`);
    }
}
