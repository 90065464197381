import ViewNewsStore, { CommentsStore } from "../store";
import { observer } from "mobx-react-lite";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import UpperLevelComment from "./UpperLevelComment";
import NewComment from "./comments/NewComment";

interface CommentsBlockInterface {
    store: ViewNewsStore;
    commentsStore: CommentsStore;
}

const CommentsBlock = ({ store, commentsStore }: CommentsBlockInterface) => {
    const { ref, inView } = useInView({
        threshold: 0.7,
    });

    useEffect(() => {
        if (inView && commentsStore.hasMore && !commentsStore.loadingMore) {
            commentsStore.setPage(commentsStore.page + 1);
        }
    }, [inView]);

    const renderMainComments = () => {
        if (commentsStore.loadingMainComments) {
            return <div className="w-full bg-slate-200 animate-pulse h-10 rounded-[40px] hover:cursor-wait" />;
        } else if (commentsStore.comments.length) {
            return (
                <div className="w-full flex flex-col gap-10">
                    {commentsStore.comments.map((el, idx) => (
                        <div key={el.id} ref={idx + 1 === commentsStore.comments.length ? ref : null}>
                            <UpperLevelComment comment={el} store={commentsStore} />
                        </div>
                    ))}
                    {commentsStore.loadingMore && (
                        <div className="mt-5">
                            <div className="flex justify-center">
                                <div className="flex items-center justify-between bg-white p-4 rounded-lg w-[170px]">
                                    <motion.div
                                        animate={{ rotate: "360deg" }}
                                        transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                                        className="border-4 border-gray-200/50  block rounded-full border-t-[#CC3B3B] size-8"
                                    />
                                    <div className="avx-p">Загрузка...</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div className="text-[17px]">Пока нет комментариев</div>;
        }
    };

    return (
        <div>
            <div className="text-lg mb-3 xl:text-[25px] xl:mb-[27px]">
                <span className="font-bold">Комментарии</span> <span>{store.news?.comments}</span>
            </div>
            <NewComment store={commentsStore} />
            <hr className="my-5 xl:my-[30px] dark:border-[#717171]" />
            <div className="flex justify-center">{renderMainComments()}</div>
        </div>
    );
};

export default observer(CommentsBlock);
