import useImageLoad from "../../hooks/useImageLoad";
import { observer } from "mobx-react-lite";
import PersonIcon from "@mui/icons-material/Person";

interface ProfileImageSkeletonInterface {
    image: string;
    alt?: string;
    className?: string;
}

const ProfileImageSkeleton = ({ image, alt = "Изображение", className }: ProfileImageSkeletonInterface) => {
    const [loaded, error] = useImageLoad(image);

    if (loaded) {
        return <img src={image} className={`rounded-full border-solid border dark:border-[#717171] aspect-square ${className}`} />;
    } else if (error || !image) {
        return (
            <div className={`bg-gray-300 rounded-full flex items-center justify-center aspect-square ${className}`}>
                <PersonIcon className="text-white !text-[0.7em]" />
            </div>
        );
    }
    return <div className={`aspect-square bg-slate-200 animate-pulse rounded-full ${className}`}/>;
};

export default observer(ProfileImageSkeleton);
