import { useContext } from "react";
import { motion } from "framer-motion";
import Logo from "../../../icons/Logo";
import SidebarItem from "./SidebarItem";
import SidebarContext from "./SidebarContext";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import { observer } from "mobx-react-lite";
import "./styles.css";
import { Home, Person, Edu, Company, Documents, News, Audio, Projects, Work } from "./Icons/icons";

interface SideBarProps {
    width: number;
    fullWidth: number;
}

const SideBar = ({ width, fullWidth }: SideBarProps) => {
    const itemVariants = { expanded: { opacity: 1, x: 0 }, collapsed: { opacity: 0, x: -45 } };
    const sidebarVariants = {
        expanded: { width: fullWidth, backgroundColor: "rgba(41, 41, 41, .85)", backdropFilter: "blur(13.6px)" },
        collapsed: { width: width },
    };
    const navigate = useNavigate();
    const { domainStore, uiStore } = useContext(AuthContext);

    return (
        <SidebarContext.Provider value={{ variants: itemVariants, sideBarState: uiStore.sidebarState }}>
            <motion.div
                initial={{
                    width: uiStore.sidebarState === "collapsed" ? width : fullWidth,
                    backgroundColor: "rgba(57, 57, 57)",
                    backdropFilter: "blur(0px)",
                }}
                variants={sidebarVariants}
                animate={uiStore.sidebarState}
                onHoverStart={() => {
                    uiStore.setSidebarState("expanded");
                }}
                onHoverEnd={() => {
                    uiStore.setSidebarState("collapsed");
                    uiStore.setSideBarSelectedItem("");
                }}
                className="fixed overflow-x-hidden text-white pt-[45px] pb-[59px] h-full custom-scrollbar flex flex-col pe-2 z-50"
            >
                <div
                    className="flex items-center overflow-hidden shrink-0 hover:cursor-pointer"
                    onClick={() => navigate("/")}
                >
                    <div className="ml-[16px]">
                        <Logo />
                    </div>
                    <motion.div
                        className="ml-[19px] justify-between flex flex-col h-full"
                        variants={itemVariants}
                        animate={uiStore.sidebarState}
                        initial={{ opacity: uiStore.sidebarState === "collapsed" ? 0 : 1 }}
                        transition={{ ease: "easeOut" }}
                    >
                        <div>
                            <svg
                                width="124"
                                height="19"
                                viewBox="0 0 124 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M120.733 18.6937L116.413 11.8403L112.12 18.6937H108.826L114.847 9.30514L109.204 0.306641H112.498L116.44 6.79782L120.328 0.306641H123.595L118.06 9.30514L124 18.6937H120.733Z"
                                    fill="white"
                                />
                                <path
                                    d="M98.342 19.0001C96.92 19.0001 95.651 18.6937 94.535 18.0808C93.437 17.4679 92.573 16.5764 91.943 15.4063C91.313 14.2362 90.998 12.8525 90.998 11.2553V0.306641H93.86V11.4503C93.86 13.0104 94.274 14.2083 95.102 15.0441C95.948 15.8799 97.028 16.2978 98.342 16.2978C99.638 16.2978 100.7 15.8799 101.528 15.0441C102.356 14.2083 102.77 13.0104 102.77 11.4503V0.306641H105.632V11.2553C105.632 12.8525 105.317 14.2362 104.687 15.4063C104.057 16.5764 103.184 17.4679 102.068 18.0808C100.97 18.6937 99.728 19.0001 98.342 19.0001Z"
                                    fill="white"
                                />
                                <path
                                    d="M77.0137 0.306641H79.8756V16.0471H88.0296V18.6937H77.0137V0.306641Z"
                                    fill="white"
                                />
                                <path
                                    d="M62.1836 0.306641H73.6315V2.95326H65.0456V8.44151H72.1195V11.116H65.0456V18.6937H62.1836V0.306641Z"
                                    fill="white"
                                />
                                <path
                                    d="M57.1371 0.306449V18.9999H56.1651L45.3112 6.24044V18.6935H42.4492V0H43.4482L54.2751 12.7038V0.306449H57.1371Z"
                                    fill="white"
                                />
                                <path
                                    d="M29.1036 18.9999C27.3936 18.9999 25.8187 18.582 24.3787 17.7463C22.9567 16.9105 21.8317 15.7683 21.0037 14.3196C20.1757 12.8709 19.7617 11.2644 19.7617 9.49996C19.7617 7.73555 20.1757 6.12901 21.0037 4.68033C21.8497 3.23166 22.9837 2.08943 24.4057 1.25366C25.8277 0.417886 27.3936 0 29.1036 0C30.7956 0 32.3616 0.417886 33.8016 1.25366C35.2415 2.08943 36.3755 3.23166 37.2035 4.68033C38.0495 6.12901 38.4725 7.73555 38.4725 9.49996C38.4725 11.2644 38.0495 12.8709 37.2035 14.3196C36.3755 15.7683 35.2415 16.9105 33.8016 17.7463C32.3616 18.582 30.7956 18.9999 29.1036 18.9999ZM29.1306 16.2976C30.3366 16.2976 31.4256 16.0097 32.3976 15.434C33.3696 14.8396 34.1346 14.0224 34.6926 12.9824C35.2505 11.9423 35.5295 10.7722 35.5295 9.4721C35.5295 8.20916 35.2505 7.05765 34.6926 6.01757C34.1346 4.9775 33.3696 4.16958 32.3976 3.59383C31.4256 2.9995 30.3366 2.70234 29.1306 2.70234C27.9066 2.70234 26.8086 2.9995 25.8367 3.59383C24.8647 4.16958 24.0997 4.9775 23.5417 6.01757C23.0017 7.03907 22.7317 8.19058 22.7317 9.4721C22.7317 10.7722 23.0017 11.9423 23.5417 12.9824C24.0997 14.0224 24.8647 14.8396 25.8367 15.434C26.8086 16.0097 27.9066 16.2976 29.1306 16.2976Z"
                                    fill="white"
                                />
                                <path
                                    d="M9.3689 18.9999C7.65892 18.9999 6.08393 18.582 4.64395 17.7463C3.22197 16.9105 2.08798 15.7683 1.24199 14.3196C0.413995 12.8709 0 11.2644 0 9.49996C0 7.73555 0.413995 6.12901 1.24199 4.68033C2.08798 3.23166 3.22197 2.08943 4.64395 1.25366C6.08393 0.417886 7.65892 0 9.3689 0C11.0069 0 12.4919 0.380741 13.8238 1.14222C15.1738 1.90371 16.2358 2.93449 17.0098 4.23459L14.8498 5.87828C14.2198 4.85677 13.4459 4.07672 12.5279 3.53811C11.6099 2.98093 10.5659 2.70234 9.3959 2.70234C8.18991 2.70234 7.09192 2.9995 6.10193 3.59383C5.12994 4.16958 4.36495 4.9775 3.80696 6.01757C3.24896 7.05765 2.96997 8.21844 2.96997 9.49996C2.96997 10.7815 3.24896 11.9423 3.80696 12.9824C4.36495 14.0224 5.12994 14.8396 6.10193 15.434C7.09192 16.0097 8.18991 16.2976 9.3959 16.2976C10.5659 16.2976 11.6099 16.0283 12.5279 15.4897C13.4459 14.9325 14.2198 14.1432 14.8498 13.1217L17.0098 14.7653C16.2538 16.0654 15.2008 17.0962 13.8509 17.8577C12.5189 18.6192 11.0249 18.9999 9.3689 18.9999Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div>
                            <svg
                                width="50"
                                height="9"
                                viewBox="0 0 50 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M48.7755 8.85466L46.3799 5.27843L43.9708 8.85466H42.7461L45.8012 4.39427L42.9614 0.14502H44.1727L46.3799 3.5365L48.5736 0.14502H49.7848L46.9855 4.40747L50.0002 8.85466H48.7755Z"
                                    fill="white"
                                />
                                <path
                                    d="M34.3281 0.14502H40.021V1.06877H35.3779V3.73445H38.9309V4.6582H35.3779V7.93091H40.1825V8.85466H34.3281V0.14502Z"
                                    fill="white"
                                />
                                <path
                                    d="M25.9062 0.14502H26.956V7.93091H31.1685V8.85466H25.9062V0.14502Z"
                                    fill="white"
                                />
                                <path d="M20.8086 0.14502H21.8584V8.85466H20.8086V0.14502Z" fill="white" />
                                <path
                                    d="M17.5494 0.14502L13.7003 8.99982H13.31L9.46094 0.14502H10.6184L13.5119 6.98077L16.392 0.14502H17.5494Z"
                                    fill="white"
                                />
                                <path
                                    d="M6.21779 7.08648H1.85726L1.10359 8.8548H0L3.84911 0H4.2394L8.08851 8.8548H6.97146L6.21779 7.08648ZM4.03753 1.87389L2.26101 6.16273H5.81404L4.03753 1.87389Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </motion.div>
                </div>
                <div
                    className="flex flex-col justify-between mt-[55px] basis-full gap-[60px] overflow-y-auto overflow-x-hidden custom-scrollbar"
                    style={{ overflowY: uiStore.sidebarState === "collapsed" ? "hidden" : "visible" }}
                >
                    <div className="flex flex-col gap-[60px]">
                        <SidebarItem name="home" icon={<Home />} onClick={() => navigate("/")} label="Главная" />
                        <div className="flex flex-col gap-[27px] overflow-hidden text-nowrap">
                            <SidebarItem
                                icon={<Person />}
                                label="Профиль"
                                name="profile"
                                onClick={() => navigate("/users/me")}
                            ></SidebarItem>
                            <SidebarItem icon={<Edu />} label="Обучение" disabled></SidebarItem>
                            <SidebarItem
                                icon={<Company />}
                                label="Компания"
                                name="company"
                                subItems={[
                                    // { name: "О компании", path: "/company/about" },
                                    { name: "Структура", path: "/company/structure" },
                                    { name: "Сотрудники", path: "/users/all" },
                                ]}
                            ></SidebarItem>
                            {/* <SidebarItem
                                icon={<Documents />}
                                label="Отпуск"
                                name="vacation"
                                subItems={[{ name: "Планирование", path: "/vacation/planning" }]}
                            /> */}
                        </div>
                        <div className="flex flex-col gap-[27px] overflow-hidden text-nowrap">
                            <SidebarItem
                                icon={<News />}
                                label="Новости"
                                name="news"
                                subItems={[
                                    { name: "Все новости", path: "/news/all" },
                                    { name: "Мои новости", path: "/news/mine" },
                                ]}
                            ></SidebarItem>
                            <SidebarItem icon={<Audio />} label="Переговорные" disabled></SidebarItem>
                            <SidebarItem icon={<Projects />} label="Проекты" disabled></SidebarItem>
                            <SidebarItem icon={<Work />} label="Вакансии" disabled></SidebarItem>
                        </div>
                    </div>
                </div>
            </motion.div>
        </SidebarContext.Provider>
    );
};

export default observer(SideBar);
