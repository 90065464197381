const Edit = () => {
    return (
        <svg className="fill-[#6D6D6D] dark:fill-white" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.4312 11.2125L24.3563 29.2874C24.1532 29.4905 23.8873 29.5983 23.6213 29.6073L16.8866 30.3117C16.1984 30.3835 15.6126 29.8014 15.6952 29.0916L16.405 22.3085C16.432 22.0498 16.547 21.8198 16.7177 21.6491L16.7159 21.6473L34.7908 3.57232C36.9902 1.37295 40.0306 2.17078 41.9315 4.07184L41.9333 4.07005C43.8308 5.96575 44.6286 9.01505 42.4311 11.2123L42.4312 11.2125ZM40.7295 21.239H38.5464V38.162C38.5464 40.0325 37.01 41.5689 35.1395 41.5689H7.83924C5.96869 41.5689 4.43237 40.0325 4.43237 38.162V10.8618C4.43237 8.9894 5.96869 7.45488 7.83924 7.45488H24.7623V5.27168H7.83924C4.76119 5.27168 2.24756 7.78551 2.24756 10.8614V38.1617C2.24756 41.2397 4.76138 43.7514 7.83733 43.7514H35.1376C38.2156 43.7514 40.7273 41.2376 40.7273 38.1598L40.7295 21.239ZM35.0854 6.36449L39.6367 10.9158L40.8855 9.66698C42.1271 8.42533 41.4569 6.68058 40.3878 5.61323L40.3896 5.61143C39.3258 4.54768 37.5739 3.87566 36.3324 5.11548L35.0854 6.36449ZM38.0933 12.4614L33.542 7.91009L18.5291 22.9214L17.9973 28.0048L23.0807 27.4729L38.0933 12.4614Z"
            />
        </svg>
    );
};

export default Edit;
