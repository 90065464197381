import { useState, useContext, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { enqueueSnackbar } from "notistack";
import { ProfileContext } from "../../../../context";
import { Button, Dialog, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { observer } from "mobx-react-lite";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import CloseIcon from "@mui/icons-material/Close";
import { MyProfileStore } from "../../../../stores";

const PhotoDialog = () => {
    const MAX_IMAGE_SIZE = Number(process.env.REACT_APP_MAX_IMAGE_SIZE);
    const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE / 1000;
    const minCropHeight = 100;
    const maxCropHeight = 300;

    const [aspect, setAspect] = useState<number | undefined>(10 / 10);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const { store } = useContext(ProfileContext) as { store: MyProfileStore };

    useEffect(() => {
        if (store.profile?.photo) {
            const fetchImage = async () => {
                let blob = await fetch(store.profile?.photo as string).then((r) => r.blob());

                store.setCrop(undefined);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    store.setPhoto(reader.result?.toString() || "");
                    store.setPhotoAsFile(blob as File);
                });
                reader.readAsDataURL(blob);
            };
            fetchImage();
        }
    }, []);

    useDebounceEffect(
        async () => {
            if (
                store.completedCrop?.width &&
                store.completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(imgRef.current, previewCanvasRef.current, store.completedCrop);
            }
        },
        100,
        [store.completedCrop]
    );

    function onSelectFile(e: React.BaseSyntheticEvent) {
        if (e.target.files.length && e.target.files[0].size > MAX_IMAGE_SIZE * 10 ** 3) {
            enqueueSnackbar(`Размер вложения должен быть до ${MAX_IMAGE_SIZE_MB} Мб`);
            return;
        }

        if (e.target.files && e.target.files.length > 0) {
            store.setCrop(undefined);
            const reader = new FileReader();

            reader.addEventListener("load", (el) => {
                const image = new Image();
                image.src = reader.result as string;

                image.onload = function () {
                    if (image.height < minCropHeight || image.width < minCropHeight) {
                        enqueueSnackbar(`Минимальный размер изображения ${minCropHeight}x${minCropHeight}px`);
                        return;
                    }
                    store.setPhoto(reader.result?.toString() || "");
                    store.setPhotoAsFile(e.target.files[0]);
                };
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "px",
                    width: (maxCropHeight + minCropHeight) / 2,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            store.setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function prepareCrop() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !store.completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen: any = new OffscreenCanvas(
            store.completedCrop.width * scaleX,
            store.completedCrop.height * scaleY
        );

        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        );
        const blob = await offscreen.convertToBlob({
            type: "image/jpeg",
        });
        return blob;
    }

    function handleDialogClose() {
        store.setPhotoDialogOpen(false);
        store.setCrop(undefined);
        store.setCompletedCrop(undefined);
    }

    return (
        <Dialog
            open={store.photoDialogOpen}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    borderRadius: {
                        xl: "25px"
                    },
                    padding: {
                        xs: 1,
                        xl: 3,
                    },
                },
            }}
        >
            <div>
                <div className="flex items-center justify-between">
                    <div className="font-bold xl:text-xl">Выбор миниатюры</div>
                    <IconButton onClick={handleDialogClose} title="Закрыть">
                        <CloseIcon />
                    </IconButton>
                </div>
                <hr className="w-full my-1.5 xl:my-3" />
                <div className="flex flex-col items-center gap-3 text-xs xl:text-base">
                    <div className="text-center">
                        Выберите область для маленьких фотографий. Выбранная миниатюра будет использоваться в новостях,
                        личных сообщениях и комментариях.
                    </div>
                    <div className="flex items-center gap-3">
                        <Button component="label" variant="contained" color="secondary" startIcon={<CloudUploadIcon />}>
                            <span className="text-[9px] xl:text-base text-center">
                                {store.profile?.photo ? "Изменить фото" : "Загрузить фото"}
                            </span>
                            <input type="file" accept="image/*" onChange={onSelectFile} className="hidden" />
                        </Button>
                        <div className="italic text-xs text-center xl:text-sm">
                            Максимальный размер изображения - {MAX_IMAGE_SIZE_MB} Мб
                        </div>
                    </div>
                </div>
                {!!store.photo && (
                    <div className="mt-5 flex flex-col justify-between items-center gap-5 xl:mt-9 xl:flex-row">
                        <div className="flex items-center justify-center xl:basis-1/2">
                            <ReactCrop
                                crop={store.crop}
                                onChange={(_, percentCrop) => store.setCrop(percentCrop)}
                                onComplete={(c) => store.setCompletedCrop(c)}
                                aspect={aspect}
                                circularCrop
                                minHeight={minCropHeight}
                            >
                                <img
                                    ref={imgRef}
                                    src={store.photo}
                                    alt="Crop me"
                                    onLoad={onImageLoad}
                                    className="border border-solid !max-w-[250px] !max-h-[250px] xl:!max-h-[600px] xl:!max-w-[550px]"
                                />
                            </ReactCrop>
                        </div>
                        {!!store.completedCrop && (
                            <div className="flex items-center justify-center xl:basis-1/2">
                                {store.completedCrop.width ? (
                                    <div>
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                                border: "1px solid black",
                                                objectFit: "contain",
                                                borderRadius: "50%",
                                            }}
                                            className="aspect-square !w-[120px] xl:!w-[350px]"
                                        />
                                    </div>
                                ) : (
                                    <div className="">
                                        Выберите область на изображении
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {store.completedCrop && (
                    <div className="flex flex-col gap-3 items-end mt-3">
                        <hr className="w-full" />
                        <Button
                            variant="contained"
                            color="signature"
                            disabled={store.loadingEdit || !store.completedCrop.width}
                            onClick={async () => {
                                const blob = await prepareCrop();
                                await store.editProfilePhoto(blob);
                            }}
                            className="!text-xs xl:!text-base"
                        >
                            Сохранить
                        </Button>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default observer(PhotoDialog);
