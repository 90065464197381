import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { BaseSyntheticEvent } from "react";
import { useRef } from "react";
import { motion } from "framer-motion";

interface SearchInterface {
    placeholder?: string;
    value: string;
    onChange: Function;
    onFocus?: Function;
    onBlur?: Function;
    color?: "white" | "gray";
    onClear?: Function;
}

const Search = ({ placeholder = "", value, onChange, onFocus, onBlur, color = "white", onClear }: SearchInterface) => {
    const ref = useRef<HTMLInputElement>(null);
    const styles = {
        white: {
            bg: "bg-white",
        },
        gray: {
            bg: "bg-[#F4F4F6]",
        },
    };

    const handleClear = (e: BaseSyntheticEvent) => {
        if (onClear) {
            onClear(e);
            if (ref.current) ref.current.focus();
        }
    };

    const handleFocus = (e: BaseSyntheticEvent) => {
        if (onFocus) onFocus(e);
    };

    const handleBlur = (e: BaseSyntheticEvent) => {
        if (onBlur) onBlur(e);
    };

    return (
        <div className={`flex items-center py-[10px] px-[19px] rounded-[64px] text-[#ABABAB] ${styles[color].bg} dark:!dark-surface-400 dark:!text-white`}>
            <div className="mr-[16px]">
                <SearchIcon />
            </div>
            <input
                ref={ref}
                className={`${styles[color].bg} dark:placeholder:text-white/80 dark:!dark-surface-400 avx-p outline-none border-l-2 border-[#ABABAB] dark:border-white ps-[16px] w-full xl:min-w-[300px] rounded-none`}
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                    onChange(e);
                }}
                maxLength={60}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {onClear && value && (
                <motion.div
                    className="flex items-center cursor-pointer"
                    title="Очистить"
                    onClick={handleClear}
                    whileHover={{ color: "#000000" }}
                >
                    <ClearIcon />
                </motion.div>
            )}
        </div>
    );
};

export default Search;
