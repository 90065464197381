import MainLayout from "../../../layouts/MainLayout";
import { observer } from "mobx-react-lite";
import { useEffect, useState, BaseSyntheticEvent } from "react";
import { AllUsersStore } from "../stores";
import { useInView } from "react-intersection-observer";
import ProfileImageSkeleton from "../../../components/skeletons/ProfileImageSkeleton";
import "./styles.css";
import { TextField, Select, MenuItem, FormControl, InputLabel, Autocomplete } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import TelegramIcon from "@mui/icons-material/Telegram";
import UserSocialLinks from "./UserSocialLinks";
import { Loading, NoResults, EndOfList } from "../../../components/ListFeedback";

interface ImageWithPopoverInterface {
    image: string;
    userId: number;
}

const AllUsers = () => {
    const [store] = useState(() => new AllUsersStore());
    const minCardHeight = 64;
    const inputBorderRadius = 37;

    const { ref, inView } = useInView({
        threshold: 0.7,
    });

    useEffect(() => {
        const fetchUsers = async () => {
            await store.fetchUsers();
            await store.fetchCompanies();
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (inView && store.hasMore && !store.loadingMore) {
            store.setPage(store.page + 1);
        }
    }, [inView]);

    const redirectToProfile = (id: number) => {
        window.open(`/users/profile/${id}`);
    };

    const renderContentBlock = () => {
        if (store.loadingUsers) {
            return (
                <div className="users-list">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((el) => {
                        return (
                            <div
                                key={el}
                                style={{ minHeight: minCardHeight }}
                                className="bg-slate-200 animate-pulse rounded-xl"
                            />
                        );
                    })}
                </div>
            );
        } else if (store.users.length) {
            return (
                <div>
                    <div className="users-list">
                        {store.users.map((el, idx) => (
                            <div
                                className="rounded-[21px] bg-white dark:dark-surface-300 py-[13px] flex items-center profile-card xl:rounded-[12px]"
                                style={{ minHeight: minCardHeight }}
                                key={el.id}
                                ref={idx + 1 === store.users.length ? ref : null}
                            >
                                <div className="basis-[19%] min-w-[36px] hover:cursor-pointer flex items-center justify-center xl:basis-[13%]">
                                    <ImageWithPopover image={el.profile.thumbnail} userId={el.id} />
                                </div>
                                <div
                                    className="basis-[44%] border-solid border-r-2 flex items-center cursor-pointer xl:basis-[42%]"
                                    onClick={() => redirectToProfile(el.id)}
                                >
                                    <div>
                                        <div className="text-[13px] font-bold users-text xl:text-[13px] ellipses-text">
                                            {el.last_name} {el.first_name}
                                        </div>
                                        <div className="text-[12px] text-gray-500 dark:dark-text-secondary font-normal users-text ellipses-text xl:text-[12px] xl:text-[#BCBCBC]">
                                            {el.profile.position?.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="basis-[24%] text-[12px] font-medium flex justify-center items-center border-solid border-r-2 xl:text-[13px] xl:basis-[25%]">
                                    <div className="company-text ellipses-text text-center">
                                        {el.profile.department?.company.name}
                                    </div>
                                </div>
                                <div
                                    className={`hidden basis-[10%] justify-center items-center border-solid border-r-2 text-sm xl:flex ${
                                        el.email ? "text-[#00B0E7] hover:cursor-pointer" : "text-[#E9E9E9]"
                                    }`}
                                    onClick={() => {
                                        if (el.email) {
                                            window.location.href = `mailto:${el.email}`;
                                        }
                                    }}
                                    title="Почта"
                                >
                                    <div className="xl:hidden">
                                        <MailIcon fontSize="small" />
                                    </div>
                                    <div className="hidden xl:block">
                                        <MailIcon fontSize="medium" />
                                    </div>
                                </div>
                                <div
                                    className={`basis-[10%] hidden justify-center items-center xl:flex ${
                                        el.profile.telegram_username
                                            ? "text-[#00B0E7] hover:cursor-pointer"
                                            : "text-[#E9E9E9]"
                                    }`}
                                    onClick={() => {
                                        if (el.profile.telegram_username) {
                                            window.open(`https://t.me/${el.profile.telegram_username}`);
                                        }
                                    }}
                                    title="Telegram"
                                >
                                    <div className="xl:hidden">
                                        <TelegramIcon fontSize="small" />
                                    </div>
                                    <div className="hidden xl:block">
                                        <TelegramIcon fontSize="medium" />
                                    </div>
                                </div>
                                <UserSocialLinks mail={el.email} tg={el.profile.telegram_username} />
                            </div>
                        ))}
                    </div>
                    <div className="mt-5 text-sm xl:text-base flex justify-center">{store.hasMore ? <Loading /> : <EndOfList />}</div>
                </div>
            );
        }
        return (
            <NoResults/>
        );
    };

    return (
        <MainLayout>
            <div className="avx-page-h">Все сотрудники</div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mb-[18px] mt-[13px] xl:my-5 xl:flex-nowrap xl:gap-8 xl:grid-cols-none xl:flex xl:items-center">
                <div className="col-span-2 sm:col-span-1">
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Поиск сотрудника"
                        variant="outlined"
                        sx={{
                            borderRadius: inputBorderRadius,
                            "& .MuiInputBase-root": {
                                borderRadius: inputBorderRadius,
                            },
                        }}
                        onChange={(e: BaseSyntheticEvent) => store.setSearch(e.target.value)}
                        value={store.search}
                        size="small"
                        className="base-filter-field xl:!min-w-[277px] bg-white dark:dark-surface-300"
                    />
                </div>
                <div className="">
                    <FormControl size="small" className="base-filter-field xl:!min-w-[230px]" fullWidth>
                        <InputLabel color="secondary">Компания</InputLabel>
                        <Select
                            label="Компания"
                            sx={{
                                borderRadius: inputBorderRadius,
                                "& .MuiInputBase-root": {
                                    borderRadius: inputBorderRadius,
                                },
                            }}
                            value={store.company}
                            onChange={store.handleCompanyChange}
                            className="bg-white dark:dark-surface-300"
                        >
                            <MenuItem value="">
                                <div className="italic">Все</div>
                            </MenuItem>
                            {store.companies.map((el) => (
                                <MenuItem
                                    key={el.id}
                                    value={el.id}
                                    className="!text-wrap !max-w-[300px] xl:!max-w-none"
                                >
                                    {el.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="">
                    <Autocomplete
                        fullWidth
                        className="bg-white dark:dark-surface-300 base-filter-field filter-autocomplete xl:!min-w-[280px]"
                        renderInput={(params) => <TextField {...params} label="Отдел" size="small" />}
                        sx={{
                            borderRadius: inputBorderRadius,
                            "& .MuiInputBase-root": {
                                borderRadius: inputBorderRadius,
                            },
                        }}
                        options={store.departments}
                        disabled={!store.company}
                        getOptionLabel={(option) => option.name}
                        getOptionKey={(option) => option.id}
                        value={store.department}
                        onChange={store.handleDepartmentChange}
                        clearText="Очистить"
                        noOptionsText="Нет совпадений"
                        slotProps={{
                            popper: {
                                placement: "bottom-end",
                                sx: {
                                    minWidth: {
                                        xs: 300,
                                        xl: null,
                                    },
                                },
                            },
                        }}
                    />
                </div>
                {store.total > 0 && !store.loadingUsers && (
                    <div className="hidden italic xl:block">Найдено пользователей: {store.total}</div>
                )}
            </div>
            <div>{renderContentBlock()}</div>
        </MainLayout>
    );
};

const ImageWithPopover = ({ image, userId }: ImageWithPopoverInterface) => {
    const [open, setOpen] = useState<boolean>(false);
    const handlePopoverOpen = () => {
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
    };

    const redirectToProfile = (id: number) => {
        window.open(`/users/profile/${id}`);
    };

    return (
        <div className="relative z-10">
            <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onClick={() => {
                    handlePopoverClose();
                    setTimeout(() => {
                        redirectToProfile(userId);
                    }, 0);
                }}
            >
                <ProfileImageSkeleton image={image} className="w-[45px] text-[45px] xl:w-[38px] xl:text-[38px]" />
            </div>
            {open && (
                <div className="absolute top-0 left-0 bg-white/80 dark:bg-black/30 p-1 rounded-xl -translate-y-[110%] min-w-[150px] flex justify-center items-center">
                    <ProfileImageSkeleton image={image} className="w-[140px] text-[140px]" />
                </div>
            )}
        </div>
    );
};

export default observer(AllUsers);
