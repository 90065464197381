import { NodeDatumInterface } from "./interfaces";

export const searchElRecursively = (
    el: NodeDatumInterface,
    id: number,
    type: "company" | "department" | "user",
    parent: any = [1, "company"]
): Array<any> => {
    // функция ищет в дереве элемент по id и типу
    if (["company", "department"].includes(type) && el.id === id && el.type === type) {
        return [[id, type]];
    } else if (type === "user" && el.user?.id === id) {
        return [[id, type]];
    } else if (type === "user" && ["company", "department"].includes(el.type) && el.manager && el.manager.id === id) {
        return [[el.id, el.type]];
    }
    for (const i of el.children) {
        const res = searchElRecursively(i, id, type, [i.user ? i.user.id : i.id, i.type]);
        if (res.length) {
            return [parent, ...res];
        }
    }
    return [];
};

export const mediaTree = [
    {
        threshold: 640,
        params: {
            orientation: "horizontal",
            separation: {
                siblings: 0.6,
            },
            nodeSize: { y: 210, x: 260 },
        },
    },
    {
        threshold: 1024,
        params: {
            orientation: "vertical",
            separation: {
                siblings: 2.5,
            },
            nodeSize: { y: 210, x: 135 },
        },
    },
];

export const mediaNode = [
    {
        threshold: 640,
        params: {
            width: 240,
            height: 100,
            x: -120,
            y: -37,
        },
    },
    {
        threshold: 1024,
        params: {
            width: 320,
            height: 100,
            x: -160,
            y: -2,
        },
    },
];
