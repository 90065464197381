import useImageLoad from "../../../hooks/useImageLoad";

interface ImageDivInterface {
    src: string;
    onClick: Function;
}

const ImageDiv = ({ src, onClick }: ImageDivInterface) => {
    const [loaded] = useImageLoad(src);

    return (
        <div
            className={`aspect-square card-inner rounded-3xl flex justify-center p-1 hover:cursor-pointer xl:rounded-[26px] ${
                !loaded && "animate-pulse bg-slate-200 cursor-wait"
            }`}
            style={{
                backgroundImage: loaded ? `url(${src})` : "",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
            onClick={() => onClick()}
        />
    );
};

export default ImageDiv;
