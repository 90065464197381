import { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import DOMPurify from "dompurify";
import { Editor } from "@tiptap/react";
import PreviewTab from "../PreviewTab";

export interface SafeUserInterface {
    id: number;
    last_name: string;
    first_name: string;
}

interface NewsStatusInterface {
    name: string;
    code_name: string;
}

export interface NewsItemInterface {
    author?: SafeUserInterface;
    created_at?: string;
    status?: NewsStatusInterface;
    refusal_reason?: string;
}

interface PreviewInterface {
    title: string;
    text: string;
    titleImage: any;
    newsData?: NewsItemInterface;
    editor: Editor;
    action: "create" | "edit";
}

const Preview = ({ title, text, titleImage, newsData = {}, editor, action }: PreviewInterface) => {
    const [textOverflow, setTextOverflow] = useState(false);
    const [imageLoading, setImageLoading] = useState(action === "edit");
    const ref = useRef<HTMLDivElement>(null);
    const cardHeight = 810;

    useEffect(() => {
        if (action === "edit" && typeof titleImage === "string") {
            setImageLoading(true);
            const imageLoader = new Image();
            imageLoader.src = titleImage;
            imageLoader.onload = () => setImageLoading(false);
        }
    }, [titleImage]);

    useEffect(() => {
        if (ref.current) {
            setTextOverflow(ref.current.scrollHeight !== ref.current.clientHeight);
        }
    }, [text]);

    const renderImage = useMemo(() => {
        let titleImageLink;
        if (typeof titleImage === "string") {
            titleImageLink = `${titleImage}`;
        } else if (!titleImage) {
            titleImageLink = "";
        } else {
            titleImageLink = `${URL.createObjectURL(titleImage)}`;
        }

        if (titleImage || imageLoading) {
            return (
                <div
                    className={`rounded-tr-[28px] overflow-hidden flex justify-center border-b-[1px] dark:border-[#717171] relative ${
                        imageLoading && "animate-pulse bg-slate-200 cursor-wait"
                    }`}
                    style={{
                        height: cardHeight / 2,
                    }}
                >
                    {!!titleImageLink && !imageLoading ? (
                        <img src={titleImageLink} className="absolute object-cover w-full h-full blur-lg -z-10" />
                    ) : (
                        <></>
                    )}
                    {!!titleImageLink && !imageLoading ? <img src={titleImageLink} /> : <></>}
                </div>
            );
        } else {
            return (
                <div className="bg-white dark:dark-surface-300 h-[405px] w-full rounded-tr-[28px] border-b-[1px] dark:border-[#717171] flex items-center justify-center text-[33px] leading-[101%] font-thin">
                    Главное изображение
                </div>
            );
        }
    }, [titleImage, imageLoading]);

    return (
        <div className="relative shadow-2xl rounded-b-[28px] rounded-tr-[28px]">
            <PreviewTab />
            {renderImage}
            <div className="bg-white dark:dark-surface-300 h-[405px] rounded-b-[28px] pt-[17px] px-[32px] pb-[29px]">
                {title ? (
                    <div className="avx-h1 font-bold whitespace-pre-wrap break-all">{title}</div>
                ) : (
                    <div className="avx-h1 font-bold">Заголовок</div>
                )}

                <div className="mt-[13px] text-[#8F9DB9] flex justify-between avx-p">
                    <div>{dayjs(newsData.created_at).format("LLL")}</div>
                    <div>
                        {newsData.author?.last_name} {newsData.author?.first_name}
                    </div>
                </div>
                {editor.state.doc.textContent.trim().length ? (
                    <div>
                        <div ref={ref} className="max-h-[180px] overflow-y-hidden mt-[13px] relative">
                            <div
                                className="avx-p whitespace-pre-wrap break-word font-medium"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
                            />
                            {textOverflow && (
                                <div className="absolute bottom-0 left-0 h-[60px] bg-gradient-to-b from-white/0 to-white/100 dark:from-[#3F3F3F]/0 dark:to-[#3F3F3F]/100 w-full"></div>
                            )}
                        </div>
                        <span className="avx-p mt-2 inline-block text-[#2B5BB9] dark:dark-primary-text-400">Читать полностью</span>
                    </div>
                ) : (
                    <div className="avx-p mt-[13px]">Текст статьи</div>
                )}
            </div>
        </div>
    );
};

export default observer(Preview);
