import { makeAutoObservable } from "mobx";
import UserService from "../../../services/UserService";
import { enqueueSnackbar } from "notistack";

export interface dimensionsInterface {
    translateX: number;
    translateY: number;
}

export interface UserInterface {
    id: number;
    name: string;
    company: string;
}

export class CompanyStructureStore {
    treeContainer: any;
    dimensions: dimensionsInterface;
    initDepth: number = 0;
    tree: any = {};
    circleD: number = 50;
    cardSize: { width: number; height: number };
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this);

        this.dimensions = {
            translateX: 200,
            translateY: 200,
        };
        this.cardSize = {
            width: 320,
            height: 100,
        };
    }

    setDimensions = (dimensions: dimensionsInterface) => {
        this.dimensions = dimensions;
    };

    setTreeContainer = (tree: any) => {
        this.treeContainer = tree;
    };

    setTree = (tree: any) => {
        this.tree = tree;
    };

    setInitDepth = (depth: number) => {
        this.initDepth = depth;
    };

    fetchTree = async () => {
        const resp = await UserService.getCompanyStructure();
        return resp.data;
    };

    setLoading = (bool: boolean) => {
        this.loading = bool;
    };
}

export class SearchUserStore {
    options: Array<UserInterface> = [];
    inputValue: string = "";
    open: boolean = false;
    value: UserInterface | null = null;
    searchLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setInputValue = (inputValue: string) => {
        this.inputValue = inputValue;
    };

    setOpen = (open: boolean) => {
        this.open = open;
    };

    setOptions = (options: Array<UserInterface>) => {
        this.options = options;
    };

    setValue = (value: UserInterface | null) => {
        this.value = value;
    };

    setSearchLoading = (searchLoading: boolean) => {
        this.searchLoading = searchLoading;
    };

    fetchUsers = async () => {
        await UserService.getStructureUsers()
            .then((resp) => {
                this.setOptions(resp.data);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка");
            });
    };
}
