import { useEffect, useState, useContext } from "react";
import MainLayout from "../../../layouts/MainLayout";
import Tree from "react-d3-tree";
import "./styles.css";
import { dimensionsInterface } from "./store";
import UserService from "../../../services/UserService";
import { enqueueSnackbar } from "notistack";
import TreeElement from "./components/TreeElement";
import LoadingText from "../../../components/LoadingText";
import { searchElRecursively } from "./utils";
import SearchUser from "./components/SearchUser";
import { SearchUserStore, CompanyStructureStore } from "./store";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import Person from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import AuthContext from "../../../context/AuthContext";
import { mediaTree } from "./utils";

const CompanyStructure = () => {
    const [store] = useState(() => new SearchUserStore());
    const [treeStore] = useState(() => new CompanyStructureStore());
    const { domainStore } = useContext(AuthContext);
    const transitionDuration = 500;
    const centeringTransitionDuration = 1000;
    const [tree, setTree] = useState<any>({});
    const [treeContainer, setTreeContainer] = useState<any>();

    const [treeSettings, setTreeSettings] = useState(mediaTree[0].params);

    const [translate, setTranslate] = useState<dimensionsInterface>({
        translateX: 0,
        translateY: 0,
    });
    const [ready, setReady] = useState<boolean>(false);
    const [dimensions, setDimensions] = useState<{ width: number; height: number } | undefined>(undefined);
    const [search, setSearch] = useState<Array<number | string>>([]);
    const [rootNodeCollapsed, setRootNodeXCollapsed] = useState<boolean>(false);

    useEffect(() => {
        const fetchTree = async () => {
            await UserService.getCompanyStructure()
                .then((resp) => {
                    setTree(resp.data);
                    treeStore.setLoading(false);
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar("Ошибка");
                });
        };
        fetchTree();
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const windowWidth = entries[0].contentRect.width;

            let currRes = 0;
            let idx = 0;
            for (const i of mediaTree) {
                if (windowWidth >= i.threshold) {
                    currRes = idx;
                } else {
                    break;
                }
                idx++;
            }
            setTreeSettings(mediaTree[currRes].params);
        });
        resizeObserver.observe(document.body);
    }, []);

    useEffect(() => {
        if (treeContainer) {
            const windowWidth = document.body.clientWidth;
            const { width, height } = treeContainer.getBoundingClientRect();
            if (windowWidth >= 1024) {
                setTranslate({ translateY: height / 3, translateX: width / 2 });
            } else if (windowWidth >= 640) {
                setTranslate({ translateY: height / 3, translateX: width / 4 });
            } else {
                setTranslate({ translateY: height / 3, translateX: width / 9 });
            }
            setDimensions({
                width,
                height,
            });
            setReady(true);
        }
    }, [treeContainer]);

    const renderCustomNodeElement = (el: any) => {
        return (
            <TreeElement
                nodeDatum={el.nodeDatum}
                toggleNode={el.toggleNode}
                search={search}
                setRootNodeXCollapsed={setRootNodeXCollapsed}
                centeringTransitionDuration={centeringTransitionDuration}
            />
        );
    };

    const searchEl = (id: number, type: "company" | "department" | "user") => {
        const chain = searchElRecursively(tree, id, type);
        if (!chain.length) {
            enqueueSnackbar("Не удалось найти пользователя");
            return;
        }
        store.setSearchLoading(true);
        setSearch(chain[0]);
        setTimeout(
            () => {
                for (const [idx, el] of chain.slice(1).entries()) {
                    setTimeout(() => {
                        setSearch(el);
                        if (idx === chain.length - 2) store.setSearchLoading(false);
                    }, idx * centeringTransitionDuration + 100);
                }
                setRootNodeXCollapsed(false);
            },
            rootNodeCollapsed ? centeringTransitionDuration + 100 : centeringTransitionDuration * 2 + 100
        );
    };

    return (
        <MainLayout>
            <div className="avx-page-h">Структура компании</div>            
            <div className="my-4 flex items-center gap-3 xl:my-10 xl:gap-5">
                <SearchUser store={store} treeStore={treeStore} />
                <div className="flex items-center">
                    <Button
                        variant="contained"
                        color="signature"
                        onClick={() => {
                            searchEl(store.value?.id as number, "user");
                        }}
                        disabled={!!!store.value || store.searchLoading}
                    >
                        Найти
                    </Button>
                </div>
                <IconButton
                    title="Найти меня"
                    disabled={treeStore.loading || store.searchLoading}
                    onClick={() => {
                        searchEl(domainStore.user.id, "user");
                    }}
                >
                    <Person fontSize="large" color="primary" />
                </IconButton>
            </div>
            {!treeStore.loading ? (
                <div
                    id="treeWrapper"
                    className="w-full rounded-3xl h-[700px] justify-center border-solid relative"
                    ref={(tc) => setTreeContainer(tc)}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {ready && (
                        <Tree
                            data={tree}
                            rootNodeClassName="node__root"
                            branchNodeClassName="node__branch"
                            leafNodeClassName="node__leaf"
                            pathFunc="step"
                            translate={{ x: translate.translateX, y: translate.translateY }}
                            initialDepth={1}
                            renderCustomNodeElement={renderCustomNodeElement}
                            zoom={0.8}
                            scaleExtent={{ max: 2, min: 0.1 }}
                            shouldCollapseNeighborNodes
                            dimensions={dimensions}
                            transitionDuration={transitionDuration}
                            centeringTransitionDuration={centeringTransitionDuration}
                            pathClassFunc={() => "tree-link"}
                            {...(treeSettings as any)}
                        />
                    )}
                    {store.searchLoading && (
                        <div className="absolute w-full h-full z-20 bg-transparent top-0 left-0 hover:cursor-wait" />
                    )}
                </div>
            ) : (
                <div className="flex justify-center">
                    <LoadingText />
                </div>
            )}
        </MainLayout>
    );
};

export default observer(CompanyStructure);
