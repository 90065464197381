import { useContext, useRef } from "react";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import AuthContext from "../../context/AuthContext";

interface ButtonInterface {
    name?: string;
    color?: "avilex" | "secondary";
    text: string;
    onClick?: Function;
    styles?: object;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
}

const Button = ({
    name,
    color = "avilex",
    text,
    onClick,
    disabled = false,
    loading = false,
    className,
    styles,
}: ButtonInterface) => {
    const { domainStore } = useContext(AuthContext);
    const colors = {
        avilex: {
            normal: domainStore.theme === "dark" ? "#7A5AF5" : "#C72929",
            hover: domainStore.theme === "dark" ? "#5e43f3" : "#AB2121",
        },
        secondary: {
            normal: "#A5A5A5",
            hover: "#959595",
        },
    };
    const textRef = useRef<HTMLDivElement>(null);
    const customStyles = styles ? styles : {};

    return (
        <motion.button
            name={name}
            style={{
                backgroundColor: colors[color].normal,
                ...customStyles,
                opacity: disabled || loading ? 0.8 : 1,
            }}
            className={`text-white py-[12px] px-[20px] rounded-[15px] flex items-center justify-center ${className}`}
            whileHover={{
                backgroundColor: !disabled && !loading ? colors[color].hover : colors[color].normal,
            }}
            disabled={disabled || loading}
        >
            {loading && (
                <motion.div
                    animate={{ rotate: "360deg" }}
                    transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                    className="border-4 border-white/50  block rounded-full border-t-white mr-2"
                    style={{ width: textRef.current?.clientHeight, height: textRef.current?.clientHeight }}
                />
            )}

            <div className="avx-p" ref={textRef}>
                {text}
            </div>
        </motion.button>
    );
};

export default observer(Button);
