import { makeAutoObservable } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import api from "../../http";
import { enqueueSnackbar } from "notistack";

export type ViewType = "year" | "month";
export type FetchType = "birthdate";

interface DateTimeInterface {
    d: number;
    m: number;
}

export interface EventInterface {
    type: FetchType;
    description: string;
    datetime: DateTimeInterface;
}

export default class MyCalendarStore {
    view: ViewType;
    viewList: Array<ViewType> = [];
    date: Dayjs;
    monthList: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    showToday: boolean;
    fetchList: Array<FetchType>;
    events: Array<EventInterface> = [];

    constructor(
        year: number,
        month: number,
        view: ViewType,
        viewList: Array<ViewType>,
        showToday: boolean,
        fetchList: Array<FetchType>
    ) {
        this.viewList = viewList;
        this.date = dayjs({ year, month });
        this.showToday = showToday;
        this.view = view;
        this.fetchList = fetchList;
        makeAutoObservable(this);
    }

    setView(view: ViewType) {
        this.view = view;
    }

    setDate(date: Dayjs) {
        this.date = date;
    }

    setEvents(events: Array<EventInterface>) {
        this.events = events;
    }

    async fetchEvents() {
        const params = new URLSearchParams();
        const dateFormat = "YYYY-MM-DD";

        for (const item of this.fetchList) {
            params.append("type", item);
        }
        if (this.view === "month") {
            params.append(
                "date_start",
                dayjs({ year: this.date.year(), month: this.date.month(), day: 1 }).format(dateFormat)
            );
            params.append(
                "date_end",
                dayjs({ year: this.date.year(), month: this.date.month() }).endOf("month").format(dateFormat)
            );
        } else {
            params.append("date_start", dayjs({ year: this.date.year() }).startOf("year").format(dateFormat));
            params.append("date_end", dayjs({ year: this.date.year() }).endOf("year").format(dateFormat));
        }

        await api
            .get("/calendar/", { params: params })
            .then((res) => {
                this.setEvents(res.data);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса событий");
            });
    }
}
