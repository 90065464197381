import { makeAutoObservable, reaction } from "mobx";
import NewsService from "../../../services/NewsService";
import { enqueueSnackbar } from "notistack";
import { SafeUserInterface, CommentInterface } from "./interfaces";
import { getFileFormat } from "../common/utils";

interface ImageInterface {
    id: number;
    image: string;
}

export interface TagInterface {
    id: number;
    name: string;
    color: string;
}

export interface NewsInterface {
    id: number;
    title: string;
    text: string;
    title_image: string;
    published_at: string;
    author: SafeUserInterface;
    likes: number;
    liked: boolean;
    tags: Array<TagInterface>;
    images: Array<ImageInterface>;
    comments: number;
}

export interface ImageListItemInterface {
    image: string;
    loaded: boolean;
}

export default class ViewNewsStore {
    loading: boolean = true;
    titleImageLoaded: boolean = false;
    news: NewsInterface | null = null;
    modalOpen: boolean = false;
    mobileModalOpen: boolean = false;
    imageList: Array<ImageListItemInterface> = [];
    currImageIdx: number = 0;
    direction: number = 1;

    cardWidth: number = 650;
    carouselHover: boolean = false;
    carouselIdx: number = 0;
    notFound: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    changeSlide = (direction: number) => {
        this.direction = direction;
        if (this.currImageIdx + direction < 0) {
            this.currImageIdx = this.imageList.length - 1;
        } else if (this.currImageIdx + direction + 1 > this.imageList.length) {
            this.currImageIdx = 0;
        } else {
            this.currImageIdx = this.currImageIdx + direction;
        }
    };

    setCurrImageIdx = (idx: number) => {
        this.currImageIdx = idx;
    };
    setDirection = (direction: number) => {
        this.direction = direction;
    };

    setImageList = (imageList: Array<ImageListItemInterface>) => {
        this.imageList = imageList;
    };

    setModalOpen = (bool: boolean) => {
        if (bool) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
        this.modalOpen = bool;
    };

    setMobileModalOpen = (bool: boolean) => {
        if (bool) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
        this.mobileModalOpen = bool;
    };

    setLoading = (bool: boolean) => {
        this.loading = bool;
    };

    setTitleImageLoaded = (bool: boolean) => {
        this.titleImageLoaded = bool;
    };

    setNews = (news: NewsInterface | null) => {
        this.news = news;
    };

    setCarouselHover = (bool: boolean) => {
        this.carouselHover = bool;
    };

    setCarouselIdx = (num: number) => {
        this.carouselIdx = num;
    };

    setNotFound = (bool: boolean) => {
        this.notFound = bool;
    };

    fetchNews = async (id: number) => {
        await NewsService.getPublishedNewsItem(id)
            .then((resp) => {
                this.setNews(resp.data);
                let tempImageList = [{ image: resp.data.title_image, loaded: false }];
                for (const i of resp.data.images) {
                    tempImageList.push({ image: i.image, loaded: false });
                }
                this.setImageList(tempImageList);

                const imageLoader = new Image();
                imageLoader.src = resp.data.title_image;

                imageLoader.onload = () => {
                    setTimeout(() => {
                        this.setTitleImageLoaded(true);
                    }, 0);
                };

                this.imageList.forEach((el) => {
                    const parsedFile = getFileFormat(el.image);
                    if (parsedFile.format === "image") {
                        const imageLoader = new Image();
                        imageLoader.src = el.image;

                        imageLoader.onload = () => {
                            setTimeout(() => {
                                let tempList = [];
                                for (const i of this.imageList) {
                                    if (i.image === el.image) {
                                        tempList.push({ image: i.image, loaded: true });
                                    } else {
                                        tempList.push(i);
                                    }
                                }
                                this.setImageList(tempList);
                            }, 0);
                        };
                    } else {
                        const vid = document.createElement("video");
                        vid.muted = true;
                        vid.autoplay = true;
                        vid.playsInline = true;
                        vid.onloadeddata = () => {
                            let tempList = [];
                            for (const i of this.imageList) {
                                if (i.image === el.image) {
                                    tempList.push({ image: i.image, loaded: true });
                                } else {
                                    tempList.push(i);
                                }
                            }
                            this.setImageList(tempList);
                        };
                        vid.src = el.image;
                    }
                });
            })
            .catch((e) => {
                if (e.toJSON().status === 404) {
                    this.setNotFound(true);
                } else {
                    enqueueSnackbar("Ошибка");
                    console.error(e);
                }
            })
            .finally(() => {
                this.setLoading(false);
            });
    };

    swapLike = async (id: number) => {
        try {
            await NewsService.swapLike(id).then((res) => {
                let newLikes: number = 0;
                if (res.data.liked && this.news) {
                    newLikes = this.news.likes + 1;
                } else if (!res.data.liked && this.news) {
                    newLikes = this.news.likes - 1;
                }

                this.setNews({
                    ...this.news,
                    liked: res.data.liked,
                    likes: newLikes,
                } as NewsInterface);
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Ошибка");
        }
    };
}

export class CommentsStore {
    page: number = 1;
    pageSize: number = 30;
    hasMore: boolean = false;
    loadingMore: boolean = false;
    loadingMainComments: boolean = false;
    store: ViewNewsStore;

    newsID: number;
    newCommentText: string = "";
    comments: Array<CommentInterface> = [];

    constructor(newsId: number, store: ViewNewsStore) {
        makeAutoObservable(this);
        this.newsID = newsId;
        this.store = store;

        reaction(
            () => this.page,
            () => {
                this.fetchMainComments();
            }
        );
    }

    setLoadingMore = (bool: boolean) => {
        this.loadingMore = bool;
    };

    setLoadingMainComments = (bool: boolean) => {
        this.loadingMainComments = bool;
    };

    setHasMore = (bool: boolean) => {
        this.hasMore = bool;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setNewCommentText = (text: string) => {
        this.newCommentText = text;
    };

    setComments = (comments: Array<CommentInterface>) => {
        this.comments = comments;
    };

    sendComment = async (news: boolean | null, parent: number | null) => {
        setTimeout(async () => {
            try {
                const newComment = await NewsService.sendComment(
                    this.newCommentText,
                    news ? this.newsID : null,
                    parent
                );
                this.setNewCommentText("");
                if (this.page === 1) {
                    this.fetchMainComments(false);
                } else {
                    this.setPage(1);
                }
                if (this.store.news)
                    this.store.setNews({ ...this.store.news, comments: newComment.data.total_comments });
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка отправки комментария");
            }
        }, 0);
    };

    fetchMainComments = async (needSkeleton: boolean = true) => {
        if (this.page === 1) {
            if (needSkeleton) this.setLoadingMainComments(true);
        } else {
            this.setLoadingMore(true);
        }

        setTimeout(async () => {
            try {
                const comments = await NewsService.fetchComments(this.newsID, null, this.page, this.pageSize);
                if (this.page === 1) {
                    this.setComments(comments.data.results);
                } else {
                    this.setComments([...this.comments, ...comments.data.results]);
                }
                this.setHasMore(!!comments.data.next);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса комментариев");
            } finally {
                this.setLoadingMainComments(false);
                this.setLoadingMore(false);
            }
        }, 0);
    };
}

export class UpperLevelCommentStore {
    type: string = "upper";
    store: CommentsStore;
    childrenExpanded: boolean = false;
    comment: CommentInterface;
    page: number = 1;
    pageSize: number = 10;
    hasMore: boolean = false;
    childComments: Array<CommentInterface> = [];

    loadingMore: boolean = false;
    loadingComments: boolean = false;

    newCommentText: string = "";
    newCommentExpanded: boolean = false;

    editedText: string;
    editMode: boolean = false;

    constructor(comment: CommentInterface, store: CommentsStore) {
        this.comment = comment;
        this.store = store;
        this.editedText = comment.text;
        makeAutoObservable(this);

        reaction(
            () => this.childrenExpanded,
            (newVal) => {
                if (newVal && !this.childComments.length) {
                    this.fetchChildComments();
                }
            }
        );

        reaction(
            () => this.page,
            () => {
                this.fetchChildComments();
            }
        );
    }

    setComment = (comment: CommentInterface) => {
        this.comment = comment;
    };

    setEditMode = (bool: boolean) => {
        this.editMode = bool;
    };

    setEditedText = (text: string) => {
        this.editedText = text;
    };

    setNewCommentText = (text: string) => {
        this.newCommentText = text;
    };

    setNewCommentExpanded = (bool: boolean) => {
        this.newCommentExpanded = bool;
    };

    setLoadingMore = (bool: boolean) => {
        this.loadingMore = bool;
    };

    setLoadingComments = (bool: boolean) => {
        this.loadingComments = bool;
    };

    setChildrenExpanded = (bool: boolean) => {
        this.childrenExpanded = bool;
    };

    setHasMore = (bool: boolean) => {
        this.hasMore = bool;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setChildComments = (comments: Array<CommentInterface>) => {
        this.childComments = comments;
    };

    fetchChildComments = async () => {
        if (this.page === 1) {
            this.setLoadingComments(true);
        } else {
            this.setLoadingMore(true);
        }
        setTimeout(async () => {
            try {
                const comments = await NewsService.fetchComments(null, this.comment.id, this.page, this.pageSize);
                if (this.page === 1) {
                    this.setChildComments(comments.data.results);
                } else {
                    this.setChildComments([...this.childComments, ...comments.data.results]);
                }
                this.setHasMore(!!comments.data.next);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса комментариев");
            } finally {
                this.setLoadingComments(false);
                this.setLoadingMore(false);
            }
        }, 0);
    };

    sendComment = async (parent: number) => {
        setTimeout(async () => {
            try {
                const newComment = await NewsService.sendComment(this.newCommentText, null, parent);
                this.setNewCommentText("");
                this.setNewCommentExpanded(false);

                this.setChildrenExpanded(true);
                if (this.childComments.length) {
                    if (this.page === 1) {
                        this.fetchChildComments();
                    } else {
                        this.setPage(1);
                    }
                }

                if (this.store.store.news)
                    this.store.store.setNews({ ...this.store.store.news, comments: newComment.data.total_comments });
                let updatedComments: Array<CommentInterface> = [];

                for (const i of this.store.comments) {
                    const newEl = { ...i };
                    if (i.id === this.comment.id) newEl.child_comments = newComment.data.main_comment_children;
                    updatedComments.push(newEl);
                }
                this.store.setComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка отправки комментария");
            } finally {
            }
        }, 0);
    };

    editComment = async () => {
        setTimeout(async () => {
            try {
                const resp = await NewsService.editComment(this.comment.id, this.editedText);

                this.setEditMode(false);
                this.setEditedText(resp.data.text);
                this.setComment({ ...this.comment, text: resp.data.text });

                let updatedComments: Array<CommentInterface> = [];
                for (const i of this.store.comments) {
                    const newEl = { ...i };
                    if (i.id === this.comment.id) {
                        newEl.text = resp.data.text;
                        newEl.edited = true;
                    }
                    updatedComments.push(newEl);
                }
                this.store.setComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка редактирования");
            } finally {
            }
        }, 0);
    };

    deleteOrBlockComment = async (action: "delete" | "block") => {
        setTimeout(async () => {
            try {
                if (action === "delete") {
                    await NewsService.deleteComment(this.comment.id);
                } else {
                    await NewsService.blockComment(this.comment.id);
                }

                let updatedComments: Array<CommentInterface> = [];
                for (const i of this.store.comments) {
                    const newEl = { ...i };
                    if (i.id === this.comment.id) {
                        if (action === "delete") {
                            newEl.deleted = true;
                        } else {
                            newEl.blocked = true;
                        }
                    }
                    updatedComments.push(newEl);
                }
                this.store.setComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка обновления комментария");
            } finally {
            }
        }, 0);
    };
}

export class LowerLevelCommentStore {
    type: string = "lower";
    upperStore: UpperLevelCommentStore;
    comment: CommentInterface;

    newCommentText: string = "";
    newCommentExpanded: boolean = false;

    editedText: string;
    editMode: boolean = false;

    constructor(upperStore: UpperLevelCommentStore, comment: CommentInterface) {
        this.upperStore = upperStore;
        this.comment = comment;
        this.editedText = comment.text;
        makeAutoObservable(this);
    }

    setComment = (comment: CommentInterface) => {
        this.comment = comment;
    };

    setEditMode = (bool: boolean) => {
        this.editMode = bool;
    };

    setEditedText = (text: string) => {
        this.editedText = text;
    };

    setNewCommentText = (text: string) => {
        this.newCommentText = text;
    };

    setNewCommentExpanded = (bool: boolean) => {
        this.newCommentExpanded = bool;
    };

    sendComment = async (parent: number) => {
        setTimeout(async () => {
            try {
                const newComment = await NewsService.sendComment(this.newCommentText, null, parent);
                this.setNewCommentText("");
                this.setNewCommentExpanded(false);

                if (this.upperStore.page === 1) {
                    this.upperStore.fetchChildComments();
                } else {
                    this.upperStore.setPage(1);
                }

                if (this.upperStore.store.store.news)
                    this.upperStore.store.store.setNews({
                        ...this.upperStore.store.store.news,
                        comments: newComment.data.total_comments,
                    });
                let updatedComments: Array<CommentInterface> = [];

                for (const i of this.upperStore.store.comments) {
                    const newEl = { ...i };
                    if (i.id === this.upperStore.comment.id)
                        newEl.child_comments = newComment.data.main_comment_children;
                    updatedComments.push(newEl);
                }
                this.upperStore.store.setComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка отправки комментария");
            } finally {
            }
        }, 0);
    };

    editComment = async () => {
        setTimeout(async () => {
            try {
                const resp = await NewsService.editComment(this.comment.id, this.editedText);

                this.setEditMode(false);
                this.setEditedText(resp.data.text);
                this.setComment({ ...this.comment, text: resp.data.text });

                let updatedComments: Array<CommentInterface> = [];
                for (const i of this.upperStore.childComments) {
                    const newEl = { ...i };
                    if (i.id === this.comment.id) {
                        newEl.text = resp.data.text;
                        newEl.edited = true;
                    }
                    updatedComments.push(newEl);
                }
                console.log(updatedComments);
                this.upperStore.setChildComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка редактирования");
            } finally {
            }
        }, 0);
    };

    deleteOrBlockComment = async (action: "delete" | "block") => {
        setTimeout(async () => {
            try {
                if (action === "delete") {
                    await NewsService.deleteComment(this.comment.id);
                } else {
                    await NewsService.blockComment(this.comment.id);
                }

                let updatedComments: Array<CommentInterface> = [];
                for (const i of this.upperStore.childComments) {
                    const newEl = { ...i };
                    if (i.id === this.comment.id) {
                        if (action === "delete") {
                            newEl.deleted = true;
                        } else {
                            newEl.blocked = true;
                        }
                    }
                    updatedComments.push(newEl);
                }
                this.upperStore.setChildComments([...updatedComments]);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка обновления комментария");
            } finally {
            }
        }, 0);
    };
}
