const Title = () => {
    return (
        <div className="flex items-end">
            <div>
                <svg
                    className="fill-[#BB2525] dark:fill-[#7A5AF5]"
                    width="60"
                    height="59"
                    viewBox="0 0 60 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M57.0777 29.4868C58.3438 29.4868 59.1035 28.4784 59.1035 27.47C58.8503 22.428 57.0777 17.386 54.2923 13.1003C51.0005 8.31036 46.4425 4.52885 41.1249 2.25994C35.5541 -0.00896835 29.7301 -0.51317 23.9061 0.495233C18.082 1.75574 12.7644 4.52885 8.71292 8.56246C4.66142 12.5961 1.87601 17.8902 0.609921 23.6885C-0.656174 29.4868 0.103483 35.2852 2.38245 40.5793C4.66142 45.8734 8.45971 50.6633 13.2709 53.6885C17.5756 56.4616 22.3867 58.2263 27.7043 58.4784C28.9704 58.4784 29.7301 57.7221 29.7301 56.4616V46.3776C29.7301 45.1171 30.743 44.3608 31.7558 44.3608H42.8975C44.1636 44.3608 44.9232 45.3692 44.9232 46.3776V56.4616C44.9232 57.7221 45.9361 58.4784 46.949 58.4784H57.0777C58.3438 58.4784 59.1035 57.47 59.1035 56.4616V46.3776C59.1035 45.1171 58.0906 44.3608 57.0777 44.3608H46.949C45.6829 44.3608 44.9232 43.3524 44.9232 42.344V31.5036C44.9232 30.2431 45.9361 29.4868 46.949 29.4868H57.0777ZM21.1207 42.0919C22.8932 43.3524 25.1722 44.1087 27.4511 44.6129C28.464 44.865 29.4769 43.8566 29.4769 42.5961V31.5036C29.4769 30.2431 30.4897 29.4868 31.5026 29.4868H42.6443C43.9103 29.4868 44.67 28.4784 44.67 27.47C44.4168 25.2011 43.4039 23.1843 42.1378 21.1675C40.3653 18.6465 38.0863 16.6297 35.3009 15.6213C32.5155 14.3608 29.4769 14.1087 26.4382 14.865C23.6528 15.1171 21.1207 16.6297 18.8417 18.6465C16.8159 20.9154 15.2966 23.4364 14.7902 26.4616C14.2837 29.4868 14.537 32.512 15.5498 35.2852C16.8159 38.0583 18.5885 40.3272 21.1207 42.0919Z"
                    />
                </svg>
            </div>
            <div className="ml-[22px]">
                <svg
                    className="fill-black dark:!fill-white"
                    width="164"
                    height="26"
                    viewBox="0 0 164 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M157.51 25.0002L152.47 17.1162L147.43 25.0002H141.886L149.77 12.9042L142.354 1.24023H147.898L152.47 8.72823L157.006 1.24023H162.514L155.206 12.9042L163.018 25.0002H157.51Z" />
                    <path d="M128.811 25.4322C126.867 25.4322 125.127 25.0242 123.591 24.2082C122.079 23.3682 120.891 22.1802 120.027 20.6442C119.163 19.0842 118.731 17.2602 118.731 15.1722V1.24023H123.591V15.5322C123.591 17.2602 124.071 18.5922 125.031 19.5282C126.015 20.4642 127.275 20.9322 128.811 20.9322C130.371 20.9322 131.631 20.4642 132.591 19.5282C133.551 18.5922 134.031 17.2602 134.031 15.5322V1.24023H138.891V15.1722C138.891 17.2602 138.447 19.0842 137.559 20.6442C136.695 22.1802 135.495 23.3682 133.959 24.2082C132.447 25.0242 130.731 25.4322 128.811 25.4322Z" />
                    <path d="M100.42 1.24023H105.28V20.6082H115.684V25.0002H100.42V1.24023Z" />
                    <path d="M81.1541 1.24023H96.7781V5.63223H86.0141V11.5722H94.8341V15.9642H86.0141V25.0002H81.1541V1.24023Z" />
                    <path d="M75.722 1.23962V25.4316H74.102L60.35 10.7436V24.9996H55.49V0.807617H57.074L70.862 15.4236V1.23962H75.722Z" />
                    <path d="M38.7023 25.4316C36.3983 25.4316 34.2863 24.8916 32.3663 23.8116C30.4463 22.7316 28.9223 21.2556 27.7943 19.3836C26.6663 17.4876 26.1023 15.3996 26.1023 13.1196C26.1023 10.8396 26.6663 8.76362 27.7943 6.89162C28.9223 4.99562 30.4463 3.50762 32.3663 2.42762C34.2863 1.34762 36.3983 0.807617 38.7023 0.807617C41.0063 0.807617 43.1183 1.34762 45.0383 2.42762C46.9823 3.50762 48.5183 4.99562 49.6463 6.89162C50.7983 8.76362 51.3743 10.8396 51.3743 13.1196C51.3743 15.3996 50.7983 17.4876 49.6463 19.3836C48.5183 21.2556 46.9823 22.7316 45.0383 23.8116C43.1183 24.8916 41.0063 25.4316 38.7023 25.4316ZM38.7383 20.9316C40.1783 20.9316 41.4743 20.6076 42.6263 19.9596C43.8023 19.2876 44.7143 18.3636 45.3623 17.1876C46.0343 15.9876 46.3703 14.6316 46.3703 13.1196C46.3703 11.6316 46.0343 10.2996 45.3623 9.12362C44.7143 7.92362 43.8023 6.98762 42.6263 6.31562C41.4743 5.64362 40.1783 5.30762 38.7383 5.30762C37.2743 5.30762 35.9663 5.64362 34.8143 6.31562C33.6623 6.96362 32.7503 7.88762 32.0783 9.08762C31.4303 10.2636 31.1063 11.6076 31.1063 13.1196C31.1063 14.6316 31.4303 15.9876 32.0783 17.1876C32.7503 18.3636 33.6623 19.2876 34.8143 19.9596C35.9663 20.6076 37.2743 20.9316 38.7383 20.9316Z" />
                    <path d="M13.0039 25.4316C10.6759 25.4316 8.53992 24.8916 6.59592 23.8116C4.67592 22.7316 3.15192 21.2556 2.02392 19.3836C0.91992 17.4876 0.36792 15.3996 0.36792 13.1196C0.36792 10.8396 0.93192 8.76362 2.05992 6.89162C3.18792 4.99562 4.71192 3.50762 6.63192 2.42762C8.57592 1.34762 10.7119 0.807617 13.0399 0.807617C15.2959 0.807617 17.3359 1.33562 19.1599 2.39162C21.0079 3.42362 22.4599 4.86362 23.5159 6.71162L19.8439 9.30362C18.1639 6.63962 15.8959 5.30762 13.0399 5.30762C11.5759 5.30762 10.2559 5.64362 9.07992 6.31562C7.92792 6.98762 7.01592 7.92362 6.34392 9.12362C5.69592 10.2996 5.37192 11.6436 5.37192 13.1556C5.37192 14.6676 5.69592 16.0116 6.34392 17.1876C7.01592 18.3636 7.92792 19.2876 9.07992 19.9596C10.2559 20.6076 11.5759 20.9316 13.0399 20.9316C15.8959 20.9316 18.1639 19.5996 19.8439 16.9356L23.5159 19.5276C22.4839 21.3756 21.0439 22.8276 19.1959 23.8836C17.3479 24.9156 15.2839 25.4316 13.0039 25.4316Z" />
                </svg>
            </div>
            <div className="ml-[9px]">
                <svg
                    className="fill-black dark:!fill-white"
                    width="113"
                    height="26"
                    viewBox="0 0 113 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M108.985 25.0002L102.577 15.2442L96.1334 25.0002H92.8574L101.029 12.8322L93.4334 1.24023H96.6734L102.577 10.4922L108.445 1.24023H111.685L104.197 12.8682L112.261 25.0002H108.985Z" />
                    <path d="M74.6626 1.24023H89.8906V3.76023H77.4706V11.0322H86.9746V13.5522H77.4706V22.4802H90.3226V25.0002H74.6626V1.24023Z" />
                    <path d="M56.4517 1.24023H59.2597V22.4802H70.5277V25.0002H56.4517V1.24023Z" />
                    <path d="M47.1353 1.24023H49.9433V25.0002H47.1353V1.24023Z" />
                    <path d="M42.7403 1.24023L32.4443 25.3962H31.4002L21.1042 1.24023H24.2002L31.9402 19.8882L39.6442 1.24023H42.7403Z" />
                    <path d="M16.748 20.1758H5.08397L3.06797 24.9998H0.115967L10.412 0.84375H11.456L21.752 24.9998H18.764L16.748 20.1758ZM10.916 5.95575L6.16397 17.6558H15.668L10.916 5.95575Z" />
                </svg>
            </div>
        </div>
    );
};

export default Title;
