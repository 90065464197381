import { useState } from "react";
import { CommentInterface } from "../interfaces";
import { LowerLevelCommentStore, UpperLevelCommentStore } from "../store";
import { observer } from "mobx-react-lite";
import CommentBody from "./CommentBody";

interface LowerLevelCommentInterface {
    comment: CommentInterface;
    upperStore: UpperLevelCommentStore;
}

const LowerLevelComment = ({ comment, upperStore }: LowerLevelCommentInterface) => {
    const [store] = useState(() => new LowerLevelCommentStore(upperStore, comment));

    return (
        <div>
            <CommentBody comment={comment} store={store} />
        </div>
    );
};

export default observer(LowerLevelComment);
