import { motion } from "framer-motion";
import "./styles.css"

export interface ListFeedbackInterface {
    text?: string;
}

interface LoadingInterface extends ListFeedbackInterface {}

export const Loading = ({ text = "Загрузка..." }: LoadingInterface) => {
    return (
        <div className="list-info p-2 flex gap-2 items-center justify-between rounded-lg xl:p-4">
            <motion.div
                animate={{ rotate: "360deg" }}
                transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                className="border-4 border-gray-200/50  block rounded-full border-t-[#CC3B3B] size-8 dark:border-t-[#5e43f3]"
            />
            <div>{text}</div>
        </div>
    );
};

interface EndOfListInterface extends ListFeedbackInterface {}

export const EndOfList = ({ text = "Больше результатов нет" }: EndOfListInterface) => {
    return (
        <div className="flex justify-center">
            <div className="list-info p-2 flex items-center justify-between rounded-lg xl:p-4">{text}</div>
        </div>
    );
};

interface NoResultsInterface extends ListFeedbackInterface {}

export const NoResults = ({ text = "Нет результатов" }: NoResultsInterface) => {
    return (
        <div className="flex justify-center text-sm xl:text-base">
            <div className="list-info p-2 rounded-xl xl:p-4">{text}</div>
        </div>
    );
};
