import { weekDayNames } from "../../common/constants";
import { useState, useEffect, useContext } from "react";
import MonthStore, { DateInterface } from "./store";
import { observer } from "mobx-react-lite";
import { MyCalendarContextType, MyCalendarContext } from "../../context";
import { EventInterface } from "../../store";
import { Popover, PopoverContent, PopoverTrigger } from "../../../FloatingPopover";
import CakeIcon from "@mui/icons-material/Cake";
import "./styles.css";

interface MonthInterface {
    year: number;
    month: number;
    showMonth?: boolean;
    events: Array<EventInterface>;
}

interface DateComponentInterface {
    date: DateInterface;
}

interface EventPopoverItemInterface {
    event: EventInterface;
}

const EventPopoverItem = observer(({ event }: EventPopoverItemInterface) => {
    if (event.type === "birthdate") {
        return (
            <div className="flex gap-1 items-center">
                <CakeIcon fontSize="small" />
                <span className="text-nowrap max-w-[300px] sm:max-w-[600px] overflow-hidden text-ellipsis">
                    {event.description}
                </span>
            </div>
        );
    }
    return null;
});

const Date = observer(({ date }: DateComponentInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [open, setOpen] = useState(false);

    return (
        <td
            className={`relative text-center ${date.date.isToday() ? "bg-blue-200 dark:dark-primary-500" : ""} ${
                open
                    ? "outline outline-offset-[-1px] outline-[1px] outline-blue-200 dark:outline-[#A688FA]"
                    : "outline-none"
            }`}
        >
            <Popover open={open} onOpenChange={setOpen} placement="top-start">
                <PopoverTrigger asChild onClick={() => setOpen((v) => !v)}>
                    <div
                        className={`date-block size-full ${
                            !date.date.isToday() ? "dark:hover:dark-surface-400 hover:bg-blue-50" : ""
                        } ${myCalendarStore.view === "month" ? "px-3 py-5 sm:py-7 md:py-9 lg:py-11 xl:py-14" : "p-3"}`}
                    >
                        <div className="date-number">{date.date.format("D")}</div>
                        {date.events.length > 0 && (
                            <div
                                className={`absolute bottom-1 inset-x-0 flex justify-center gap-1 ${
                                    myCalendarStore.view === "month" ? "xl:bottom-3" : ""
                                }`}
                            >
                                {date.events.slice(0, 3).map((ev, idx) => (
                                    <div
                                        key={idx}
                                        className={`size-1 rounded-full bg-orange-300 ${
                                            myCalendarStore.view === "month" ? "sm:size-2" : ""
                                        }`}
                                    ></div>
                                ))}
                            </div>
                        )}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="bg-gray-200 dark:dark-surface-500 rounded-xl p-2 text-xs">
                    {date.events.length > 0 ? (
                        <div className="flex flex-col gap-1">
                            {date.events.map((ev, idx) => (
                                <EventPopoverItem key={idx} event={ev} />
                            ))}
                        </div>
                    ) : (
                        <div>Событий нет</div>
                    )}
                </PopoverContent>
            </Popover>
        </td>
    );
});

const Month = ({ year, month, showMonth = true, events }: MonthInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [monthStore, setMonthStore] = useState(() => new MonthStore(year, month, events));

    useEffect(() => {
        setMonthStore(new MonthStore(year, month, events));
    }, [myCalendarStore.date, myCalendarStore.events]);

    return (
        <div className="bg-white dark:dark-surface-300 rounded-xl py-2.5">
            {showMonth && (
                <div className="capitalize font-bold text-xl pl-2 mb-3">{monthStore.monthFirstDate.format("MMMM")}</div>
            )}
            <table className="w-full">
                <thead>
                    <tr className="bg-blue-100 dark:dark-primary-600">
                        {weekDayNames.map((name) => (
                            <th className="text-center" key={name}>
                                {name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {monthStore.monthData.map((week, weekIdx) => (
                        <tr key={weekIdx}>
                            {week.map((date, dateIdx) =>
                                date?.date ? <Date date={date} key={dateIdx} /> : <td key={dateIdx} />
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default observer(Month);
