import { makeAutoObservable } from "mobx";

class UIStore {
    sidebarState: string = "collapsed";
    sideBarSelectedItem: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setSidebarState(state: string) {
        this.sidebarState = state;
    }

    setSideBarSelectedItem(item: string) {
        this.sideBarSelectedItem = item;
    }

    reset() {
        this.sidebarState = "collapsed";
        this.sideBarSelectedItem = "";
    }
}

export default UIStore;
