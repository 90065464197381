import Month from "./components/Month";
import { useState, useContext, useEffect } from "react";
import MyCalendarStore, { ViewType, FetchType } from "./store";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { YearSelector, MonthSelector } from "./components/YearSelector";
import Tools from "./components/Tools";
import { MyCalendarContext, MyCalendarContextType } from "./context";

interface MyCalendarInterface {
    year?: number;
    month?: number;
    view?: ViewType; // Дефолтный вид
    viewList?: Array<ViewType>; // Какие доступны виды календаря
    showToday?: boolean; // Есть ли кнопка перехода на текущую дату
    fetchList?: Array<FetchType>; // Какие данные подгрузить с сервера
}

const ViewSelector = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    useEffect(() => {
        const fetchEvents = async () => {
            await myCalendarStore.fetchEvents();
        };
        fetchEvents();
    }, [myCalendarStore.date, myCalendarStore.view]);

    if (myCalendarStore.view === "year") {
        return (
            <div>
                <div className="mb-5 sticky z-20 flex justify-center w-full top-5">
                    <YearSelector />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 2xl:gap-8 gap-5 ">
                    {myCalendarStore.monthList.map((month) => (
                        <Month
                            key={month}
                            year={myCalendarStore.date.year()}
                            month={month}
                            events={myCalendarStore.events}
                        />
                    ))}
                </div>
            </div>
        );
    } else if (myCalendarStore.view === "month") {
        return (
            <div>
                <div className="mb-5 flex justify-center w-full">
                    <MonthSelector />
                </div>
                <div>
                    <Month
                        year={myCalendarStore.date.year()}
                        month={myCalendarStore.date.month()}
                        showMonth={false}
                        events={myCalendarStore.events}
                    />
                </div>
            </div>
        );
    }
    return null;
});

const MyCalendar = ({
    year = dayjs().year(),
    month = dayjs().month(),
    view = "year",
    viewList = [],
    showToday = true,
    fetchList = [],
}: MyCalendarInterface) => {
    const [myCalendarStore] = useState(() => new MyCalendarStore(year, month, view, viewList, showToday, fetchList));

    return (
        <div className="my-calendar">
            <MyCalendarContext.Provider value={{ myCalendarStore }}>
                {(myCalendarStore.viewList.length > 0 || showToday) && (
                    <div className="mb-2">
                        <Tools />
                    </div>
                )}
                <ViewSelector />
            </MyCalendarContext.Provider>
        </div>
    );
};

export default observer(MyCalendar);
