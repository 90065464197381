import React, { BaseSyntheticEvent, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface NavLinkInterface {
    children: ReactNode;
    href: string;
}

const NavLink = ({ children, href }: NavLinkInterface) => {
    const navigate = useNavigate();
    const redirect = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        navigate(href);
    };

    return (
        <a href={href} onClick={redirect}>
            {children}
        </a>
    );
};

export default NavLink;
