import { useContext } from "react";
import NewsContext from "../context";

interface InterfaceLoadingNewsSkeleton {
    gridStyles: string;
}

const LoadingNewsSkeleton = ({ gridStyles }: InterfaceLoadingNewsSkeleton) => {
    const context = useContext(NewsContext);
    const cardHeight = context.cardHeight;
    const cardRadius = context.cardRadius;

    return (
        <div className={gridStyles}>
            {[1, 2, 3, 4].map((el) => {
                return (
                    <div key={el}>
                        <div
                            className="bg-slate-200 cursor-wait animate-pulse"
                            style={{ height: cardHeight, borderRadius: `${cardRadius}px` }}
                        ></div>
                    </div>
                );
            })}
        </div>
    );
};

export default LoadingNewsSkeleton;
