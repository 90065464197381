import { useState, useEffect } from "react";
import "./styles.css";
import { observer } from "mobx-react-lite";
import { motion, AnimatePresence } from "framer-motion";
import DesktopSettings from "./components/DesktopSettings";
import MobileSettings from "./components/MobileSettings";

interface SettingDialogInterface {
    open: boolean;
    setOpen: Function;
}

const SettingDialog = ({ open, setOpen }: SettingDialogInterface) => {
    const [tab, setTab] = useState<"main" | "privacy" | "linked" | "">("");

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [open]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed h-screen w-screen z-50 bg-black/70 left-0 top-0 overflow-hidden flex justify-center items-center"
                >
                    <div className="hidden xl:block">
                        <DesktopSettings tab={tab} setTab={setTab} setOpen={setOpen} />
                    </div>
                    <div className="xl:hidden">
                        <MobileSettings tab={tab} setTab={setTab} setOpen={setOpen} />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default observer(SettingDialog);
