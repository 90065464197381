import { motion } from "framer-motion";

const Loader = () => {
    return (
        <div className="flex justify-center">
            <div className="flex items-center justify-between bg-white dark:dark-surface-300 rounded-lg gap-3">
                <motion.div
                    animate={{ rotate: "360deg" }}
                    transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                    className="border-4 border-gray-200/50  block rounded-full border-t-[#CC3B3B] dark:border-t-[#9171F8] size-6 xl:size-8"
                />
                <div className="text-sm xl:text-base">Загрузка...</div>
            </div>
        </div>
    );
};

export default Loader;
