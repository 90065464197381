import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "ru",
    resources: {
        ru: {
            translation: {
                comment_one: "{{count}} ответ",
                comment_few: "{{count}} ответа",
                comment_many: "{{count}} ответов",
            },
        },
    },
});
export default i18n;
