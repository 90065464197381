import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { settingsItems } from "../utils";
import InnerSettings from "./InnerSettings";

interface DesktopSettingsInterface {
    tab: "main" | "privacy" | "linked" | "";
    setTab: Function;
    setOpen: Function;
}

const DesktopSettings = ({ tab, setTab, setOpen }: DesktopSettingsInterface) => {
    return (
        <div className="flex settings-modal">
            <div className="bg-[#F5F5F5] dark:dark-surface-300 py-[14px] px-[17px]">
                <List>
                    {settingsItems.map((el, idx) => (
                        <ListItem disablePadding key={idx}>
                            <ListItemButton
                                selected={tab === el.name || (el.name === "main" && tab === "")}
                                className="ListItemButton"
                                onClick={() => setTab(el.name as "main" | "privacy" | "linked")}
                            >
                                <ListItemText>
                                    <span className="font-semibold">{el.text}</span>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </div>
            <InnerSettings tab={tab} setOpen={setOpen} />
        </div>
    );
};

export default DesktopSettings;
