import { useContext } from "react";
import { Dialog, IconButton, Button } from "@mui/material";
import { ProfileContext, ProfileContextInterface } from "../../../context";
import { observer } from "mobx-react-lite";
import CloseIcon from "@mui/icons-material/Close";
import { MyProfileStore } from "../../../stores";

const DeleteDialog = () => {
    const { store } = useContext(ProfileContext) as { store: MyProfileStore };

    return (
        <Dialog
            open={store.deleteDialogOpen}
            onClose={() => store.setDeleteDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{ sx: { borderRadius: "25px", padding: 3 } }}
        >
            <div>
                <div className="flex items-center justify-between">
                    <div className="font-bold text-xl">Удаление фото</div>
                    <IconButton onClick={() => store.setDeleteDialogOpen(false)} title="Закрыть">
                        <CloseIcon />
                    </IconButton>
                </div>
                <hr className="my-3 w-full" />
                <div className="my-5">Вы уверены, что хотите удалить фотографию?</div>
                <div className="flex flex-col gap-3 items-end mt-3">
                    <hr className="w-full" />
                    <Button
                        variant="contained"
                        color="signature"
                        disabled={store.loadingEdit}
                        onClick={async () => {
                            await store.deleteProfilePhoto();
                        }}
                    >
                        Подтвердить
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default observer(DeleteDialog);
