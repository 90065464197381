import { useContext } from "react";
import { motion } from "framer-motion";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { observer } from "mobx-react-lite";
import AuthContext from "../../context/AuthContext";

const ThemeSwitcher = () => {
    const { domainStore } = useContext(AuthContext);

    return (
        <div className="flex flex-col">
            <input
                type="checkbox"
                id="dark-mode-toggle"
                className="hidden h-0 w-0"
                checked={domainStore.theme === "dark"}
                onChange={async (e) => await domainStore.changeTheme(e)}
            />
            <motion.label
                initial={false}
                animate={{
                    backgroundColor: domainStore.theme === "dark" ? "#282828" : "#ffffff",
                }}
                htmlFor="dark-mode-toggle"
                className="cursor-pointer bg-white border-[1px] dark:border-gray-500 rounded-full relative w-[95px] h-[45px] shadow-inner overflow-hidden xl:h-[38px] xl:w-[70px]"
            >
                <motion.div
                    initial={false}
                    animate={{
                        left: domainStore.theme === "dark" ? "unset" : 0,
                        right: domainStore.theme === "dark" ? 0 : "unset",
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                    className="absolute h-full aspect-square"
                >
                    <div className="size-full p-1">
                        <motion.div
                            animate={{
                                color: domainStore.theme === "dark" ? "#9171F8" : "#fb923c",
                                backgroundColor: domainStore.theme === "dark" ? "#BFBFBF" : "#fef08a",
                            }}
                            initial={false}
                            className="size-full rounded-full shadow-md flex items-center justify-center"
                        >
                            {domainStore.theme === "dark" ? <DarkModeIcon /> : <LightModeIcon />}
                        </motion.div>
                    </div>
                </motion.div>
            </motion.label>
        </div>
    );
};

export default observer(ThemeSwitcher);
