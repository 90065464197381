import api from "../http";

interface EditMyProfileInterface {
    description?: string;
    phone?: string;
    tags?: Array<number>;
    telegram_username?: string | null;
    telegram_id?: string | null;
    hide_birthdate?: boolean;
    hide_phone?: boolean;
    hide_tg_username?: boolean;
}

export default class UserService {
    static async getMe() {
        return api.get("/profile");
    }

    static async getMyProfile() {
        return api.get("/users/me/");
    }

    static async getUserProfile(id: number) {
        return api.get(`/users/${id}/`);
    }

    static async editMyProfileInfo(data: EditMyProfileInterface) {
        return api.patch("/users/me/", data);
    }

    static async editMyProfilePhoto(formData: FormData, config: object) {
        return api.patch("/users/me-update-photo/", formData, config);
    }

    static async getUserTags() {
        return api.get("/users/tags/");
    }

    static async get_tg_confirm_code() {
        return api.get("/users/confirm-code/");
    }

    static async getUsers(page: number, pageSize: number, search: string, company: string, department: string) {
        return api.get("/users/", {
            params: {
                search: search,
                page: page,
                page_size: pageSize,
                profile__department__company: company,
                profile__department: department,
            },
            paramsSerializer: {
                indexes: null,
            },
        });
    }

    static async getCompanies() {
        return api.get("/users/companies/");
    }

    static async getDepartments(company_id: string) {
        return api.get("/users/departments/", { params: { company_id } });
    }

    static async getCompanyStructure() {
        return api.get("/users/company-structure/");
    }

    static async getStructureUsers() {
        return api.get("/users/company-structure/users/");
    }

    static async getMyColleagues() {
        return api.get("/users/colleagues/");
    }
    
    static async changeTheme(data: object) {
        return api.patch("/change-my-settings/", data);
    }

    static async changeSubscription(data: object) {
        return api.post("/app-subscribe/", data);
    }
}
