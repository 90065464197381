import { makeAutoObservable, reaction } from "mobx";
import { enqueueSnackbar } from "notistack";
import NewsService from "../../../services/NewsService";
import NewsInterface, { TagInterface } from "./interfaces";

export default class AllNewsStore {
    search: string = "";
    page: number = 1;
    pageSize: number = 48;
    tagsFilter: Array<number> = [];

    news: Array<NewsInterface> = [];
    tags: Array<TagInterface> = [];
    hasMore: boolean = false;
    loadingNews: boolean = true;
    loadingTags: boolean = true;
    loadingMore: boolean = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.page,
            () => {
                this.fetchNews();
            }
        );
        
        reaction(
            () => this.search,
            (val) => {
                if (this.page === 1) {
                    this.fetchNews();
                } else {
                    this.setPage(1);
                }
            },
            {
                delay: 1000
            }
        );

        reaction(
            () => this.tagsFilter,
            () => {
                if (this.page === 1) {
                    this.fetchNews();
                } else {
                    this.setPage(1);
                }
            }
        );
    }

    setNews = (news: Array<NewsInterface>) => {
        this.news = news;
    };

    setTags = (tags: Array<TagInterface>) => {
        this.tags = tags;
    };

    setSearch = (search: string) => {
        this.search = search;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setNewsLoading = (bool: boolean) => {
        this.loadingNews = bool;
    };

    setTagsLoading = (bool: boolean) => {
        this.loadingTags = bool;
    };

    setLoadingMore = (bool: boolean) => {
        this.loadingMore = bool;
    };

    setHasMore = (bool: boolean) => {
        this.hasMore = bool;
    };

    swapLike = async (id: number) => {
        try {
            const res = await NewsService.swapLike(id);
            let changedNews: Array<NewsInterface> = [];

            for (const el of this.news) {
                let copiedEl = { ...el };
                if (copiedEl.id === id) {
                    copiedEl.liked = res.data.liked;
                    if (res.data.liked) {
                        copiedEl.likes++;
                    } else {
                        copiedEl.likes--;
                    }
                }
                changedNews.push(copiedEl);
            }
            this.setNews(changedNews);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Ошибка");
        }
    };

    swapTagsFilter = (id: number) => {
        if (this.tagsFilter.includes(id)) {
            this.tagsFilter = this.tagsFilter.filter((el) => el !== id);
        } else {
            this.tagsFilter = [...this.tagsFilter, id];
        }
    };

    fetchNews = async () => {
        if (this.page === 1) {
            this.setNewsLoading(true);
        } else {
            this.setLoadingMore(true);
        }

        setTimeout(async () => {
            try {
                const news = await NewsService.getPublishedNews(this.page, this.pageSize, this.search, this.tagsFilter);
                if (this.page === 1) {
                    this.setNews(news.data.results);
                } else {
                    this.setNews([...this.news, ...news.data.results]);
                }
                this.setHasMore(!!news.data.next);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса новостей");
            } finally {
                this.setNewsLoading(false);
                this.setLoadingMore(false);
            }
        }, 0);
    };

    fetchTags = async () => {
        setTimeout(async () => {
            try {
                const tags = await NewsService.getTags();
                this.setTags(tags.data);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса тегов");
            } finally {
                this.setTagsLoading(false);
            }
        }, 0);
    };
}
