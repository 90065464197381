import { useContext } from "react";
import { ProfileContext, ProfileContextInterface } from "../../../context";
import "./styles.css";
import { Link } from "@mui/material";
import dayjs from "dayjs";
import { BirthdateInterface } from "../../../stores";
import { observer } from "mobx-react-lite";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { MyProfileStore } from "../../../stores";
import { declOfNum } from "../../../../../utils/common";

interface VisibilityMarkInterface {
    store: MyProfileStore;
    field: "phone" | "birthdate" | "tgUsername";
}

const ContactInfo = () => {
    const { store } = useContext(ProfileContext) as ProfileContextInterface;

    const checkEmpty = (value: any) => {
        if (!!value) return value;
        return "-";
    };

    const birthdateRepr = (birthdate: BirthdateInterface) => {
        if (birthdate.y) {
            const date = dayjs({ day: birthdate.d, month: birthdate.m - 1, year: birthdate.y });
            return date.format("D MMMM, YYYY");
        }
        const date = dayjs({ day: birthdate.d, month: birthdate.m - 1 });
        return date.format("D MMMM");
    };

    const serviceLengthRepr = (company_joined_date: string | undefined): string => {
        if (company_joined_date) {
            const now = dayjs();
            const diff = now.diff(dayjs(company_joined_date), "months");
            const years = Math.floor(diff / 12);
            const months = diff % 12;
            const yearDecl = years ? `${years} ${declOfNum(years, ["год", "года", "лет"])} ` : ""
            const monthDecl = months ? `${months} ${declOfNum(months, ["месяц", "месяца", "месяцев"])}` : ""
            const lessThanMonthDecl = !years && !months ? "менее месяца" : ""
            return `${yearDecl}${monthDecl}${lessThanMonthDecl}`;
        }
        return "-";
    };

    return (
        <div className="bg-[#E3EBF8] dark:dark-surface-400 rounded-[28px] p-[21px] xl:p-[28px] xl:bg-white">
            <div className="font-bold xl:text-[30px] xl:leading-[105%]">Контактная информация</div>
            <div className="flex flex-col gap-5 justify-between items-stretch mt-[10px] xl:flex-row xl:mt-[28px]">
                <div className="basis-1/2 flex flex-col gap-[18px]">
                    <div className="flex items-center">
                        <div className="info-name">Телефон:</div>
                        <div className="info-value">
                            {store.profile?.phone ? (
                                <div className="flex items-center gap-2">
                                    <Link href={"tel:" + store.profile.phone} className="social-link">{store.profile.phone}</Link>
                                    {store.name === "myProfile" && (
                                        <VisibilityMark store={store as MyProfileStore} field="phone" />
                                    )}
                                </div>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Доб. номер:</div>
                        <div className="info-value">
                            {store.profile?.extension_number ? (
                                <Link href={"tel:" + store.profile.extension_number} className="social-link">
                                    {store.profile.extension_number}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="info-name">E-mail:</div>
                        <div className="info-value">
                            {store.profile?.user.email ? (
                                <Link href={"mailto:" + store.profile.user.email} className="social-link">{store.profile.user.email}</Link>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Telegram:</div>
                        <div className="info-value">
                            {store.profile?.telegram_username ? (
                                <div className="flex items-center gap-2">
                                    <Link href={`https://t.me/${store.profile.telegram_username}`} className="social-link">
                                        {"@" + store.profile.telegram_username}
                                    </Link>
                                    {store.name === "myProfile" && (
                                        <VisibilityMark store={store as MyProfileStore} field="tgUsername" />
                                    )}
                                </div>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                </div>
                <div className="h-0.5 w-auto xl:h-auto xl:w-0.5 bg-[#DCDCDC]"/>
                <div className="basis-1/2 flex flex-col gap-[18px]">
                    <div className="flex items-center">
                        <div className="info-name">Дата рождения:</div>
                        <div className="info-value">
                            {store.profile?.birthdate && (
                                <div className="flex items-center gap-2">
                                    {birthdateRepr(store.profile.birthdate)}
                                    {store.name === "myProfile" && (
                                        <VisibilityMark store={store as MyProfileStore} field="birthdate" />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Организация:</div>
                        <div className="info-value">{checkEmpty(store.profile?.department?.company?.name)}</div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Отдел:</div>
                        <div className="info-value">{checkEmpty(store.profile?.department?.name)}</div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Стаж работы:</div>
                        <div className="info-value">{serviceLengthRepr(store.profile?.company_joined_date)}</div>
                    </div>
                    <div className="flex">
                        <div className="info-name">Руководитель:</div>
                        <div className="info-value">
                            {store.profile?.manager ? (
                                <Link href={`/users/profile/${store.profile.manager.id}`} className="social-link">
                                    {store.profile.manager.last_name} {store.profile.manager.first_name}
                                </Link>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const VisibilityMark = observer(({ store, field }: VisibilityMarkInterface) => {
    if (
        (!store.privacy.show_birthdate && field === "birthdate") ||
        (!store.privacy.show_phone && field === "phone") ||
        (!store.privacy.show_tg_username && field === "tgUsername")
    ) {
        return (
            <div title="Информация скрыта от других пользователей">
                <VisibilityOffIcon fontSize="small" color="secondary" />
            </div>
        );
    }
    return null;
});

export default observer(ContactInfo);
