import { SyntheticEvent, useState, useContext, BaseSyntheticEvent, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Input from "../../components/inputs/Input";
import Button from "../../components/inputs/Button";
import useImageLoad from "../../hooks/useImageLoad";
import LoadingScreen from "../../components/LoadingScreen";
import Title from "./Title";
import { enqueueSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DomainStore from "../../stores/domainStore";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const { domainStore }: { domainStore: DomainStore } = useContext(AuthContext);
    const navigate = useNavigate();
    const [imageLoaded, imageError] = useImageLoad(window.location.origin + "/LoginPage.png");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        setLoginError(false);

        const searchParams = new URLSearchParams(window.location.search);
        const res = await domainStore.loginBySSO(searchParams.has("next") ? (searchParams.get("next") as string) : "");
        if (res.status === 200) {
            window.location.href = res.data;
        } else if (res.status === 401) {
            setLoginError(true);
        } else {
            enqueueSnackbar("Внутрення ошибка сервера");
        }
        setLoading(false);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("access") && searchParams.has("refresh")) {
            domainStore.handleTokenReceive(searchParams.get("access") as string, searchParams.get("refresh") as string);
            if (searchParams.has("next")) {
                navigate(searchParams.get("next") as string);
            } else {
                navigate("/");
            }
        }
    }, []);

    const handleSSOLogin = async (e: BaseSyntheticEvent) => {
        e.preventDefault();
    };

    if (imageLoaded || imageError) {
        return (
            <div
                className="flex justify-center h-screen overflow-auto items-center dark:!bg-none"
                style={{
                    backgroundImage: "url(/LoginPage.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div className="w-[300px] flex flex-col items-center xl:w-[532px]">
                    <div className="scale-[85%]  xl:scale-100">
                        <Title />
                    </div>
                    <form
                        className="py-3 px-5 mt-7 bg-white dark:dark-surface-300 rounded-2xl w-full flex flex-col xl:py-10 xl:px-[67px] xl:mt-[75px] xl:rounded-[25px]"
                        onSubmit={handleSubmit}
                    >
                        <Button                        
                            styles={{ height: 32, width: "100%", borderRadius: "22px", padding: "20px 0" }}
                            text="Войти"
                            loading={loading}
                            onClick={handleSSOLogin}
                        />
                        {/* <Input
                            title="Логин"
                            value={username}
                            onChange={(e: React.BaseSyntheticEvent) => {
                                setUsername(e.target.value);
                                setLoginError(false);
                            }}
                            variant="outlined"
                            required
                            autoFocus
                        />
                        <Input
                            styles={{ marginTop: 6 }}
                            title="Пароль"
                            value={password}
                            onChange={(e: React.BaseSyntheticEvent) => {
                                setPassword(e.target.value);
                                setLoginError(false);
                            }}
                            required
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            adornment={
                                showPassword ? (
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => setShowPassword(false)}
                                        title="Скрыть пароль"
                                    >
                                        <VisibilityOffIcon fontSize="small" />
                                    </div>
                                ) : (
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => setShowPassword(true)}
                                        title="Показать пароль"
                                    >
                                        <RemoveRedEyeIcon fontSize="small" />
                                    </div>
                                )
                            }
                        />
                        {loginError && (
                            <div className="mt-[10px] text-[#BB2525]">
                                <WarningIcon /> Неверный логин или пароль
                            </div>
                        )}
                        <div className="mt-[38px] w-full">
                            <Button
                                styles={{ height: 32, width: "100%", borderRadius: "22px", padding: "20px 0" }}
                                text="Войти"
                                loading={loading}
                            />
                        </div> */}
                    </form>
                </div>
            </div>
        );
    }
    return <LoadingScreen />;
};

export default LoginPage;
