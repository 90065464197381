import { useRef } from "react";

const PreviewTab = () => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div className="absolute top-0 left-0 w-full">
            <div className="absolute bottom-0 left-0 w-full flex">
                <div className="bg-white dark:dark-surface-300 rounded-t-[28px] font-bold text-[25px] py-[10px] px-[30px]" ref={ref}>
                    Превью
                </div>
                <div className="h-full basis-full bg-white dark:dark-surface-300 relative" style={{height: ref.current?.clientHeight}}>
                    <div className="absolute h-full w-full bg-[#F4F4F6] dark:dark-surface-200 rounded-bl-[28px]"/>
                </div>
            </div>
        </div>
    );
};

export default PreviewTab;
