import { CommentInterface } from "../interfaces";
import { useTranslation } from "react-i18next";
import { UpperLevelCommentStore } from "../store";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import LowerLevelComment from "./LowerLevelComment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Loader from "./Loader";
import { CommentsStore } from "../store";
import CommentBody from "./CommentBody";

interface UpperLevelCommentInterface {
    comment: CommentInterface;
    store: CommentsStore;
}

const UpperLevelComment = ({ comment, store }: UpperLevelCommentInterface) => {
    const { t } = useTranslation();
    const [upperStore] = useState(() => new UpperLevelCommentStore(comment, store));

    const renderChildComments = () => {
        if (upperStore.loadingComments) {
            return (
                <div className="mt-3">
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className="ml-7 xl:ml-20">
                    <div className="mt-4 flex flex-col gap-8">
                        {upperStore.childComments.map((el) => (
                            <div key={el.id}>
                                <LowerLevelComment comment={el} upperStore={upperStore} />
                            </div>
                        ))}
                    </div>
                    {upperStore.loadingMore && (
                        <div className="mt-3">
                            <Loader />
                        </div>
                    )}
                    {upperStore.hasMore && !upperStore.loadingMore && (
                        <div
                            className="avilex-color text-sm xl:text-base hover:cursor-pointer mt-5 dark:dark-primary-text-400"
                            onClick={() => upperStore.setPage(upperStore.page + 1)}
                        >
                            Показать больше
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div>
            <CommentBody comment={comment} store={upperStore} />
            {comment.child_comments && comment.child_comments > 0 ? (
                <div className="mt-[12px]">
                    <div className="gap-2 flex items-center xl:gap-5">
                        <hr className="w-6 border-black dark:border-white xl:w-[34px]" />
                        <div
                            className="text-sm hover:cursor-pointer avilex-color dark:dark-primary-text-400 xl:text-lg"
                            onClick={() => upperStore.setChildrenExpanded(!upperStore.childrenExpanded)}
                        >
                            {t("comment", { count: comment.child_comments })}{" "}
                            {upperStore.childrenExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </div>
                    </div>
                    {upperStore.childrenExpanded && renderChildComments()}
                </div>
            ) : null}
        </div>
    );
};

export default observer(UpperLevelComment);
