import { makeAutoObservable, reaction } from "mobx";
import dayjs from "dayjs";
import api from "../../../http";
import { enqueueSnackbar } from "notistack";
import Cookies from "universal-cookie";

interface BaseSearchItem {
    type: "news" | "user";
}

export interface NewsInterface extends BaseSearchItem {
    id: number;
    title: string;
}

export interface UsersInterface extends BaseSearchItem {
    id: number;
    first_name: string;
    last_name: string;
}

export default class HeaderStore {
    maxRecentSearchLength: number = 100;
    hours: string = dayjs().format("HH");
    minutes: string = dayjs().format("mm");

    search: string = "";
    searchOpen: boolean = false;
    news: Array<NewsInterface> = [];
    users: Array<UsersInterface> = [];
    loading: boolean = true;
    showUsers: boolean = false;
    showNews: boolean = false;
    searchResults: Array<NewsInterface | UsersInterface> = [];
    recentSearch: Array<Array<string>>;

    modalOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);

        const cookies = new Cookies(null, { path: "/" });
        let searchList = cookies.get("recentSearch");
        if (!searchList) searchList = [];
        this.recentSearch = searchList;

        reaction(
            () => this.search,
            async (val) => {
                if (val) {
                    await this.fetchResults();
                } else {
                    this.setSearchResults([]);
                }
            },
            {
                delay: 500,
            }
        );
    }

    setSearch = (search: string) => {
        this.search = search;
    };

    setSearchOpen = (bool: boolean) => {
        this.searchOpen = bool;
    };

    setLoading = (bool: boolean) => {
        this.loading = bool;
    };

    setModalOpen = (bool: boolean) => {
        this.modalOpen = bool;
    };

    setHours = (hours: string) => {
        this.hours = hours;
    };

    setMinutes = (minutes: string) => {
        this.minutes = minutes;
    };

    setUsers = (users: Array<UsersInterface>) => {
        this.users = users;
    };

    setNews = (news: Array<NewsInterface>) => {
        this.news = news;
    };

    setShowUsers = (bool: boolean) => {
        this.showUsers = bool;
    };

    setShowNews = (bool: boolean) => {
        this.showNews = bool;
    };

    setSearchResults = (searchResults: Array<NewsInterface | UsersInterface>) => {
        this.searchResults = searchResults;
    };

    removeOneCookie = (search: string): number => {
        const cookies = new Cookies(null, { path: "/" });
        let searchList = cookies.get("recentSearch");
        if (!searchList) searchList = [];
        for (const [i, value] of searchList.entries()) {
            if (value[0] === search) {
                return i;
            }
        }
        return -1;
    };

    removeCookieFromList = (search: string) => {
        const cookies = new Cookies(null, { path: "/" });
        let searchList = cookies.get("recentSearch");
        if (!searchList) searchList = [];

        const removeIdx = this.removeOneCookie(search);
        if (removeIdx >= 0) {
            searchList.splice(removeIdx, 1);
        }
        cookies.set("recentSearch", searchList);
        this.setRecentSearch(searchList);
    };

    removeAllRecentCookies = () => {
        const cookies = new Cookies(null, { path: "/" });
        cookies.remove("recentSearch");
        this.setRecentSearch([]);
    };

    saveNewCookie = () => {
        const currentSearch = this.search.trim().toLocaleLowerCase();
        const cookies = new Cookies(null, { path: "/" });
        let searchList = cookies.get("recentSearch");
        if (!searchList) searchList = [];

        const removeIdx = this.removeOneCookie(currentSearch);
        if (removeIdx >= 0) {
            searchList.splice(removeIdx, 1);
        }
        searchList.push([currentSearch, dayjs().format()]);
        cookies.set("recentSearch", searchList.slice(-this.maxRecentSearchLength));
    };

    setRecentSearch = (recentSearch: Array<Array<string>>) => {
        this.recentSearch = recentSearch;
    };

    fetchResults = async () => {
        this.setLoading(true);

        setTimeout(async () => {
            await api
                .get("/portal-search/", { params: { search: this.search } })
                .then((resp) => {
                    let users: Array<UsersInterface> = [];
                    resp.data.users.forEach((el: UsersInterface) => {
                        users.push({ ...el, type: "user" });
                    });
                    let news: Array<NewsInterface> = [];
                    resp.data.news.forEach((el: NewsInterface) => {
                        news.push({ ...el, type: "news" });
                    });
                    this.setSearchResults([...users, ...news]);
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar("Ошибка запроса новостей");
                })
                .finally(() => {
                    this.setLoading(false);
                });
        }, 0);
    };
}
