import { useState, useRef, useEffect } from "react";
import NewsService from "../../../services/NewsService";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useMyTipTapEditor from "../../../hooks/useMyTipTapEditor";
import NewsChangeBase from "../components/NewsChangeBase";

interface TagsInterface {
    id: number;
    name: string;
    color: string;
}

interface SelectTagsInterface extends TagsInterface {
    selected: boolean;
}

const CreateNews = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [titleImage, setTitleImage] = useState<any>(null);
    const [serverTags, setServerTags] = useState<Array<SelectTagsInterface>>([]);
    const [images, setImages] = useState<FileList>(new DataTransfer().files);
    const [loadingButtonName, setLoadingButtonName] = useState<string>("");

    const textRef = useRef<HTMLTextAreaElement>(null);
    const [editor, tipTapEditor]: any = useMyTipTapEditor({
        text,
        setText,
        placeholder: "Введите основной текст новости, чтобы его увидели пользователи портала",
    });

    useEffect(() => {
        async function fetchTags() {
            try {
                const fetchedTags = await NewsService.getTags();
                setServerTags(fetchedTags.data.map((el: TagsInterface) => ({ ...el, selected: false })));
            } catch (e) {
                enqueueSnackbar("Ошибка запроса тегов");
                console.error(e);
            }
        }
        fetchTags();
    }, []);

    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.height = "auto";
            textRef.current.style.height = textRef.current.scrollHeight + "px";
        }
    }, [text]);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!titleImage) {
            enqueueSnackbar("Необходимо приложить главное фото");
            return;
        }

        if (editor && !editor.state.doc.textContent.trim().length) {
            enqueueSnackbar("Необходимо заполнить текст статьи");
            return;
        }

        const config = { headers: { "Content-type": "multipart/form-data" } };
        let formData = new FormData();
        let status;
        switch (event.nativeEvent.submitter.name) {
            case "draft":
                status = 1;
                break;
            case "moderate":
                status = 2;
                break;
            default:
                status = 4;
        }
        const data = {
            title: title,
            text: text,
            tags: serverTags.filter((el) => el.selected),
            status: status,
        };
        formData.append("data", JSON.stringify(data));
        formData.append("title_image", titleImage);
        Array.from(images).forEach((image) => {
            formData.append("uploaded_images", image);
        });

        async function createNews() {
            try {
                setLoadingButtonName(event.nativeEvent.submitter.name);
                await NewsService.createNews(formData, config);
                navigate("/news/mine");
                enqueueSnackbar("Новость успешно создана");
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка создания новости");
            } finally {
                setLoadingButtonName("");
            }
        }
        createNews();
    };

    return (
        <NewsChangeBase
            action="create"
            text={text}
            pageTitle="Создание новости"
            handleSubmit={handleSubmit}
            titleImage={titleImage}
            setTitleImage={setTitleImage}
            title={title}
            setTitle={setTitle}
            serverTags={serverTags}
            setServerTags={setServerTags}
            images={images}
            setImages={setImages}
            loadingButtonName={loadingButtonName}
            editor={editor}
            tipTapEditor={tipTapEditor}
        />
    );
};

export default CreateNews;
