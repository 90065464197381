interface SubscriptionInterface {
    publicKey: string;
}

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

export const useSubscribe = ({ publicKey }: SubscriptionInterface) => {
    const getSubscription = async () => {
        // Проверка поддержки ServiceWorker и PushManager
        if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
            throw { errorCode: "ServiceWorkerAndPushManagerNotSupported" };
        }

        // Ожидание готовности Service Worker
        const registration = await navigator.serviceWorker.ready;

        // Проверка наличия pushManager в регистрации
        if (!registration.pushManager) {
            throw { errorCode: "PushManagerUnavailable" };
        }

        // Проверка на наличие существующей подписки
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
            return existingSubscription;
            // throw { errorCode: "ExistingSubscription" };
        }

        // Преобразование VAPID-ключа для использования в подписке
        const convertedVapidKey = urlBase64ToUint8Array(publicKey);
        return await registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
        });
    };

    return { getSubscription };
};
