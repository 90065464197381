import React from "react";
import SideBar from "./Sidebar";
import Header from "./Header";
import "./styles.css";

interface LayoutProps {
    children?: React.ReactNode;
}

const MainLayout = ({ children }: LayoutProps) => {
    const navbarWidth = 68;
    const navbarWidthExpanded = 274;

    return (
        <div>
            <div className="hidden xl:block">
                <SideBar width={navbarWidth} fullWidth={navbarWidthExpanded} />
            </div>
            <div style={{ "--navbarWidth": navbarWidth + "px" } as any} className="xl:px-10 2xl:px-[95px] main-block">
                <Header />
                <div className="py-[13px] px-[15px] sm:px-5 md:px-7 xl:px-0 xl:py-[30px]">{children}</div>
            </div>
        </div>
    );
};

export default MainLayout;
