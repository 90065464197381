import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import Search from "../../../components/inputs/Search";
import { motion } from "framer-motion";
import "./styles.css";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

interface UpperBlockInterface {
    search: string;
    setSearch: Function;
    currentTab: string;
    setCurrentTab: Function;
}

const UpperBlock = ({ search, setSearch, currentTab, setCurrentTab }: UpperBlockInterface) => {
    const navigate = useNavigate();
    const { domainStore } = useContext(AuthContext);
    const variants = {
        initial: { backgroundColor: domainStore.theme === "dark" ? "#717171" : "#E3EBF8" },
        selected: { backgroundColor: domainStore.theme === "dark" ? "#7A5AF5" : "#B0CAF5" },
    };

    const handleTabClick = (e: any) => {
        switch (e.target.id) {
            case "moderationTab":
                if (currentTab.includes("on_moderation")) {
                    setCurrentTab("");
                } else {
                    setCurrentTab("status__code_name=on_moderation");
                }
                break;
            case "publishedTab":
                if (currentTab.includes("published")) {
                    setCurrentTab("");
                } else {
                    setCurrentTab("status__code_name=published");
                }
                break;
            default:
                if (!domainStore.userIsLoading) {
                    if (currentTab.includes("author")) {
                        setCurrentTab("");
                    } else {
                        setCurrentTab(`author=${domainStore.user.id}`);
                    }
                }
        }
    };

    return (
        <div>
            <div className="grid grid-cols-2 gap-3">
                <div className="col-span-2 flex justify-between items-center avx-page-h xl:block xl:col-span-1">
                    <div>Мои новости</div>
                    <div
                        className="bg-white dark:dark-surface-400 p-1 flex justify-center items-center rounded-md xl:hidden"
                        onClick={() => navigate("/news/create")}
                    >
                        <AddIcon fontSize="small" />
                    </div>
                </div>
                {!domainStore.userIsLoading && domainStore.isModerator && (
                    <div className="col-span-2 gap-3 flex justify-start items-center xl:gap-4 sm:col-span-1">
                        <motion.div
                            id="myTab"
                            className="news-tab"
                            onClick={handleTabClick}
                            initial="initial"
                            variants={variants}
                            animate={currentTab.includes("author") ? "selected" : "initial"}
                        >
                            Мои
                        </motion.div>
                        <motion.div
                            id="moderationTab"
                            className="news-tab"
                            onClick={handleTabClick}
                            initial="initial"
                            variants={variants}
                            animate={currentTab.includes("on_moderation") ? "selected" : "initial"}
                        >
                            На проверку
                        </motion.div>
                        <motion.div
                            id="publishedTab"
                            className="news-tab"
                            onClick={handleTabClick}
                            initial="initial"
                            variants={variants}
                            animate={currentTab.includes("published") ? "selected" : "initial"}
                        >
                            Опубликованные
                        </motion.div>
                    </div>
                )}
            </div>
            <div className="mt-4 xl:mt-6">
                <Search
                    value={search}
                    placeholder="Поиск"
                    onChange={(e: React.BaseSyntheticEvent) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default observer(UpperBlock);
