export const Home = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.7343 9.50945L17.52 3.61877C16.37 2.79374 14.8421 2.79374 13.7085 3.61877L5.49426 9.50945C4.62354 10.1365 4.11426 11.1265 4.11426 12.199V25.3499C4.11426 26.2575 4.85354 27 5.75712 27H12.3285V17.0997H18.9V27H25.4714C26.375 27 27.1143 26.2575 27.1143 25.3499V12.199C27.1143 11.1265 26.605 10.1365 25.7343 9.50945Z"
                fill="white"
            />
        </svg>
    );
};

export const Audio = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.804 16.9928C15.2916 16.9928 17.3082 14.9762 17.3082 12.4886C17.3082 10.001 15.2916 7.98438 12.804 7.98438C10.3164 7.98438 8.2998 10.001 8.2998 12.4886C8.2998 14.9762 10.3164 16.9928 12.804 16.9928Z"
                fill="white"
            />
            <path
                d="M19.9997 19.8748C18.108 18.9064 15.6532 18.1182 12.8043 18.1182C9.95538 18.1182 7.50059 18.9064 5.60883 19.8748C4.48279 20.4491 3.7959 21.6089 3.7959 22.8701V23.7484C3.7959 24.9871 4.80934 26.0005 6.04799 26.0005H19.5606C20.7992 26.0005 21.8127 24.9871 21.8127 23.7484V22.8701C21.8127 21.6089 21.1258 20.4491 19.9997 19.8748Z"
                fill="white"
            />
            <path
                d="M25.3148 4.43689C24.8982 3.89639 24.0987 3.85135 23.6257 4.33555C23.2316 4.72967 23.1866 5.36025 23.5244 5.79941C24.8419 7.48848 26.4972 11.6886 23.5244 15.4947C23.1866 15.9338 23.2316 16.5644 23.6257 16.9585C24.1099 17.4427 24.8982 17.3864 25.3148 16.8572C27.0039 14.6839 29.0871 9.29016 25.3148 4.43689Z"
                fill="white"
            />
            <path
                d="M22.1621 7.80321C21.7905 7.16136 20.9122 7.04876 20.3829 7.56674C20.0113 7.93834 19.9776 8.51262 20.2366 8.9743C20.5181 9.46976 21.0698 10.8773 20.2253 12.3412C19.9663 12.7916 20.0113 13.3546 20.3829 13.715C20.9122 14.2442 21.7905 14.1091 22.1621 13.4672C23.4683 11.2264 22.8152 8.94052 22.1621 7.80321Z"
                fill="white"
            />
        </svg>
    );
};

export const Company = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0462 13.1915L12.4164 9.15585C11.6282 8.59063 10.5811 8.59063 9.80416 9.15585L4.17433 13.1915C3.57757 13.6211 3.22852 14.2993 3.22852 15.0341V24.0437C3.22852 24.6654 3.7352 25.1741 4.35448 25.1741H8.85835V18.3915H13.3622V25.1741H17.8661C18.4854 25.1741 18.992 24.6654 18.992 24.0437V15.0341C18.992 14.2993 18.643 13.6211 18.0462 13.1915Z"
                fill="white"
            />
            <path
                d="M25.7817 4.82617H15.5805C14.3532 4.82617 13.3623 5.82095 13.3623 7.05313L13.4636 7.15487C13.5537 7.21139 13.6438 7.25661 13.7339 7.31313L19.3637 11.3488C20.2194 11.9592 20.8275 12.8636 21.0977 13.8696H23.496V16.1305H21.2441V18.3914H23.496V20.6523H21.2441V24.0436V25.174H25.7817C27.009 25.174 27.9999 24.1792 27.9999 22.947V7.05313C27.9999 5.82095 27.009 4.82617 25.7817 4.82617ZM23.496 11.6088H21.2441V9.34791H23.496V11.6088Z"
                fill="white"
            />
        </svg>
    );
};

export const Documents = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0036 3H8.48926C7.18301 3 6.11426 4.08 6.11426 5.4V24.6C6.11426 25.92 7.18301 27 8.48926 27H22.7393C24.0455 27 25.1143 25.92 25.1143 24.6V11.196C25.1143 10.56 24.8649 9.948 24.4136 9.504L18.678 3.708C18.2386 3.252 17.633 3 17.0036 3ZM15.6143 12.6C16.9205 12.6 17.9893 13.68 17.9893 15C17.9893 16.32 16.9205 17.4 15.6143 17.4C14.308 17.4 13.2393 16.32 13.2393 15C13.2393 13.68 14.308 12.6 15.6143 12.6ZM20.3643 22.2H10.8643V21.516C10.8643 20.544 11.4343 19.68 12.313 19.296C13.3224 18.852 14.4386 18.6 15.6143 18.6C16.7899 18.6 17.9061 18.852 18.9155 19.296C19.7943 19.68 20.3643 20.544 20.3643 21.516V22.2Z"
                fill="white"
            />
        </svg>
    );
};

export const Edu = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.59088 16.4832V20.0264C6.59088 20.9468 7.06918 21.8043 7.83445 22.2456L13.8132 25.6879C14.5306 26.104 15.3916 26.104 16.109 25.6879L22.0877 22.2456C22.853 21.8043 23.3313 20.9468 23.3313 20.0264V16.4832L16.109 20.6442C15.3916 21.0603 14.5306 21.0603 13.8132 20.6442L6.59088 16.4832ZM13.8132 4.30262L3.73305 10.1029C2.90799 10.582 2.90799 11.843 3.73305 12.3221L13.8132 18.1224C14.5306 18.5385 15.3916 18.5385 16.109 18.1224L25.7228 12.5869V20.039C25.7228 20.7325 26.2609 21.2999 26.9185 21.2999C27.5762 21.2999 28.1143 20.7325 28.1143 20.039V11.9564C28.1143 11.4899 27.8751 11.0738 27.4925 10.8468L16.109 4.30262C15.3916 3.89913 14.5306 3.89913 13.8132 4.30262Z"
                fill="white"
            />
        </svg>
    );
};

export const News = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.81426 7.8C4.15426 7.8 3.61426 8.34 3.61426 9V24.6C3.61426 25.92 4.69426 27 6.01426 27H21.6143C22.2743 27 22.8143 26.46 22.8143 25.8C22.8143 25.14 22.2743 24.6 21.6143 24.6H7.21426C6.55426 24.6 6.01426 24.06 6.01426 23.4V9C6.01426 8.34 5.47426 7.8 4.81426 7.8ZM25.2143 3H10.8143C9.49426 3 8.41426 4.08 8.41426 5.4V19.8C8.41426 21.12 9.49426 22.2 10.8143 22.2H25.2143C26.5343 22.2 27.6143 21.12 27.6143 19.8V5.4C27.6143 4.08 26.5343 3 25.2143 3ZM22.8143 13.8H13.2143C12.5543 13.8 12.0143 13.26 12.0143 12.6C12.0143 11.94 12.5543 11.4 13.2143 11.4H22.8143C23.4743 11.4 24.0143 11.94 24.0143 12.6C24.0143 13.26 23.4743 13.8 22.8143 13.8ZM18.0143 18.6H13.2143C12.5543 18.6 12.0143 18.06 12.0143 17.4C12.0143 16.74 12.5543 16.2 13.2143 16.2H18.0143C18.6743 16.2 19.2143 16.74 19.2143 17.4C19.2143 18.06 18.6743 18.6 18.0143 18.6ZM22.8143 9H13.2143C12.5543 9 12.0143 8.46 12.0143 7.8C12.0143 7.14 12.5543 6.6 13.2143 6.6H22.8143C23.4743 6.6 24.0143 7.14 24.0143 7.8C24.0143 8.46 23.4743 9 22.8143 9Z"
                fill="white"
            />
        </svg>
    );
};

export const Person = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6143 15C19.0674 15 21.8643 12.315 21.8643 9C21.8643 5.685 19.0674 3 15.6143 3C12.1611 3 9.36426 5.685 9.36426 9C9.36426 12.315 12.1611 15 15.6143 15ZM15.6143 18C11.4424 18 3.11426 20.01 3.11426 24V25.5C3.11426 26.325 3.81738 27 4.67676 27H26.5518C27.4111 27 28.1143 26.325 28.1143 25.5V24C28.1143 20.01 19.7861 18 15.6143 18Z"
                fill="white"
            />
        </svg>
    );
};

export const Projects = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.45 7.092L20.9819 3.696C20.5285 3.252 19.9035 3 19.254 3H5.56524C4.2172 3 3.11426 4.08 3.11426 5.4V22.2C3.11426 23.52 4.2172 24.6 5.56524 24.6H14.6339L25.1731 14.28V8.796C25.1731 8.16 24.9157 7.548 24.45 7.092ZM14.1437 21C12.1094 21 10.4672 19.392 10.4672 17.4C10.4672 15.408 12.1094 13.8 14.1437 13.8C16.178 13.8 17.8201 15.408 17.8201 17.4C17.8201 19.392 16.178 21 14.1437 21ZM17.8201 10.2C17.8201 10.86 17.2687 11.4 16.5947 11.4H8.01622C7.3422 11.4 6.79073 10.86 6.79073 10.2V7.8C6.79073 7.14 7.3422 6.6 8.01622 6.6H16.5947C17.2687 6.6 17.8201 7.14 17.8201 7.8V10.2ZM23.9353 18.9L26.1045 21.024L20.1731 26.832C20.0505 26.94 19.8912 27 19.7319 27H18.4329C18.0897 27 17.8201 26.736 17.8201 26.4V25.128C17.8201 24.972 17.8814 24.816 18.004 24.708L23.9353 18.9ZM27.9304 19.212L26.8888 20.232L24.7196 18.108L25.7613 17.088C26.0064 16.848 26.3863 16.848 26.6314 17.088L27.9304 18.36C28.1755 18.6 28.1755 18.984 27.9304 19.212Z"
                fill="white"
            />
        </svg>
    );
};

export const Work = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.8998 13.2857C23.6028 13.2857 25.18 13.8229 26.4715 14.7258V9.85697C26.4715 8.58834 25.4543 7.57115 24.1857 7.57115H19.614V5.28533C19.614 4.0167 18.5968 2.99951 17.3282 2.99951H12.7566C11.4879 2.99951 10.4707 4.0167 10.4707 5.28533V7.57115H5.8991C4.63047 7.57115 3.62471 8.58834 3.62471 9.85697L3.61328 22.429C3.61328 23.6976 4.63047 24.7148 5.8991 24.7148H14.6767C14.1852 23.6748 13.8995 22.5204 13.8995 21.2861C13.8995 16.863 17.4768 13.2857 21.8998 13.2857ZM12.7566 5.28533H17.3282V7.57115H12.7566V5.28533Z"
                fill="white"
            />
            <path
                d="M21.9001 15.5708C18.7457 15.5708 16.1855 18.1309 16.1855 21.2854C16.1855 24.4398 18.7457 26.9999 21.9001 26.9999C25.0545 26.9999 27.6146 24.4398 27.6146 21.2854C27.6146 18.1309 25.0545 15.5708 21.9001 15.5708ZM23.7859 23.9712L21.3286 21.5139V17.8566H22.4716V21.0453L24.5859 23.1597L23.7859 23.9712Z"
                fill="white"
            />
        </svg>
    );
};

export const Exit = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.034 6.25641H23.5082V23.7431H16.034C15.3488 23.7431 14.7883 24.3052 14.7883 24.9922C14.7883 25.6791 15.3488 26.2412 16.034 26.2412H23.5082C24.8785 26.2412 25.9997 25.1171 25.9997 23.7431V6.25641C25.9997 4.88245 24.8785 3.7583 23.5082 3.7583H16.034C15.3488 3.7583 14.7883 4.32037 14.7883 5.00735C14.7883 5.69433 15.3488 6.25641 16.034 6.25641ZM8.88002 11.078L5.4045 14.5628C5.16782 14.8001 5.16782 15.1998 5.41696 15.4371L8.89248 18.922C9.27865 19.3217 9.95133 19.0469 9.95133 18.4848V16.249H18.6713C19.3564 16.249 19.917 15.6869 19.917 14.9999C19.917 14.313 19.3564 13.751 18.6713 13.751H9.95133V11.5151C9.95133 10.953 9.27865 10.6783 8.88002 11.078Z"
                fill="white"
            />
        </svg>
    );
};
