import { BaseSyntheticEvent, useEffect, useState, useContext } from "react";
import { Editor, EditorContent } from "@tiptap/react";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import EmojiPicker, { EmojiStyle, EmojiClickData } from "emoji-picker-react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { emojiCategories } from "./utils";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
} from "@floating-ui/react";
import { observer } from "mobx-react-lite";
import AuthContext from "../../../context/AuthContext";
import { Theme } from "emoji-picker-react";
import "./styles.css";

interface TipTapEditorInterface {
    text: string;
    setText: Function;
    disabled: boolean;
    editor: Editor;
}

interface ToolbarInterface {
    editor: Editor;
    iconSize?: "small" | "medium" | "large";
}

interface EmojiMenuInterface {
    editor: Editor;
    iconSize: "small" | "medium" | "large";
}

const TipTapEditor = ({ disabled, editor }: TipTapEditorInterface) => {
    useEffect(() => {
        if (editor) {
            if (disabled) {
                editor.setEditable(false);
            } else {
                editor.setEditable(true);
            }
            editor.setOptions({
                editorProps: {
                    attributes: {
                        class: `focus:outline-none w-full border-x-2 p-2 overflow-auto min-h-96 max-h-[465px] font-medium ${
                            disabled ? "border-y-2 rounded-xl bg-[#EFEFEF]/30" : "border-b-2 rounded-b-xl"
                        }`,
                    },
                },
            });
        }
    }, [disabled]);

    if (!editor) return null;
    return (
        <div className="tip-tap-editor">
            {!disabled && (
                <div className="tip-tap-toolbar">
                    <Toolbar editor={editor} />
                </div>
            )}
            <div className="tip-tap-content">
                <EditorContent editor={editor} />
            </div>
        </div>
    );
};

const Toolbar = ({ editor, iconSize = "small" }: ToolbarInterface) => {
    const [linkDialogOpen, setLinkDialogOpen] = useState<boolean>(false);
    const [url, setUrl] = useState<string>("");

    const setLink = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === "") {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();
            return;
        }

        // update link
        editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
        setLinkDialogOpen(false);
    };

    const handleLinkClose = () => {
        setLinkDialogOpen(false);
    };

    const handleLinkOpen = () => {
        setLinkDialogOpen(true);
        const previousUrl = editor.getAttributes("link").href;
        setUrl(previousUrl ? previousUrl : "");
    };

    return (
        <div className="border-solid border-2 rounded-t-xl p-2 flex gap-2">
            <div className="flex gap-2 pe-2 border-r-2">
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        if (editor.isEditable) editor.chain().focus().toggleBold().run();
                    }}
                    className={`tool-icon ${editor.isActive("bold") && "is-active"}`}
                    title="Жирный (Ctrl + B)"
                >
                    <FormatBoldIcon fontSize={iconSize} />
                </button>
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        if (editor.isEditable) editor.chain().focus().toggleItalic().run();
                    }}
                    className={`tool-icon ${editor.isActive("italic") && "is-active"}`}
                    title="Курсив (Ctrl + I)"
                >
                    <FormatItalicIcon fontSize={iconSize} />
                </button>
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        if (editor.isEditable) editor.chain().focus().toggleUnderline().run();
                    }}
                    className={`tool-icon ${editor.isActive("underline") && "is-active"}`}
                    title="Подчёркнутый (Ctrl + U)"
                >
                    <FormatUnderlinedIcon fontSize={iconSize} />
                </button>
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        if (editor.isEditable) editor.chain().focus().toggleStrike().run();
                    }}
                    className={`tool-icon ${editor.isActive("strike") && "is-active"}`}
                    title="Зачёркнутый (Ctrl + Shift + S)"
                >
                    <FormatStrikethroughIcon fontSize={iconSize} />
                </button>
            </div>
            <div className="flex gap-2 pe-2 border-r-2">
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        handleLinkOpen();
                    }}
                    className={`tool-icon ${editor.isActive("link") && "is-active"}`}
                    title="Добавить ссылку"
                >
                    <InsertLinkIcon fontSize={iconSize} />
                </button>
                <button
                    onClick={(e: BaseSyntheticEvent) => {
                        e.preventDefault();
                        if (editor.isEditable) editor.chain().focus().unsetLink().run();
                    }}
                    className={`tool-icon ${!editor.isActive("link") && "opacity-50"}`}
                    disabled={!editor.isActive("link")}
                    title="Удалить ссылку"
                >
                    <LinkOffIcon fontSize={iconSize} />
                </button>
            </div>
            <div>
                <EmojiMenu editor={editor} iconSize={iconSize} />
            </div>
            <Dialog
                open={linkDialogOpen}
                onClose={handleLinkClose}
                PaperProps={{ component: "form", onSubmit: setLink }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <span className="text-[19px]">Введите ссылку</span>
                </DialogTitle>
                <DialogContent>
                    <div className="mt-1">
                        <TextField
                            fullWidth
                            autoFocus
                            required
                            onChange={(e: BaseSyntheticEvent) => setUrl(e.target.value)}
                            value={url}
                            size="small"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLinkClose} color="secondary" variant="outlined" size="small">
                        Отмена
                    </Button>
                    <Button type="submit" size="small" variant="contained">
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const EmojiMenu = observer(({ editor, iconSize }: EmojiMenuInterface) => {
    const { domainStore } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [theme, setTheme] = useState(domainStore.theme === "dark" ? Theme.DARK : Theme.LIGHT);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: (open: boolean, event, reason: any) => {
            if (reason === "escape-key" || reason === "outside-press") {
                setTimeout(() => editor.commands.focus(), 0);
            }
            setIsOpen(open);
        },
        middleware: [offset(10), flip({ fallbackAxisSideDirection: "end" }), shift()],
        whileElementsMounted: autoUpdate,
        placement: "right-end",
    });

    const click = useClick(context);
    const dismiss = useDismiss(context, {
        outsidePress(event: any) {
            if (event.target.matches(".tip-tap-content *")) return false;
            return true;
        },
    });
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

    const handleEmojiMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(!isOpen);
        setTimeout(() => editor.commands.focus(), 0);
    };

    const handleEmojiClick = (emojiData: EmojiClickData, event: any) => {
        editor.chain().focus().insertContent(emojiData.emoji).run();
    };

    return (
        <>
            <button onClick={handleEmojiMenuClick} ref={refs.setReference} className={`tool-icon`} title="Эмодзи">
                <SentimentSatisfiedAltIcon fontSize={iconSize} />
            </button>
            {isOpen && (
                <FloatingFocusManager context={context} modal={false} closeOnFocusOut={false}>
                    <div className="z-20" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                        <EmojiPicker
                            theme={theme}
                            skinTonesDisabled={true}
                            searchPlaceHolder="Поиск"
                            previewConfig={{ showPreview: false }}
                            categories={emojiCategories as any}
                            searchDisabled
                            emojiStyle={EmojiStyle.NATIVE}
                            emojiVersion={"5.0"}
                            onEmojiClick={handleEmojiClick}
                            className="!w-[300px] !h-[230px] xl:!w-[400px] xl:!h-[270px]"
                        />
                    </div>
                </FloatingFocusManager>
            )}
        </>
    );
});

export default TipTapEditor;
