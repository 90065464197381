import MainLayout from "../../../../layouts/MainLayout";
import ProfileCard from "../ProfileCard";
import { ProfileContext, ProfileContextInterface } from "../../context";
import { ReactNode, useContext } from "react";
import { observer } from "mobx-react-lite";
import LoadingText from "../../../../components/LoadingText";
import ContactInfo from "./ContactInfo";
import NotFound from "../../../errors/404";

interface BaseProfileInterface {
    children?: ReactNode;
}

const BaseProfile = ({ children }: BaseProfileInterface) => {
    const { store } = useContext(ProfileContext) as ProfileContextInterface;

    if (store.notFound) {
        return <NotFound />;
    }
    if (store.profile) {
        return (
            <MainLayout>
                <div className="avx-page-h mb-[21px]">Профиль</div>
                <div className="grid grid-cols-1 gap-5 xl:gap-[42px] lg:grid-cols-12">
                    <div className="col-span-1 lg:col-span-5">
                        <ProfileCard />
                    </div>
                    <div className="col-span-1 lg:col-span-7">
                        <ContactInfo />
                        {children}
                    </div>
                </div>
            </MainLayout>
        );
    } else {
        return (
            <MainLayout>
                <div className="flex justify-center">
                    <LoadingText />
                </div>
            </MainLayout>
        );
    }
};

export default observer(BaseProfile);
