export const emojiCategories = [
    {
        category: "suggested", 
        name: "Часто используемые"
    },
    {
        category: "smileys_people", 
        name: "Смайлики и люди"
    },
    {
        category: "animals_nature", 
        name: "Животные и природа"
    },
    {
        category: "food_drink", 
        name: "Еда и напитки"
    },
    {
        category: "travel_places", 
        name: "Места и путешествия"
    },
    {
        category: "activities", 
        name: "Активность"
    },
    {
        category: "objects", 
        name: "Предметы"
    },
    {
        category: "symbols", 
        name: "Символы"
    },
    {
        category: "flags", 
        name: "Флаги"
    },
]