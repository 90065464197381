import { motion, AnimatePresence } from "framer-motion";
import ViewNewsStore from "../../store";
import { ClickAwayListener } from "@mui/material";
import { observer } from "mobx-react-lite";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BaseSyntheticEvent, useEffect, useRef } from "react";
import { getFileFormat } from "../../../common/utils";

interface ImagesModalInterface {
    store: ViewNewsStore;
}

interface MediaItemInterface {
    file: string;
}

interface ChangeElementArrowInterface {
    store: ViewNewsStore;
    direction: "prev" | "next";
}

const ImagesModal = ({ store }: ImagesModalInterface) => {
    const variants = {
        enter: (direction: number) => {
            return {
                opacity: 0,
                x: direction > 0 ? "100%" : "-100%",
            };
        },
        center: {
            opacity: 1,
            x: 0,
        },
        exit: (direction: number) => {
            return {
                x: direction > 0 ? "-100%" : "100%",
                opacity: 0,
            };
        },
    };

    useEffect(() => {
        function hideError(e: any) {
            if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        }

        const handleArrowDown = (e: any) => {
            if (store.modalOpen) {
                if (e.key === "ArrowRight") {
                    store.changeSlide(1);
                } else if (e.key === "ArrowLeft") {
                    store.changeSlide(-1);
                } else if (e.key === "Escape") {
                    store.setModalOpen(false);
                }
            }
        };

        window.addEventListener("keydown", handleArrowDown);

        window.addEventListener("error", hideError);
        return () => {
            window.removeEventListener("error", hideError);
            window.removeEventListener("keydown", handleArrowDown);
        };
    }, []);

    return (
        <AnimatePresence>
            {store.modalOpen && (
                <motion.div
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed h-screen w-screen z-50 bg-black/70 left-0 top-0 flex justify-center items-center overflow-hidden"
                >
                    <div className="absolute w-full flex justify-between px-24">
                        <ChangeElementArrow store={store} direction="prev" />
                        <ChangeElementArrow store={store} direction="next" />
                    </div>
                    <div className="w-screen relative flex justify-center items-center">
                        <AnimatePresence initial={false} custom={store.direction}>
                            <motion.div
                                className="w-screen absolute flex justify-center items-center"
                                key={store.imageList[store.currImageIdx].image}
                                variants={variants}
                                exit="exit"
                                initial="enter"
                                animate="center"
                                transition={{ ease: "easeOut" }}
                                custom={store.direction}
                            >
                                <ClickAwayListener onClickAway={() => store.setModalOpen(false)}>
                                    {store.imageList[store.currImageIdx].loaded ? (
                                        <div className="">
                                            <MediaItem file={store.imageList[store.currImageIdx].image} />
                                        </div>
                                    ) : (
                                        <div className="w-[800px] h-[600px] bg-slate-200 cursor-wait rounded-lg flex items-center justify-center"></div>
                                    )}
                                </ClickAwayListener>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const ChangeElementArrow = ({ store, direction }: ChangeElementArrowInterface) => {
    const handleSlideChange = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        if (direction === "next") {
            store.changeSlide(1);
        } else if (direction === "prev") {
            store.changeSlide(-1);
        }
    };

    return (
        <motion.div
            className={`text-white bg-gray-400 dark:dark-surface-400 p-3 rounded-full z-20 cursor-pointer outline-none`}
            onClick={(e) => handleSlideChange(e)}
            title={direction === "prev" ? "Назад" : "Вперёд"}
            whileTap={{ scale: 0.8 }}
        >
            {direction === "prev" ? <ArrowBackIosNewIcon fontSize="large" /> : <ArrowForwardIosIcon fontSize="large" />}
        </motion.div>
    );
};

const MediaItem = ({ file }: MediaItemInterface) => {
    const parsedFile = getFileFormat(file);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideoActions = (e: any) => {
        if (videoRef.current) {
            if (e.code === "Space") {
                e.preventDefault();
                if (videoRef.current.paused) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleVideoActions);
        return () => {
            window.removeEventListener("keydown", handleVideoActions);
        };
    }, []);

    if (parsedFile.format === "image") {
        return <img src={file} className="max-h-[900px] max-w-[80vw]" />;
    }
    return <video ref={videoRef} className="max-h-[900px] max-w-[80vw]" src={file} autoPlay loop controls />;
};

export default observer(ImagesModal);
