import { useState, useEffect } from "react";

export default function useImageLoad(src: string) {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const imageLoader = new Image();
        imageLoader.src = src;
        imageLoader.onload = () => {
            setTimeout(() => {
                setLoaded(true);
            }, 0);
        };
        imageLoader.onerror = () => {
            setError(true);
        };
    }, []);

    return [loaded, error, setLoaded, setError];
}
