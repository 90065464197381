const Logo = () => {
    return (
        <div>
            <svg className="fill-[#C03A3A] dark:fill-[#7A5AF5]" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.9453 17.9964C35.0754 17.9964 36 17.0705 35.8973 15.9388C35.5891 13.0583 34.5617 10.3834 33.0206 8.01724C31.0686 5.03379 28.192 2.77047 24.9044 1.43306C21.6168 -0.00722606 18.021 -0.315859 14.5279 0.301407C11.0348 1.02155 7.84998 2.6676 5.28155 5.23954C2.71312 7.81149 1.06932 11.0007 0.35016 14.4985C-0.369001 17.9964 0.0419479 21.5971 1.37753 24.8892C2.71312 28.1813 5.07608 30.959 7.95272 33.0165C10.3157 34.6626 13.0896 35.5885 15.8635 35.8971C16.9936 36 17.9182 35.0741 17.9182 33.9424V29.4158C17.9182 28.2842 18.8429 27.3583 19.973 27.3583H25.2126C26.3427 27.3583 27.2673 28.2842 27.2673 29.4158V33.9424C27.2673 35.0741 28.192 36 29.3221 36H33.9453C35.0754 36 36 35.0741 36 33.9424V29.4158C36 28.2842 35.0754 27.3583 33.9453 27.3583H29.4248C28.2947 27.3583 27.3701 26.4324 27.3701 25.3007V20.0539C27.3701 18.9223 28.2947 17.9964 29.4248 17.9964H33.9453ZM12.8841 25.7122C13.8087 26.3295 14.8361 26.741 15.8635 27.0496C16.9936 27.3583 17.9182 26.3295 17.9182 25.1978V19.9511C17.9182 18.8194 18.8429 17.8935 19.973 17.8935H25.2126C26.3427 17.8935 27.2673 16.9676 27.0619 15.836C26.8564 14.8072 26.3427 13.7784 25.7263 12.8525C24.6989 11.3093 23.2606 10.1777 21.6168 9.45753C19.973 8.73739 18.021 8.53163 16.2744 8.94314C14.4252 9.25177 12.7814 10.1777 11.4458 11.4122C10.2129 12.7496 9.28831 14.3957 8.98009 16.1446C8.67188 17.8935 8.77462 19.8482 9.49378 21.4942C10.1102 23.2432 11.3431 24.6834 12.8841 25.7122Z"
                />
            </svg>
        </div>
    );
};

export default Logo;
