import ViewNewsStore from "../../store";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import DesktopMediaElement from "./DesktopMediaElement";

interface DesktopImagesCarouselInterface {
    store: ViewNewsStore;
}

const DesktopImagesCarousel = ({ store }: DesktopImagesCarouselInterface) => {
    const imageBlockWidth = store.cardWidth;
    const imageBlockHeight = 380;

    if (!store.news) {
        return null;
    }
    return (
        <motion.div
            className="relative overflow-hidden rounded-[13px] border-[1px] dark:border-[#717171]"
            style={{
                width: imageBlockWidth,
                height: imageBlockHeight,
            }}
            onHoverStart={() => store.setCarouselHover(true)}
            onHoverEnd={() => store.setCarouselHover(false)}
        >
            <motion.div
                className="absolute flex"
                animate={{ x: -store.carouselIdx * store.cardWidth }}
                transition={{ ease: "easeOut" }}
            >
                {store.imageList.map((el) => (
                    <div
                        key={el.image}
                        className={`overflow-hidden flex justify-center relative hover:cursor-pointer ${
                            !el.loaded && "animate-pulse bg-slate-200 cursor-wait"
                        }`}
                        style={{
                            width: imageBlockWidth,
                            height: imageBlockHeight,
                        }}
                        onClick={() => {
                            store.setCurrImageIdx(store.carouselIdx);
                            store.setModalOpen(true);
                        }}
                    >
                        <DesktopMediaElement el={el} />
                    </div>
                ))}
            </motion.div>
            {store.imageList.length > 1 && (
                <motion.div
                    className="absolute h-full w-[65px] bg-black/50 right-0 z-30 flex justify-center items-center hover:cursor-pointer"
                    animate={store.carouselHover ? { opacity: 1 } : { opacity: 0 }}
                    initial={false}
                    onClick={() => {
                        if (store.carouselIdx + 1 !== store.imageList.length) {
                            store.setCarouselIdx(store.carouselIdx + 1);
                        } else {
                            store.setCarouselIdx(0);
                        }
                    }}
                >
                    <div className="bg-white dark:dark-surface-300 rounded-full flex justify-center items-center w-8 h-8">
                        <ArrowForwardIosIcon fontSize="small" />
                    </div>
                </motion.div>
            )}
            {store.imageList.length > 1 && (
                <motion.div
                    className="absolute h-full w-[65px] bg-black/50 left-0 z-30 flex justify-center items-center hover:cursor-pointer"
                    animate={store.carouselHover ? { opacity: 1 } : { opacity: 0 }}
                    initial={false}
                    onClick={() => {
                        if (store.carouselIdx !== 0) {
                            store.setCarouselIdx(store.carouselIdx - 1);
                        } else {
                            store.setCarouselIdx(store.imageList.length - 1);
                        }
                    }}
                >
                    <div className="bg-white dark:dark-surface-300 rounded-full flex justify-center items-center w-8 h-8">
                        <ArrowBackIosNewIcon fontSize="small" />
                    </div>
                </motion.div>
            )}
        </motion.div>
    );
};

export default observer(DesktopImagesCarousel);
