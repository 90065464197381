import Search from "../../../../components/inputs/Search";
import HeaderStore from "../store";
import { BaseSyntheticEvent } from "react";
import { observer } from "mobx-react-lite";
import { ClickAwayListener } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import RestoreIcon from "@mui/icons-material/Restore";
import CloseIcon from "@mui/icons-material/Close";

interface CorpSearchInterface {
    store: HeaderStore;
}

interface CategoryBubbleInterface {
    title: string;
    store: HeaderStore;
    field: string;
}

interface SearchItemInterface {
    store: HeaderStore;
    el: any;
}

const CorpSearch = observer(({ store }: CorpSearchInterface) => {
    const handleSearch = (e: BaseSyntheticEvent) => {
        store.setLoading(true);
        store.setSearch(e.target.value.trimStart());
    };

    const handleFocus = () => {
        store.setSearchOpen(true);
    };

    const handleBlur = () => {
        store.setSearchOpen(false);
    };

    const AllCategoriesActive = () => {
        if (!store.showNews && !store.showUsers) return true;
        return false;
    };

    const handleClear = (e: BaseSyntheticEvent) => {
        store.setSearch("");
    };

    return (
        <ClickAwayListener onClickAway={handleBlur}>
            <div className="w-[550px] relative h-[64px] flex items-center justify-center px-3">
                <div className="basis-full z-30">
                    <Search
                        placeholder="Поиск по порталу"
                        value={store.search}
                        onChange={handleSearch}
                        color={store.searchOpen ? "white" : "gray"}
                        onFocus={handleFocus}
                        onClear={handleClear}
                    />
                </div>
                <AnimatePresence initial={false}>
                    {store.searchOpen && (
                        <motion.div
                            key={"content"}
                            initial={{ height: 0, opacity: 0, borderRadius: "0px 0px 0px 0px" }}
                            animate={{ height: "auto", opacity: 1, borderRadius: "0px 0px 28px 28px" }}
                            exit={{ height: 0, opacity: 0, borderRadius: "0px 0px 0px 0px" }}
                            transition={{ duration: 0.3 }}
                            className="z-20 absolute w-full top-0 left-0 bg-[#1C1A1A]/[0.73] backdrop-blur-[19px] px-2"
                        >
                            <div className="h-[64px] w-full" />
                            <div className="pb-3 pt-1 text-white ">
                                {!!store.recentSearch.length && (
                                    <div>
                                        <RecentSearch store={store} />
                                    </div>
                                )}
                                {!store.search ? (
                                    <div className="flex items-center justify-center avx-p">Введите запрос</div>
                                ) : (
                                    <div>
                                        <div className="text-[#8DB9FF] text-lg font-bold mb-2 px-1">Результаты</div>
                                        <div className="flex gap-2 flex-wrap mb-2 px-1">
                                            <CategoryBubble title="Пользователи" store={store} field="user" />
                                            <CategoryBubble title="Новости" store={store} field="news" />
                                        </div>
                                        {!!store.searchResults.length ? (
                                            <div className="flex flex-col">
                                                {store.searchResults
                                                    .filter((el: any) => {
                                                        if (
                                                            (el.type === "user" &&
                                                                (store.showUsers || AllCategoriesActive())) ||
                                                            (el.type === "news" &&
                                                                (store.showNews || AllCategoriesActive()))
                                                        ) {
                                                            return true;
                                                        }
                                                        return false;
                                                    })
                                                    .slice(0, 10)
                                                    .map((el: any, idx: number) => (
                                                        <SearchItem key={idx} store={store} el={el} />
                                                    ))}
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center avx-p">
                                                {!store.loading && <span>Результатов нет</span>}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </ClickAwayListener>
    );
});

const SearchItem = observer(({ store, el }: SearchItemInterface) => {
    const handleRedirect = (type: "user" | "news", id: number) => {
        store.saveNewCookie();
        if (type === "user") {
            window.location.href = `/users/profile/${id}`;
        } else {
            window.location.href = `/news/view/${id}`;
        }
    };

    if (el.type === "user") {
        return (
            <motion.div
                onClick={() => handleRedirect("user", el.id)}
                className="cursor-pointer flex items-center gap-2 p-1 rounded-md"
                whileHover={{ backgroundColor: "#444242" }}
            >
                <PersonIcon fontSize="small" />
                <div>
                    {el.last_name} {el.first_name}
                </div>
            </motion.div>
        );
    } else if (el.type === "news") {
        return (
            <motion.div
                onClick={() => handleRedirect("news", el.id)}
                className="cursor-pointer flex items-center gap-2 p-1 rounded-md"
                whileHover={{ backgroundColor: "#444242" }}
                key={el.id}
            >
                <ArticleIcon fontSize="small" />
                <div>{el.title}</div>
            </motion.div>
        );
    }
    return null;
});

const CategoryBubble = observer(({ title, store, field }: CategoryBubbleInterface) => {
    const checkActive = () => {
        if ((field === "user" && store.showUsers) || (field === "news" && store.showNews)) {
            return true;
        }
        return false;
    };

    const handleClick = () => {
        if (field === "user") {
            store.setShowUsers(!store.showUsers);
        } else {
            store.setShowNews(!store.showNews);
        }
    };

    return (
        <motion.div
            initial={false}
            animate={{ backgroundColor: checkActive() ? "#8DB9FF" : "#444242" }}
            className="cursor-pointer rounded-xl py-1 px-2"
            onClick={handleClick}
        >
            {title}
        </motion.div>
    );
});

const RecentSearch = observer(({ store }: { store: HeaderStore }) => {
    let filteredRecent: any = store.recentSearch;
    if (store.search) {
        filteredRecent = filteredRecent.filter((el: any) => {
            if (el[0].startsWith(store.search.toLowerCase())) {
                return true;
            }
            return false;
        });
    }

    const handleOneCookieRemove = (e: BaseSyntheticEvent, search: string) => {
        store.removeCookieFromList(search);
    };

    if (!!!filteredRecent.length) return null;

    return (
        <div className="flex flex-col mb-2">
            <div className="flex items-center justify-between px-1 mb-1">
                <div className="text-[#8DB9FF] text-lg font-bold">История</div>
                <button
                    className="text-sm rounded-xl py-1 px-2 bg-[#444242]"
                    onClick={() => store.removeAllRecentCookies()}
                >
                    Очистить
                </button>
            </div>
            {filteredRecent
                .slice(-5)
                .reverse()
                .map((el: any, idx: number) => (
                    <motion.div
                        key={idx}
                        className="cursor-pointer flex items-center justify-between p-1 rounded-md"
                        whileHover={{ backgroundColor: "#444242" }}
                        onClick={() => store.setSearch(el[0])}
                    >
                        <div className="flex items-center gap-2">
                            <RestoreIcon fontSize="small" />
                            <div>{el[0]}</div>
                        </div>
                        <motion.div
                            className="flex items-center justify-center"
                            whileHover={{ color: "#CECCCC" }}
                            title="Удалить"
                            onClick={(e: BaseSyntheticEvent) => {
                                e.stopPropagation();
                                handleOneCookieRemove(e, el[0]);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </motion.div>
                    </motion.div>
                ))}
        </div>
    );
});

export default CorpSearch;
