import { IconButton, Button } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../../../../context";
import { observer } from "mobx-react-lite";
import { MyProfileStore } from "../../../../../stores";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import TelegramIcon from "@mui/icons-material/Telegram";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { BaseSyntheticEvent } from "react";
import { useSubscribe } from "../../../../../../../hooks/useSubscribe";
import api from "../../../../../../../http";
import { enqueueSnackbar } from "notistack";
import UserService from "../../../../../../../services/UserService";

interface InnerSettingsInterface {
    tab: "main" | "privacy" | "linked" | "";
    setOpen: Function;
}

interface RoundedCheckboxInterface {
    checked: boolean;
    onChange?: Function;
    name: string;
    id: string;
}

const RoundedCheckbox = ({ checked, onChange, name, id }: RoundedCheckboxInterface) => {
    const size = 24;
    return (
        <label className={`block relative select-none cursor-pointer`} style={{ minWidth: size }}>
            <input
                className="h-0 w-0 "
                checked={checked}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                name={name}
                type="checkbox"
                id={id}
            />
            <div
                className={`absolute top-0 left-0 rounded-full border-solid border-[1px] border-[#ab2121] dark:border-[#9171F8] flex items-center justify-center text-white ${
                    checked ? "bg-[#ab2121] dark:dark-primary-400" : "bg-[#ffffff] dark:dark-surface-400"
                }`}
                style={{ height: size, width: size }}
            >
                {checked && <CheckIcon fontSize="small" />}
            </div>
        </label>
    );
};

const CloseModalIcon = ({ setOpen }: { setOpen: Function }) => {
    return (
        <div className="hidden xl:block">
            <IconButton onClick={() => setOpen(false)} title="Закрыть">
                <CloseIcon />
            </IconButton>
        </div>
    );
};

const InnerSettings = ({ tab, setOpen }: InnerSettingsInterface) => {
    const { store } = useContext(ProfileContext) as { store: MyProfileStore };
    const buttonProps = {
        padding: {
            xs: 0.5,
            xl: 1,
        },
        fontSize: {
            xs: 13,
            xl: 15,
        },
    };
    const privacyList = [
        {
            param: store.newPrivacy.show_birthdate,
            label: "Год рождения",
            id: "show_birthdate",
            icon: <CalendarMonthOutlinedIcon />,
        },
        {
            param: store.newPrivacy.show_phone,
            label: "Номер телефона",
            id: "show_phone",
            icon: <LocalPhoneOutlinedIcon />,
        },
        {
            param: store.newPrivacy.show_tg_username,
            label: "Имя в telegram",
            id: "show_tg_username",
            icon: <TelegramIcon />,
        },
    ];

    const handlePrivacyChange = (e: BaseSyntheticEvent) => {
        if (e.target.name === "show_birthdate") {
            store.setNewPrivacy({ ...store.newPrivacy, show_birthdate: e.target.checked });
        } else if (e.target.name === "show_phone") {
            store.setNewPrivacy({ ...store.newPrivacy, show_phone: e.target.checked });
        } else {
            store.setNewPrivacy({ ...store.newPrivacy, show_tg_username: e.target.checked });
        }
    };

    let title;
    if (tab === "main" || tab === "") {
        title = "Основное";
        return (
            <SettingsWrapper title={title} setOpen={setOpen}>
                <div className="flex flex-col justify-between h-full">
                    <div className="grid grid-cols-1 gap-2 xl:gap-y-5 xl:grid-cols-12">
                        <div className="col-span-3">
                            <div className="font-semibold xl:text-lg">О себе</div>
                        </div>
                        <div className="col-span-9 text-sm xl:text-base">
                            <textarea
                                rows={5}
                                className="w-full outline-none rounded-lg py-1 px-2 bg-[#F4F4F6] dark:dark-surface-300"
                                value={store.newDescription}
                                onChange={(e) => store.setNewDescription(e.target.value)}
                                placeholder="Расскажите о себе"
                            />
                        </div>
                        <div className="col-span-3">
                            <div className="font-semibold xl:text-lg">Интересы</div>
                        </div>
                        <div className="col-span-9 text-xs xl:text-sm">
                            <div className="p-2 gap-2 rounded-lg flex flex-wrap bg-[#F4F4F6] dark:dark-surface-300 max-h-[120px] overflow-y-auto xl:p-3 xl:gap-3">
                                {store.serverTags.map((el) => (
                                    <motion.div
                                        key={el.id}
                                        style={{ backgroundColor: `#${el.color}` }}
                                        className="rounded-xl px-2 py-1 hover:cursor-pointer dark:!bg-slate-500"
                                        initial={false}
                                        animate={{ opacity: store.selectedTags.includes(el.id) ? 1 : 0.5 }}
                                        onClick={() => store.toggleTag(el.id)}
                                        transition={{ duration: 0.3 }}
                                    >
                                        #{el.name.toUpperCase()}
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 items-end">
                        <hr className="hidden w-full xl:block settings-hr" />
                        <Button
                            variant="contained"
                            color="signature"
                            disabled={store.loadingEdit}
                            onClick={async () => {
                                store.editProfile();
                                setOpen(false);
                            }}
                            sx={buttonProps}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>
            </SettingsWrapper>
        );
    } else if (tab === "linked") {
        title = "Связанные аккаунты";
        return (
            <SettingsWrapper title={title} setOpen={setOpen}>
                <div className="">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2 xl:gap-[17px]">
                            <span className="avilex-color dark:dark-primary-text-400 bg-[#F4F4F6] dark:dark-surface-400 rounded-[10px] p-2">
                                <TelegramIcon />
                            </span>
                            <span className="font-medium text-sm xl:text-[15px]">Telegram</span>
                        </div>
                        <div>
                            {store.profile?.telegram_username ? (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    disabled={store.tgLinkDisabled}
                                    onClick={async () => {
                                        await store.cancelTgLink();
                                    }}
                                    sx={buttonProps}
                                >
                                    Отменить привязку
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="lightBlue"
                                    disabled={store.tgLinkDisabled}
                                    sx={buttonProps}
                                    onClick={async () => {
                                        await store.linkTgAccount();
                                    }}
                                >
                                    Привязать
                                </Button>
                            )}
                        </div>
                    </div>
                    {!store.profile?.telegram_username && (
                        <span className="italic text-sm text-gray-400">
                            * после привязки потребуется перезагрузка страницы
                        </span>
                    )}
                </div>
            </SettingsWrapper>
        );
    } else if (tab === "privacy") {
        title = "Приватность";
        return (
            <SettingsWrapper title={title} setOpen={setOpen}>
                <div className="flex flex-col justify-between h-full">
                    <div>
                        <p className="mb-4 text-sm xl:text-base">
                            Выбранная информация будет видна другим пользователям в вашем профиле
                        </p>
                        <div className="flex flex-col gap-6">
                            {privacyList.map((el, idx) => (
                                <div className="flex justify-between items-center" key={idx}>
                                    <div className="flex items-center gap-2 xl:gap-[17px]">
                                        <label
                                            htmlFor={el.id}
                                            className="avilex-color bg-[#F4F4F6] dark:dark-primary-text-400 dark:dark-surface-400 rounded-[10px] p-2 cursor-pointer"
                                        >
                                            {el.icon}
                                        </label>
                                        <label
                                            htmlFor={el.id}
                                            className="text-sm font-medium cursor-pointer xl:text-[15px]"
                                        >
                                            {el.label}
                                        </label>
                                    </div>
                                    <RoundedCheckbox
                                        checked={el.param}
                                        onChange={handlePrivacyChange}
                                        name={el.id}
                                        id={el.id}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 items-end">
                        <hr className="hidden w-full xl:block settings-hr" />
                        <Button
                            variant="contained"
                            color="signature"
                            disabled={store.loadingEdit}
                            onClick={async () => {
                                store.editPrivacy();
                                setOpen(false);
                            }}
                            sx={buttonProps}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>
            </SettingsWrapper>
        );
    } else {
        return (
            <SettingsWrapper title="Уведомления" setOpen={setOpen}>
                <NotificationSettings />
            </SettingsWrapper>
        );
    }
};

const NotificationSettings = () => {
    const [appNotificationsChecked, setAppNotificationsChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const awaitRegister = async () => {
            const registration = await navigator.serviceWorker.ready;
            const existingSubscription = await registration.pushManager.getSubscription();
            setAppNotificationsChecked(!!existingSubscription);
        };
        awaitRegister();
    }, []);

    const { getSubscription } = useSubscribe({
        publicKey: process.env.REACT_APP_VAPID_PUBLIC_KEY as string,
    });

    const onChangeSubscribe = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        const changeSub = async () => {
            try {
                const subscription = await getSubscription();
                await UserService.changeSubscription({
                    subscription: JSON.stringify(subscription),
                })
                    .then((resp) => {
                        setAppNotificationsChecked(resp.data.subscribed);
                        const asyncRes = async () => {
                            if (!resp.data.subscribed) {
                                subscription.unsubscribe();
                            }
                        };
                        asyncRes();
                    })
                    .catch((e) => {
                        console.error(e);
                        enqueueSnackbar("Ошибка изменения подписки");
                    });
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка изменения подписки");
            } finally {
                setLoading(false);
            }
        };
        changeSub();
    };

    const c = async (e: BaseSyntheticEvent) => {
        try {
            await api.get("/send/");
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            <div
                className={`flex justify-between items-center ${loading ? "opacity-75" : ""}`}
                onClick={(e) => {
                    if (!loading) onChangeSubscribe(e);
                }}
            >
                <label className="text-sm font-medium cursor-pointer xl:text-[15px]">В приложении</label>
                <RoundedCheckbox checked={appNotificationsChecked} id="app_notification" name="app_notification" />
            </div>
            <button onClick={c} className="mt-3 bg-slate-500 rounded-lg p-1">
                Тест
            </button>
        </div>
    );
};

interface SettingsWrapperInterface {
    title: string;
    children: ReactNode;
    setOpen: Function;
}

const SettingsWrapper = ({ title, setOpen, children }: SettingsWrapperInterface) => {
    return (
        <div className="settings-inner settings-base flex flex-col">
            <div>
                <div className="flex items-center justify-between">
                    <div className="settings-header">{title}</div>
                    <CloseModalIcon setOpen={setOpen} />
                </div>
                <hr className="horizontal-line settings-hr" />
            </div>
            <div className="basis-full pt-3 xl:pt-0">{children}</div>
        </div>
    );
};

export default observer(InnerSettings);
