import MainLayout from "../../../layouts/MainLayout";
import MyCalendar from "../../../components/MyCalendar";

const VacationPlanning = () => {
    return (
        <MainLayout>
            <div className="avx-page-h">Планирование отпуска</div>
            <div className="mt-3">
                <MyCalendar viewList={["year", "month"]} />
            </div>
        </MainLayout>
    );
};

export default VacationPlanning;
