import { useContext, BaseSyntheticEvent, useRef, useState } from "react";
import Search from "../../../../components/inputs/Search";
import NewsContext from "../context";
import { observer } from "mobx-react-lite";
import { TagInterface } from "../interfaces";
import { motion } from "framer-motion";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import AuthContext from "../../../../context/AuthContext";

const UpperBlock = () => {
    const scrollStep = 200;
    const { domainStore } = useContext(AuthContext);
    const context = useContext(NewsContext);
    const tagsBlockMaxWidth = context.tagsBlockMaxWidth;
    const searchRef = useRef<HTMLDivElement>(null);
    const tagsBlockRef = useRef<HTMLDivElement>(null);
    const [currentOffset, setCurrentOffset] = useState<number>(0);

    const variants = {
        simple: {
            color: domainStore.theme === "dark" ? "#ffffff" : "#000000",
            backgroundColor: domainStore.theme === "dark" ? "#717171" : "#E3EBF8",
        },
        selected: {
            color: "#ffffff",
            backgroundColor: domainStore.theme === "dark" ? "#A688FA" : "#393939",
        },
    };

    const scrollTags = (action: "back" | "forward") => {
        if (tagsBlockRef.current) {
            const maxOffset = tagsBlockRef.current.scrollWidth - tagsBlockMaxWidth;
            if (action === "back") {
                if (currentOffset + scrollStep > 0) {
                    setCurrentOffset(0);
                } else {
                    setCurrentOffset(currentOffset + scrollStep);
                }
            } else {
                const newOffset: number =
                    tagsBlockRef.current.scrollWidth - tagsBlockMaxWidth - Math.abs(currentOffset) - scrollStep;
                if (newOffset >= 0) {
                    setCurrentOffset(currentOffset - scrollStep);
                } else {
                    setCurrentOffset(currentOffset - (scrollStep + newOffset));
                }
            }
        }
    };

    return (
        <div className="flex gap-3 mb-4 flex-col xl:justify-between xl:items-center xl:mb-[32px] xl:flex-row">
            <div className="avx-page-h text-nowrap">Новости</div>
            {context.store.loadingTags ? (
                <div
                    className="bg-slate-200 cursor-wait animate-pulse rounded-[14px] hidden xl:block"
                    style={{ height: searchRef.current?.clientHeight, width: tagsBlockMaxWidth }}
                />
            ) : (
                <div className="items-center hidden xl:flex">
                    {tagsBlockRef.current && tagsBlockRef.current.scrollWidth > tagsBlockMaxWidth && (
                        <div className="rotate-180 mr-3" onClick={() => scrollTags("back")} title="Назад">
                            <IconButton>
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                    <div
                        className="relative overflow-x-hidden"
                        style={{
                            width: tagsBlockMaxWidth,
                            height: searchRef.current?.clientHeight,
                        }}
                    >
                        <motion.div
                            className="flex gap-3 absolute top-0 left-0 xl:gap-[34px]"
                            style={{ maxWidth: tagsBlockMaxWidth }}
                            ref={tagsBlockRef}
                            animate={{ x: currentOffset }}
                            transition={{ ease: "easeOut" }}
                        >
                            {context.store.tags.map((el: TagInterface) => {
                                return (
                                    <motion.div
                                        key={el.id}
                                        variants={variants}
                                        animate={context.store.tagsFilter.includes(el.id) ? "selected" : "simple"}
                                        initial="simple"
                                        className="text-nowrap px-3 text-[17px] font-semibold flex justify-center items-center rounded-[14px] hover:cursor-pointer "
                                        style={{
                                            height: searchRef.current?.clientHeight,
                                        }}
                                        onClick={() => context.store.swapTagsFilter(el.id)}
                                    >
                                        #{el.name.charAt(0).toUpperCase() + el.name.slice(1)}
                                    </motion.div>
                                );
                            })}
                        </motion.div>
                    </div>
                    {tagsBlockRef.current && tagsBlockRef.current.scrollWidth > tagsBlockMaxWidth && (
                        <div className="ml-3" onClick={() => scrollTags("forward")} title="Далее">
                            <IconButton>
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </div>
            )}

            <div ref={searchRef}>
                <Search
                    value={context.store.search}
                    onChange={(e: BaseSyntheticEvent) => context.store.setSearch(e.target.value)}
                    placeholder="Поиск новостей"
                />
            </div>
        </div>
    );
};

export default observer(UpperBlock);
