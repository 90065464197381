import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useState, MouseEvent } from "react";
import MailIcon from "@mui/icons-material/Mail";
import TelegramIcon from "@mui/icons-material/Telegram";

interface UserSocialLinksInterface {
    mail?: string;
    tg?: string;
}

const UserSocialLinks = ({ mail, tg }: UserSocialLinksInterface) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="basis-[13%] flex items-center justify-center xl:hidden">
            <div className="text-gray-500 dark:dark-text-primary" onClick={handleClick}>
                <MoreVertIcon />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <div className="py-3 px-5 flex gap-6">
                    <div
                        className={` ${mail ? "text-[#00B0E7] hover:cursor-pointer" : "text-[#E9E9E9]"}`}
                        onClick={() => {
                            if (mail) {
                                window.location.href = `mailto:${mail}`;
                            }
                        }}
                    >   
                        <MailIcon className="!text-[32px]"/>
                    </div>
                    <div
                        className={` ${tg ? "text-[#00B0E7] hover:cursor-pointer" : "text-[#E9E9E9]"}`}
                        onClick={() => {
                            if (tg) {
                                window.open(`https://t.me/${tg}`);
                            }
                        }}
                    >
                        <TelegramIcon className="!text-[32px]"/>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default UserSocialLinks;
