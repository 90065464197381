import { BaseSyntheticEvent, useRef, useState, useMemo, useContext, ReactNode } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import { motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentsBlock from "../AttachmentsBlock";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NotFound from "../../../errors/404";
import LoadingText from "../../../../components/LoadingText";
import AuthContext from "../../../../context/AuthContext";
import Button from "../../../../components/inputs/Button";
import ButtonsBlock from "./ButtonsBlock";
import { observer } from "mobx-react-lite";
import Preview from "./Preview";
import { Editor } from "@tiptap/react";

interface SafeUserInterface {
    id: number;
    last_name: string;
    first_name: string;
}

interface NewsStatusInterface {
    name: string;
    code_name: string;
}

interface NewsItemInterface {
    author?: SafeUserInterface;
    created_at?: string;
    status?: NewsStatusInterface;
    refusal_reason?: string;
}

interface TagsInterface {
    id: number;
    name: string;
    color: string;
}

interface SelectTagsInterface extends TagsInterface {
    selected: boolean;
}

interface NewsChangeBaseInterface {
    action: "create" | "edit";
    pageTitle: string;
    handleSubmit: Function;
    titleImage: any;
    setTitleImage: Function;
    title: string;
    setTitle: Function;
    formDisabled?: boolean;
    serverTags: Array<SelectTagsInterface>;
    setServerTags: Function;
    images: any;
    setImages: Function;
    notFound?: boolean;
    loading?: boolean;
    newsData?: NewsItemInterface;
    loadingButtonName: string;
    refusalReason?: string;
    setRefusalReason?: Function;
    editor: Editor;
    tipTapEditor: ReactNode;
    dialogOpen?: boolean;
    setDialogOpen?: Function;
    text: string;
}

const NewsChangeBase = ({
    action,
    pageTitle,
    handleSubmit,
    titleImage,
    setTitleImage,
    title,
    setTitle,
    formDisabled = false,
    serverTags,
    setServerTags,
    images,
    setImages,
    notFound = false,
    loading = false,
    newsData = {},
    loadingButtonName,
    refusalReason = "",
    setRefusalReason = () => {},
    editor,
    tipTapEditor,
    dialogOpen = false,
    setDialogOpen = () => {},
    text,
}: NewsChangeBaseInterface) => {
    const MAX_IMAGE_SIZE = Number(process.env.REACT_APP_MAX_IMAGE_SIZE);
    const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE / 1000;
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [tagsBlockState, setTagsBlockState] = useState("collapsed");
    const [imagesBlockState, setImagesBlockState] = useState("collapsed");
    const { domainStore } = useContext(AuthContext);
    const buttonClasses = "!py-2 !px-3";

    const accordionMenuVariants = {
        content: {
            collapsed: { height: "0px", overflow: "hidden" },
            expanded: { height: "auto" },
        },
        button: {
            collapsed: { backgroundColor: domainStore.theme === "dark" ? "#717171" : "#F3F8FF" },
            expanded: { backgroundColor: domainStore.theme === "dark" ? "#ba9ffb" : "#d4d8dd" },
        },
    };

    const selectedTagsCount = useMemo(() => {
        return serverTags.filter((el) => el.selected).length;
    }, [serverTags]);

    const toggleTagBlock = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        if (imagesBlockState === "expanded") {
            setImagesBlockState("collapsed");
        }
        if (tagsBlockState === "collapsed") {
            setTagsBlockState("expanded");
        } else {
            setTagsBlockState("collapsed");
        }
    };

    const toggleImagesBlock = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        if (tagsBlockState === "expanded") {
            setTagsBlockState("collapsed");
        }
        if (imagesBlockState === "collapsed") {
            setImagesBlockState("expanded");
        } else {
            setImagesBlockState("collapsed");
        }
    };

    const renderImageName = () => {
        if (imageInputRef.current && imageInputRef.current.files) {
            if (imageInputRef?.current?.files[0]?.name) {
                return imageInputRef?.current?.files[0]?.name;
            } else if (titleImage) {
                return "Фото";
            }
        }
    };

    const toggleTag = (id: number) => {
        if (!formDisabled) {
            setServerTags(
                serverTags.map((el) => {
                    if (el.id === id) return { ...el, selected: !el.selected };
                    return { ...el };
                })
            );
        }
    };

    if (notFound) return <NotFound />;
    return (
        <MainLayout>
            <div className="avx-page-h">{pageTitle}</div>
            {loading ? (
                <div className="flex justify-center mt-5">
                    <LoadingText />
                </div>
            ) : (
                <div className="mt-3 xl:gap-[100px] xl:mt-[30px] xl:flex">
                    <div className="basis-1/2">
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            className="gap-10 p-2 rounded-xl bg-white dark:dark-surface-300 flex flex-col justify-between xl:p-[36px] xl:rounded-[28px] xl:min-h-[810px] xl:gap-[50px]"
                        >
                            <div>
                                {(action === "create" ||
                                    (!domainStore.userIsLoading && domainStore.isModerator) ||
                                    (!loading && newsData.status?.code_name === "draft")) && (
                                    <div className="text-sm flex flex-wrap gap-2 items-center mb-3 xl:gap-3 xl:flex-nowrap xl:text-base">
                                        <label>
                                            <span
                                                className="cursor-pointer bg-gray-400 hover:bg-gray-600 transition
                             duration-500 ease-out text-white p-1 rounded-md inline-block"
                                            >
                                                Обложка
                                            </span>
                                            <input
                                                ref={imageInputRef}
                                                type="file"
                                                accept="image/*"
                                                onChange={(e: React.BaseSyntheticEvent) => {
                                                    if (
                                                        e.target.files.length &&
                                                        e.target.files[0].size > MAX_IMAGE_SIZE * 10 ** 3
                                                    ) {
                                                        enqueueSnackbar(
                                                            `Размер вложения должен быть до ${MAX_IMAGE_SIZE_MB} Мб`
                                                        );
                                                        return;
                                                    }
                                                    setTitleImage(e.target.files[0]);
                                                }}
                                                className="border-2 rounded-lg p-1 hidden"
                                            />
                                        </label>
                                        {titleImage && (
                                            <div className="flex items-center">
                                                {renderImageName()}
                                                <button
                                                    title="Удалить"
                                                    className="text-red-600 ms-1"
                                                    onClick={() => {
                                                        setTitleImage(null);
                                                        if (imageInputRef.current) {
                                                            const dataTransfer = new DataTransfer();
                                                            imageInputRef.current.files = dataTransfer.files;
                                                        }
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </button>
                                            </div>
                                        )}
                                        <div className="italic text-sm">
                                            Максимальный размер изображения - {MAX_IMAGE_SIZE_MB} Мб
                                        </div>
                                    </div>
                                )}
                                <input
                                    autoFocus
                                    placeholder="Введите заголовок"
                                    required
                                    type="text"
                                    value={title}
                                    onChange={(e: React.BaseSyntheticEvent) => setTitle(e.target.value)}
                                    maxLength={70}
                                    className="text-2xl font-normal focus:outline-none border-2 w-full rounded-[10px] p-1 xl:text-[30px] dark:bg-transparent"
                                    disabled={formDisabled}
                                />
                                <div className="mt-5">{tipTapEditor as ReactNode}</div>
                            </div>
                            <div>
                                <motion.div
                                    variants={accordionMenuVariants.content}
                                    animate={tagsBlockState}
                                    initial={accordionMenuVariants.content.collapsed}
                                    className={`bg-[#F4F4F6] dark:dark-surface-400 ${
                                        tagsBlockState === "expanded" && "mb-2"
                                    }  rounded-md`}
                                >
                                    <div className="p-2 flex flex-wrap gap-3">
                                        {serverTags.map((el) => (
                                            <motion.div
                                                key={el.id}
                                                className={`p-1 rounded-lg dark:!bg-[#9171F8] font-normal ${
                                                    !formDisabled && "cursor-pointer"
                                                }`}
                                                style={{ backgroundColor: `#${el.color}` }}
                                                initial={{ opacity: el.selected ? 1 : 0.5 }}
                                                animate={{ opacity: el.selected ? 1 : 0.5 }}
                                                onClick={() => toggleTag(el.id)}
                                                transition={{ duration: 0.3 }}
                                            >
                                                #{el.name}
                                            </motion.div>
                                        ))}
                                    </div>
                                </motion.div>
                                <motion.div
                                    variants={accordionMenuVariants.content}
                                    animate={imagesBlockState}
                                    initial={accordionMenuVariants.content.collapsed}
                                    className={`bg-[#F4F4F6] dark:dark-surface-400 ${
                                        imagesBlockState === "expanded" && "mb-2"
                                    }  rounded-md`}
                                >
                                    <div className="p-2">
                                        {!formDisabled && (
                                            <div className="italic text-sm mb-2">
                                                Максимальный размер одного файла - {MAX_IMAGE_SIZE_MB} Мб
                                            </div>
                                        )}
                                        <AttachmentsBlock
                                            images={images}
                                            setImages={setImages}
                                            formDisabled={formDisabled}
                                        />
                                    </div>
                                </motion.div>
                                <div className="flex justify-between items-center flex-wrap gap-3">
                                    <div className="flex gap-2 items-center xl:text-lg">
                                        <motion.button
                                            className="p-1.5 rounded-[14px] flex items-center xl:p-2"
                                            onClick={toggleTagBlock}
                                            animate={tagsBlockState}
                                            variants={accordionMenuVariants.button}
                                            initial={accordionMenuVariants.button.collapsed}
                                            whileHover={{
                                                backgroundColor: accordionMenuVariants.button.expanded.backgroundColor,
                                            }}
                                        >
                                            <div className="font-normal">теги</div>
                                            {selectedTagsCount !== 0 && (
                                                <div className="font-normal pl-1 pr-1">({selectedTagsCount})</div>
                                            )}
                                        </motion.button>
                                        <motion.button
                                            title="Вложения"
                                            className="p-1.5 rounded-[14px] flex items-center xl:p-2"
                                            onClick={toggleImagesBlock}
                                            animate={imagesBlockState}
                                            variants={accordionMenuVariants.button}
                                            initial={accordionMenuVariants.button.collapsed}
                                            whileHover={{
                                                backgroundColor: accordionMenuVariants.button.expanded.backgroundColor,
                                            }}
                                        >
                                            <AttachFileIcon />
                                            {images.length !== 0 && (
                                                <div className="font-normal px-1">({images.length})</div>
                                            )}
                                        </motion.button>
                                    </div>
                                    {action === "create" ? (
                                        <div className="flex gap-2">
                                            {!domainStore.userIsLoading && (
                                                <>
                                                    <Button
                                                        text="Сохранить черновик"
                                                        color="secondary"
                                                        name="draft"
                                                        disabled={!!loadingButtonName}
                                                        loading={loadingButtonName === "draft"}
                                                        className={buttonClasses}
                                                    />
                                                    {domainStore.isModerator ? (
                                                        <Button
                                                            text="Опубликовать"
                                                            name="publish"
                                                            disabled={!!loadingButtonName}
                                                            loading={loadingButtonName === "publish"}
                                                            className={buttonClasses}
                                                        />
                                                    ) : (
                                                        <Button
                                                            text="Отправить на модерацию"
                                                            name="moderate"
                                                            disabled={!!loadingButtonName}
                                                            loading={loadingButtonName === "moderate"}
                                                            className={buttonClasses}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <ButtonsBlock
                                            status={newsData.status}
                                            loadingButtonName={loadingButtonName}
                                            dialogOpen={dialogOpen}
                                            setDialogOpen={setDialogOpen}
                                            handleClickOpen={handleSubmit}
                                            refusalReason={refusalReason}
                                            setRefusalReason={setRefusalReason}
                                            newsData={newsData}
                                            buttonClasses={buttonClasses}
                                        />
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="hidden xl:block basis-1/2">
                        <Preview
                            action={action}
                            title={title}
                            text={text}
                            titleImage={titleImage}
                            newsData={newsData}
                            editor={editor}
                        />
                    </div>
                </div>
            )}
        </MainLayout>
    );
};

export default observer(NewsChangeBase);
