import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Palette {
        signature: Palette["primary"];
        lightBlue: Palette["primary"];
    }

    interface PaletteOptions {
        signature?: PaletteOptions["primary"];
        lightBlue?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        signature: true;
        lightBlue: true;
    }
}

let theme = createTheme({
    colorSchemes: {
        light: {
            palette: {
                signature: {
                    main: "#ab2121",
                    light: "#ab2121",
                    dark: "#ab2121",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: grey[500],
                    contrastText: "#ffffff",
                },
            },
        },
        dark: {
            palette: {
                signature: {
                    main: "#7a5af5",
                    dark: "#7a5af5",
                    light: "#7a5af5",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: grey[500],
                    contrastText: "#ffffff",
                },
            },
        },
    },
});
theme = createTheme(theme, {
    palette: {
        lightBlue: theme.palette.augmentColor({
            color: {
                main: "#E3EBF8",
            },
            name: "lightBlue",
        }),
    },
});

export default theme;
