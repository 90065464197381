import { motion } from "framer-motion";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useNavigate } from "react-router-dom";

interface NewsInterface {
    liked: boolean;
    likes: number;
    id: number;
    comments: number;
}

interface FeedbackInterface {
    news: NewsInterface;
    store: any;
}

const Feedback = ({ news, store }: FeedbackInterface) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center gap-[12px]">
            <div className="flex gap-2 items-center justify-between bg-[#F5F5F5] dark:dark-surface-400 rounded-xl px-3 py-1 min-w-[70px]">
                <div className="text-[#BB2525] dark:dark-primary-text-400">
                    {news.liked ? (
                        <motion.button
                            animate={{ scale: [1, 1.2, 1] }}
                            initial={false}
                            onClick={() => store.swapLike(news.id)}
                        >
                            <FavoriteIcon sx={{fontSize: 25}} />
                        </motion.button>
                    ) : (
                        <motion.button
                            animate={{ scale: [1, 0.8, 1] }}
                            initial={false}
                            onClick={() => store.swapLike(news.id)}
                        >
                            <FavoriteBorderIcon sx={{fontSize: 25}} />
                        </motion.button>
                    )}
                </div>
                <div className="avx-p">{news.likes}</div>
            </div>
            <div className="flex gap-2 items-center justify-between bg-[#F5F5F5] dark:dark-surface-400 rounded-xl px-3 py-1 min-w-[70px]">
                <div className="text-[#BB2525] dark:dark-primary-text-400">
                    <button onClick={() => navigate(`/news/view/${news.id}?comments=`)}>
                        <ChatBubbleOutlineIcon sx={{fontSize: 25}} />
                    </button>
                </div>
                <div className="avx-p">{news.comments}</div>
            </div>
        </div>
    );
};

export default Feedback;
