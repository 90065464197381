import { useState, useRef } from "react";
import { UpperLevelCommentStore, LowerLevelCommentStore } from "../../store";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import MyEmojiPicker from "../../../../../components/inputs/MyEmojiPicker";

interface NewCommentInputInterface {
    store: UpperLevelCommentStore | LowerLevelCommentStore;
}

const NewCommentInput = ({ store }: NewCommentInputInterface) => {
    const inputRef = useRef(null);
    const [focused, setFocused] = useState(true);
    const variants = {
        simple: {
            borderColor: "#B9B9B9",
        },
        focused: {
            borderColor: "#000000",
        },
    };
    return (
        <div className="emoji-input">
            <motion.textarea
                autoFocus
                rows={3}
                className="w-full p-2 outline-none border-solid border-2 rounded-lg dark:dark-surface-400 dark:!border-[#717171]"
                value={store.newCommentText}
                onChange={(e) => store.setNewCommentText(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder="Комментарий"
                variants={variants}
                animate={focused ? "focused" : "simple"}
                initial={false}
                ref={inputRef}
            />
            <div className="w-full flex justify-between mt-2">
                <MyEmojiPicker inputRef={inputRef} textValue={store.newCommentText} setValue={store.setNewCommentText} />
                <div className="flex gap-3">
                    <motion.button
                        className="py-1 px-2 rounded-xl"
                        onClick={() => {
                            store.setNewCommentExpanded(false);
                            store.setNewCommentText("");
                        }}
                        whileHover={{ backgroundColor: "#B9B9B9", color: "#ffffff" }}
                    >
                        Отмена
                    </motion.button>
                    <motion.button
                        className="py-1 px-2 rounded-xl text-white bg-[#ab2121] dark:dark-primary-300"
                        onClick={() => {
                            if (store.newCommentText.trim()) store.sendComment(store.comment.id);
                        }}
                        // whileHover={{ backgroundColor: "#9c1d1d" }}
                    >
                        Отправить
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default observer(NewCommentInput);
