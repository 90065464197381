import MainLayout from "../../../layouts/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import ViewNewsStore, { CommentsStore } from "./store";
import { useState, useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Edit from "../../../icons/Edit";
import dayjs from "dayjs";
import Feedback from "../components/Feedback";
import CommentsBlock from "./components/CommentsBlock";
import AuthContext from "../../../context/AuthContext";
import LoadingText from "../../../components/LoadingText";
import NavLink from "../../../components/general/NavLink";
import NotFound from "../../errors/404";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import MobileDraggableCarousel from "./components/images/MobileDraggableCarousel";
import DOMPurify from "dompurify";
import DesktopImagesBlock from "./components/images/DesktopImagesBlock";

const ViewNews = () => {
    const params = useParams();

    const navigate = useNavigate();
    const [store] = useState(() => new ViewNewsStore());
    const [commentsStore] = useState(() => new CommentsStore(Number(params.id), store));
    const { domainStore } = useContext(AuthContext);
    const commentsRef = useRef<HTMLDivElement>(null);
    const searchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const fetchNews = async () => {
            await store.fetchNews(Number(params.id)).then(async () => {
                if (!store.notFound) {
                    await commentsStore.fetchMainComments();
                }
            });
        };
        fetchNews();
    }, []);

    useEffect(() => {
        if (commentsRef.current && searchParams.has("comments")) {
            commentsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [store.loading]);

    if (store.notFound) {
        return <NotFound />;
    }
    return (
        <MainLayout>
            {store.news ? (
                <div>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<NavigateNextIcon className="text-[#ab2121] dark:dark-primary-text-500" fontSize="small" sx={{ fontSize: 25 }} />}
                    >
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/news/all"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/news/all");
                            }}
                        >
                            Все новости
                        </Link>
                        <div className="avilex-color dark:dark-primary-text-500">{store.news.title}</div>
                    </Breadcrumbs>
                    <div className="mt-3 p-3 gap-5 rounded-xl bg-white dark:dark-surface-300 min-h-[800px] flex flex-col justify-between xl:gap-[30px] xl:mt-[32px] xl:p-[41px] xl:rounded-[40px]">
                        <div>
                            <div className="flex justify-between align-top">
                                <div className="text-2xl font-bold xl:text-[30px]">{store.news.title}</div>
                                {domainStore.isModerator && (
                                    <a
                                        className="hover:cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (store.news) navigate(`/news/edit/${store.news.id}`);
                                        }}
                                        title="Редактирование"
                                        href={`/news/edit/${store.news.id}`}
                                    >
                                        <div className="hidden xl:block">
                                            <Edit />
                                        </div>
                                        <div className="xl:hidden">
                                            <CreateOutlinedIcon />
                                        </div>
                                    </a>
                                )}
                            </div>
                            <div className="mt-2 text-[15px] text-[#7C90B6] xl:text-[17px] xl:mt-[16px]">
                                {dayjs(store.news.published_at).format("DD.MM.YYYY, LT")},{" "}
                                <NavLink href={`/users/profile/${store.news.author.id}`}>
                                    {store.news.author.last_name} {store.news.author.first_name}
                                </NavLink>
                            </div>
                            {store.news.tags.length !== 0 && (
                                <div className="mt-2 flex flex-wrap gap-2 xl:gap-[18px] xl:mt-[16px]">
                                    {store.news.tags.map((el) => (
                                        <div
                                            key={el.id}
                                            style={{ backgroundColor: "#" + el.color }}
                                            className=" text-[15px] px-2 rounded-xl font-semibold xl:text-[17px] xl:py-1 xl:px-3 dark:!bg-[#9171F8]"
                                        >
                                            #{el.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="mt-[16px]">
                                <div
                                    className="hidden xl:block mb-2 float-right ml-5"
                                    // это нужно, потому что в firefox float работает некорректно
                                    style={{ width: store.cardWidth }}
                                >
                                    <DesktopImagesBlock store={store} />
                                </div>
                                <div className="overflow-hidden w-full xl:hidden">
                                    <MobileDraggableCarousel store={store} />
                                </div>
                                <div
                                    className="font-medium mt-2 text-base whitespace-pre-wrap break-words xl:mt-0 xl:text-[17px] xl:leading-[120%]"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(store.news.text) }}
                                />
                            </div>
                        </div>
                        <div>
                            <Feedback news={store.news} store={store} />
                        </div>
                        <hr className="dark:border-[#717171]"/>
                        <div ref={commentsRef}>
                            <CommentsBlock store={store} commentsStore={commentsStore} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-5 flex justify-center">
                    <LoadingText />
                </div>
            )}
        </MainLayout>
    );
};

export default observer(ViewNews);
