import BaseProfile from "../components/BaseProfile";
import { useState, useEffect, useContext } from "react";
import { MyProfileStore } from "../stores";
import { ProfileContext } from "../context";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Colleagues from "./Colleagues";

const MyProfile = () => {
    const { domainStore, uiStore } = useContext(AuthContext);
    const [store] = useState(() => new MyProfileStore(domainStore));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                await store.fetchProfile();
            } catch {
                domainStore.logout();
                uiStore.reset();
                navigate("/login");
            }
            await store.fetchUserTags();
            await store.fetchColleagues();
        };
        fetchProfile();
    }, []);
    return (
        <ProfileContext.Provider value={{ store: store }}>
            <BaseProfile>
                <div className="grid grid-cols-1 sm:grid-cols-2 mt-5 xl:grid-cols-3 xl:mt-[40px]">
                    <div className="col-span-1">
                        <Colleagues store={store} />
                    </div>
                </div>
            </BaseProfile>
        </ProfileContext.Provider>
    );
};

export default MyProfile;
