import { useRef, useState, useEffect, useContext, BaseSyntheticEvent } from "react";
import NewsInterface from "../interfaces";
import useImageLoad from "../../../../hooks/useImageLoad";
import { observer } from "mobx-react-lite";
import NewsContext from "../context";
import { useNavigate } from "react-router-dom";
import Feedback from "../../components/Feedback";
import DOMPurify from "dompurify";

interface NewsCardInterface {
    news: NewsInterface;
}

const NewsCard = ({ news }: NewsCardInterface) => {
    const [loaded] = useImageLoad(news.title_image);
    const ref = useRef<HTMLDivElement>(null);
    const [textOverflow, setTextOverflow] = useState(false);
    const context = useContext(NewsContext);
    const cardHeight = context.cardHeight;
    const cardRadius = context.cardRadius;
    const navigate = useNavigate();

    useEffect(() => {
        if (ref.current) {
            setTextOverflow(ref.current.scrollHeight !== ref.current.clientHeight);
        }
    }, []);

    const navigateToView = (e: BaseSyntheticEvent, id: number) => {
        e.preventDefault();
        navigate(`/news/view/${id}`);
    };

    return (
        <div style={{ height: cardHeight, borderRadius: `${cardRadius}px` }}>
            <a
                className={`overflow-hidden flex justify-center border-b-[1px] dark:border-none relative ${
                    !loaded && "animate-pulse bg-slate-200 cursor-wait"
                }`}
                style={{
                    height: cardHeight / 2,
                    borderRadius: `${cardRadius}px ${cardRadius}px 0px 0px`,
                }}
                href={`/news/view/${news.id}`}
                onClick={(e) => navigateToView(e, news.id)}
            >
                {loaded && <img src={news.title_image} className="absolute object-cover w-full h-full blur-lg -z-10" />}
                {loaded && <img src={news.title_image} />}
            </a>
            <div
                className="pb-[22px] pt-[13px] px-[24px] flex flex-col justify-between bg-white dark:dark-surface-300"
                style={{
                    height: cardHeight / 2,
                    borderRadius: `0 0 ${cardRadius}px ${cardRadius}px`,
                }}
            >
                <div>
                    <div className="font-bold text-[17px] text-nowrap overflow-hidden text-ellipsis">
                        <a href={`/news/view/${news.id}`} onClick={(e) => navigateToView(e, news.id)}>
                            {news.title}
                        </a>
                    </div>
                    <div ref={ref} className="max-h-[85px] overflow-hidden relative dark:dark-text-secondary">
                        <div
                            className="text-[15px] font-medium"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.text) }}
                        />
                        {textOverflow && (
                            <div className="absolute bottom-0 left-0 h-[60px] bg-gradient-to-b from-white/0 to-white/100 dark:from-[#3F3F3F]/0 dark:to-[#3F3F3F]/100 w-full"></div>
                        )}
                    </div>
                </div>
                <div className="w-full flex justify-start">
                    <Feedback news={news} store={context.store} />
                </div>
            </div>
        </div>
    );
};

export default observer(NewsCard);
