import { ALLOWED_VIDEO_FORMATS } from "./constants";

interface GetFileFormatReturnInterface {
    src: string;
    filename: string;
    format: string;
}

export const getFileFormat = (file: string): GetFileFormatReturnInterface => {
    const src = file;
    const reMatch = file.match(/get-media\/(.+)\?/);
    const videoRe = new RegExp(`${ALLOWED_VIDEO_FORMATS.map((el) => `(${el})`).join("|")}$`, "i");

    let format: "video" | "image";
    if (reMatch && videoRe.test(reMatch[1].toLowerCase())) {
        format = "video";
    } else {
        format = "image";
    }
    return { src: src, filename: reMatch ? reMatch[1] : "", format: format };
};
