import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useRef, MouseEvent, BaseSyntheticEvent, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import VideocamIcon from "@mui/icons-material/Videocam";
import { getFileFormat } from "../common/utils";

interface DbAttachmentInterface {
    id: number;
    image: string;
    news: number;
}

interface AttachmentsInterface {
    images: Array<File | DbAttachmentInterface>;
    setImages: Function;
    formDisabled?: boolean;
    maxImages?: number;
}

interface ImageElementInterface {
    src: string;
}

interface VideoElementInterface {
    src: string;
}

const AttachmentsBlock = ({ images, setImages, formDisabled = false, maxImages = 10 }: AttachmentsInterface) => {
    const allowedImageFormats: Array<String> = ["jpg", "jpeg", "png", "svg"];
    const allowedVideoFormats: Array<String> = ["mp4", "mov"];
    const MAX_IMAGE_SIZE = Number(process.env.REACT_APP_MAX_IMAGE_SIZE);
    const attachmentsInputRef = useRef<any>(null);

    const handleAttachmentRemove = (e: MouseEvent, el: any) => {
        e.preventDefault();
        if (!formDisabled) {
            const dataTransfer = new DataTransfer();
            let newArr: any = [];

            Array.from(images).forEach((file: any) => {
                if (file.image && file.image !== el.image) {
                    newArr.push(file);
                } else if (file.name && file.name !== el.name) {
                    dataTransfer.items.add(file as File);
                    newArr.push(file);
                }
            });
            setImages(newArr);
            attachmentsInputRef.current.files = dataTransfer.files;
        }
    };

    const handleAttachmentAdd = (e: BaseSyntheticEvent) => {
        const dataTransfer = new DataTransfer();

        // добавим уже существующие
        let newArr = [...images];

        // добавим новые
        let sizeErr = false;
        for (const file of Array.from(e.target.files) as Array<File>) {
            if (newArr.length !== maxImages) {
                if (file.size <= MAX_IMAGE_SIZE * 10 ** 3) {
                    dataTransfer.items.add(file as File);
                    newArr.push(file);
                } else {
                    sizeErr = true;
                }
            } else {
                enqueueSnackbar(`У новости может быть до ${maxImages} вложений`);
                break;
            }
        }
        if (sizeErr) enqueueSnackbar(`Размер вложения должен быть до ${MAX_IMAGE_SIZE} Кб`);

        attachmentsInputRef.current.files = dataTransfer.files;
        setImages(newArr);
    };

    return (
        <div className="grid grid-cols-3 gap-2 xl:grid-cols-5">
            {Array.from(images).map((el: any, idx: number) => {
                let thumbnailEl;
                // Если уже загруженное вложение
                if (el.image) {
                    // Определим название файла и его тип,
                    const parsedFile = getFileFormat(el.image);
                    if (parsedFile.format === "video") {
                        thumbnailEl = <VideoElement src={el.image} />;
                    } else {
                        thumbnailEl = <ImageElement src={el.image} />;
                    }
                    // Если картинка
                } else if (el.type.startsWith("image")) {
                    thumbnailEl = <ImageElement src={URL.createObjectURL(el)} />;
                    // Если видева
                } else if (el.type.startsWith("video")) {
                    // const blob = new Blob([el], { type: "video/mp4" });
                    thumbnailEl = <VideoElement src={URL.createObjectURL(el)} />;
                }
                return (
                    <button
                        type="button"
                        key={idx}
                        className="bg-slate-200 border-2 relative"
                        onClick={(e: MouseEvent) => handleAttachmentRemove(e, el)}
                        disabled={formDisabled}
                    >
                        {thumbnailEl}
                        {!formDisabled && (
                            <div
                                className="absolute top-0 left-0 w-full h-full flex justify-center
                     items-center text-white transition duration-300 ease-out opacity-100 hover:opacity-100 cursor-pointer bg-gray-500/50 xl:bg-none xl:opacity-0 xl:hover:bg-gray-500/50"
                                title="Удалить"
                            >
                                <CloseIcon fontSize="large" />
                            </div>
                        )}
                    </button>
                );
            })}
            <button className="aspect-square" type="button">
                <label>
                    <div
                        className={`transition duration-500 ease-out h-full text-sm xl:text-base
                                      ${
                                          images.length !== maxImages && !formDisabled
                                              ? "cursor-pointer hover:bg-gray-300 opacity-100"
                                              : "cursor-default opacity-50"
                                      } rounded-md border-2 border-dashed
                                      border-black dark:border-white flex items-center justify-center flex-col`}
                        title={images.length === maxImages ? `Можно приложить до ${maxImages} картинок` : ""}
                    >
                        <div className="font-normal">Добавить</div>
                        <div className="font-normal">вложение</div>
                        <AddIcon />
                    </div>
                    <input
                        ref={attachmentsInputRef}
                        type="file"
                        accept={`${allowedImageFormats.map((el) => "." + el).join(", ")}, ${allowedVideoFormats
                            .map((el) => "." + el)
                            .join(", ")}`}
                        onChange={handleAttachmentAdd}
                        className="hidden"
                        multiple={true}
                        disabled={formDisabled || images.length === maxImages}
                    />
                </label>
            </button>
        </div>
    );
};

const ImageElement = ({ src }: ImageElementInterface) => {
    return <img src={src} className="object-cover aspect-square" />;
};

const VideoElement = ({ src }: VideoElementInterface) => {
    return (
        <div className="flex justify-center relative h-full aspect-square">
            <video
                src={src.startsWith("blob") ? src : src + "#t=0.001"}
                playsInline
                autoPlay={!!src.startsWith("blob")}
                muted
            />
            <div className="absolute left-1 bottom-1 rounded-full bg-white/80 p-1">
                <VideocamIcon />
            </div>
        </div>
    );
};

export default AttachmentsBlock;
