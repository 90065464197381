import { observer } from "mobx-react-lite";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { Menu, MenuItem } from "@mui/material";
import { useState, MouseEvent, useContext, BaseSyntheticEvent } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";

const View = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = {
        year: {
            text: "Год",
        },
        month: {
            text: "Месяц",
        },
    };

    const changeView = (e: BaseSyntheticEvent) => {
        myCalendarStore.setView(e.target.dataset.view);
        handleClose();
    };

    return (
        <div className="cursor-pointer" title="Вид">
            <div onClick={handleClick}>
                <SpaceDashboardIcon />
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {myCalendarStore.viewList.map((view) => (
                    <MenuItem onClick={changeView} key={view} data-view={view}>
                        {options[view].text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});

const Today = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;

    return (
        <div className="cursor-pointer" title="Сегодня" onClick={() => myCalendarStore.setDate(dayjs())}>
            <TodayIcon />
        </div>
    );
});

const Tools = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    return (
        <div className="flex gap-5 items-center">
            {myCalendarStore.viewList.length !== 0 && <View />}
            {myCalendarStore.showToday && <Today />}
        </div>
    );
};

export default observer(Tools);
