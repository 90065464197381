import { useState, useContext, useRef } from "react";
import { motion } from "framer-motion";
import SidebarContext from "./SidebarContext";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import AuthContext from "../../../context/AuthContext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

interface SubItemInterface {
    name: string;
    path: string;
}

interface SidebarItemProps {
    label: string;
    name?: string;
    icon: React.ReactNode;
    subItems?: Array<SubItemInterface>;
    onClick?: Function;
    disabled?: boolean;
}

const SidebarItem = ({ icon, subItems, onClick, name, label, disabled = false }: SidebarItemProps) => {
    const navigate = useNavigate();
    const { variants, sideBarState } = useContext(SidebarContext);
    const { uiStore } = useContext(AuthContext);
    const [itemState, setItemState] = useState(
        uiStore.sideBarSelectedItem === name && !subItems?.length ? "hover" : "initial"
    );

    const [subMenuState, setSubMenuState] = useState(uiStore.sideBarSelectedItem === name ? "expanded" : "collapsed");
    const subMenuRef = useRef<HTMLDivElement>(null);

    const itemVariants = {
        text: { initial: { color: "#FFFFFF" }, hover: { color: "#AB2121" } },
        back: {
            initial: { opacity: 0, x: -45 },
            hover: { opacity: 1, x: 0 },
        },
    };
    const subMenuVariants = {
        text: {
            collapsed: { height: "0px", overflow: "hidden" },
            expanded: { height: "auto" },
        },
        arrow: {
            collapsed: { rotate: "90deg" },
            expanded: { rotate: "0" },
        },
    };

    const clickItem = () => {
        if (onClick && !subItems?.length) {
            uiStore.setSideBarSelectedItem(name);
            onClick();
        } else if (subItems?.length) {
            uiStore.setSideBarSelectedItem(name);
            setSubMenuState(subMenuState === "collapsed" ? "expanded" : "collapsed");
        }
    };

    reaction(
        () => uiStore.sidebarState,
        (currSidebarState) => {
            if (currSidebarState === "collapsed") setSubMenuState("collapsed");
        }
    );

    return (
        <div>
            <motion.div
                className="flex items-center justify-between relative py-[5px] pr-[5px]"
                onClick={(e) => clickItem()}
                style={{ cursor: disabled ? "default" : "pointer", width: "95%" }}
                initial={{
                    color:
                        uiStore.sideBarSelectedItem === name && !subItems?.length
                            ? itemVariants.text.hover.color
                            : itemVariants.text.initial.color,
                }}
                animate={itemState}
                variants={itemVariants.text}
                onHoverStart={(e) => {
                    setItemState("hover");
                }}
                onHoverEnd={(e) => setItemState("initial")}
            >
                {!disabled && (
                    <motion.div
                        style={{ zIndex: -1, background: "rgb(177, 177, 177, .63)" }}
                        className="absolute w-[100%] h-[100%] rounded-r-[30px]"
                        variants={itemVariants.back}
                        animate={itemState}
                        initial={
                            uiStore.sideBarSelectedItem === name && !subItems?.length
                                ? itemVariants.back.hover
                                : itemVariants.back.initial
                        }
                        transition={{ ease: "easeOut" }}
                    />
                )}
                <div className={`ml-[18px] flex items-center ${disabled && "opacity-60"}`}>
                    <div>{icon}</div>
                    <motion.div
                        className="ml-[22px] text-[18px] font-normal text-white"
                        variants={variants}
                        animate={sideBarState}
                        initial={{ opacity: sideBarState === "collapsed" ? 0 : 1 }}
                        transition={{ ease: "easeOut" }}
                    >
                        {label}
                    </motion.div>
                </div>
                {subItems?.length && (
                    <motion.div
                        className="text-white"
                        variants={variants}
                        animate={sideBarState}
                        initial={{ opacity: sideBarState === "collapsed" ? 0 : 1 }}
                        transition={{ ease: "easeOut" }}
                    >
                        <motion.div
                            className="flex justify-center items-center"
                            initial={false}
                            variants={subMenuVariants.arrow}
                            animate={subMenuState}
                            transition={{ ease: "easeOut" }}
                        >
                            <ArrowForwardIosIcon fontSize="small" />
                        </motion.div>
                    </motion.div>
                )}
            </motion.div>
            {subItems?.length && sideBarState === "expanded" && (
                <motion.div
                    variants={subMenuVariants.text}
                    animate={subMenuState}
                    style={{ marginLeft: 72 }}
                    ref={subMenuRef}
                    initial={
                        uiStore.sideBarSelectedItem === name
                            ? subMenuVariants.text.expanded
                            : subMenuVariants.text.collapsed
                    }
                >
                    {subItems.map((item, idx) => (
                        <div
                            key={idx}
                            className="cursor-pointer py-2 hover:underline"
                            onClick={() => navigate(item.path)}
                        >
                            {item.name}
                        </div>
                    ))}
                </motion.div>
            )}
        </div>
    );
};

export default observer(SidebarItem);
