import { createContext } from "react";
import DomainStore from "../stores/domainStore";
import UIStore from "../stores/uiStore";

const domainStore = new DomainStore();
const uiStore = new UIStore();
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const contextData = {
        domainStore: domainStore,
        uiStore: uiStore,
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
