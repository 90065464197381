import ViewNewsStore from "../../store";
import ImagesModal from "./ImagesModal";
import { observer } from "mobx-react-lite";
import DesktopMediaElement from "./DesktopMediaElement";
import DesktopImagesCarousel from "./DesktopImagesCarousel";

const DesktopImagesBlock = ({ store }: { store: ViewNewsStore }) => {
    return (
        <div>
            <div>
                <DesktopImagesCarousel store={store} />
            </div>
            {store.imageList.length > 0 && (
                <div className="flex mt-[13px] gap-[13px] justify-center">
                    {store.imageList.slice(1, 6).map((el, idx) => {
                        const lastEl = idx === 4 || idx + 2 === store.imageList.length;
                        return (
                            <div
                                key={el.image}
                                className={`hover:cursor-pointer rounded-[8px] overflow-hidden flex justify-center border-[1px] dark:border-[#717171] basis-1/5 relative ${
                                    !el.loaded && "animate-pulse bg-slate-200 cursor-wait"
                                }`}
                                style={{
                                    height: 72,
                                }}
                                onClick={() => {
                                    store.setCurrImageIdx(idx + 1);
                                    store.setModalOpen(true);
                                }}
                            >
                                <DesktopMediaElement el={el} />
                                {lastEl && store.imageList.length > 6 && (
                                    <div className="absolute w-full h-full rounded-[8px] flex items-center justify-center text-[36px] font-bold bg-black/50 text-white z-20">
                                        +{store.imageList.length - 5}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
            <ImagesModal store={store} />
        </div>
    );
};

export default observer(DesktopImagesBlock);
