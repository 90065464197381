import { motion } from "framer-motion";


const LoadingScreen = () => {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="flex items-center justify-between bg-[#393939] rounded-lg gap-5 py-2 px-4 scale-150">
                <motion.div
                    animate={{ rotate: "360deg" }}
                    transition={{ ease: "linear", repeat: Infinity, duration: 1 }}
                    className="border-4 border-white  block rounded-full border-t-[#CC3B3B] size-8 dark:border-t-[#7A5AF5]"
                />
                <div>
                    <div>
                        <svg
                            width="138"
                            height="21"
                            viewBox="0 0 138 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M134.149 20.1301L129.349 12.7501L124.579 20.1301H120.919L127.609 10.0201L121.339 0.330078H124.999L129.379 7.32008L133.699 0.330078H137.329L131.179 10.0201L137.779 20.1301H134.149Z"
                                fill="white"
                            />
                            <path
                                d="M109.269 20.4601C107.689 20.4601 106.279 20.1301 105.039 19.4701C103.819 18.8101 102.859 17.8501 102.159 16.5901C101.459 15.3301 101.109 13.8401 101.109 12.1201V0.330078H104.289V12.3301C104.289 14.0101 104.749 15.3001 105.669 16.2001C106.609 17.1001 107.809 17.5501 109.269 17.5501C110.709 17.5501 111.889 17.1001 112.809 16.2001C113.729 15.3001 114.189 14.0101 114.189 12.3301V0.330078H117.369V12.1201C117.369 13.8401 117.019 15.3301 116.319 16.5901C115.619 17.8501 114.649 18.8101 113.409 19.4701C112.189 20.1301 110.809 20.4601 109.269 20.4601Z"
                                fill="white"
                            />
                            <path
                                d="M85.5723 0.330078H88.7523V17.2801H97.8123V20.1301H85.5723V0.330078Z"
                                fill="white"
                            />
                            <path
                                d="M69.0938 0.330078H81.8138V3.18008H72.2738V9.09008H80.1338V11.9701H72.2738V20.1301H69.0938V0.330078Z"
                                fill="white"
                            />
                            <path
                                d="M63.486 0.329999V20.46H62.406L50.346 6.72V20.13H47.166V0H48.276L60.306 13.68V0.329999H63.486Z"
                                fill="white"
                            />
                            <path
                                d="M32.337 20.46C30.437 20.46 28.687 20.01 27.087 19.11C25.507 18.21 24.257 16.98 23.337 15.42C22.417 13.86 21.957 12.13 21.957 10.23C21.957 8.33 22.417 6.6 23.337 5.04C24.277 3.48 25.537 2.25 27.117 1.35C28.697 0.449999 30.437 0 32.337 0C34.217 0 35.957 0.449999 37.557 1.35C39.157 2.25 40.417 3.48 41.337 5.04C42.277 6.6 42.747 8.33 42.747 10.23C42.747 12.13 42.277 13.86 41.337 15.42C40.417 16.98 39.157 18.21 37.557 19.11C35.957 20.01 34.217 20.46 32.337 20.46ZM32.367 17.55C33.707 17.55 34.917 17.24 35.997 16.62C37.077 15.98 37.927 15.1 38.547 13.98C39.167 12.86 39.477 11.6 39.477 10.2C39.477 8.84 39.167 7.6 38.547 6.48C37.927 5.36 37.077 4.49 35.997 3.87C34.917 3.23 33.707 2.91 32.367 2.91C31.007 2.91 29.787 3.23 28.707 3.87C27.627 4.49 26.777 5.36 26.157 6.48C25.557 7.58 25.257 8.82 25.257 10.2C25.257 11.6 25.557 12.86 26.157 13.98C26.777 15.1 27.627 15.98 28.707 16.62C29.787 17.24 31.007 17.55 32.367 17.55Z"
                                fill="white"
                            />
                            <path
                                d="M10.41 20.46C8.51 20.46 6.76 20.01 5.16 19.11C3.58 18.21 2.32 16.98 1.38 15.42C0.46 13.86 0 12.13 0 10.23C0 8.33 0.46 6.6 1.38 5.04C2.32 3.48 3.58 2.25 5.16 1.35C6.76 0.449999 8.51 0 10.41 0C12.23 0 13.88 0.41 15.36 1.23C16.86 2.05 18.04 3.16 18.9 4.56L16.5 6.33C15.8 5.23 14.94 4.39 13.92 3.81C12.9 3.21 11.74 2.91 10.44 2.91C9.1 2.91 7.88 3.23 6.78 3.87C5.7 4.49 4.85 5.36 4.23 6.48C3.61 7.6 3.3 8.85 3.3 10.23C3.3 11.61 3.61 12.86 4.23 13.98C4.85 15.1 5.7 15.98 6.78 16.62C7.88 17.24 9.1 17.55 10.44 17.55C11.74 17.55 12.9 17.26 13.92 16.68C14.94 16.08 15.8 15.23 16.5 14.13L18.9 15.9C18.06 17.3 16.89 18.41 15.39 19.23C13.91 20.05 12.25 20.46 10.41 20.46Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div>
                        <svg width="56" height="11" viewBox="0 0 56 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M54.3626 10.13L51.6926 6.06498L49.0076 10.13H47.6426L51.0476 5.05998L47.8826 0.22998H49.2326L51.6926 4.08498L54.1376 0.22998H55.4876L52.3676 5.07498L55.7276 10.13H54.3626Z"
                                fill="white"
                            />
                            <path
                                d="M38.2607 0.22998H44.6057V1.27998H39.4307V4.30998H43.3907V5.35998H39.4307V9.07998H44.7857V10.13H38.2607V0.22998Z"
                                fill="white"
                            />
                            <path d="M28.873 0.22998H30.043V9.07998H34.738V10.13H28.873V0.22998Z" fill="white" />
                            <path d="M23.1914 0.22998H24.3614V10.13H23.1914V0.22998Z" fill="white" />
                            <path
                                d="M19.5599 0.22998L15.2699 10.295H14.8349L10.5449 0.22998H11.8349L15.0599 7.99998L18.2699 0.22998H19.5599Z"
                                fill="white"
                            />
                            <path
                                d="M6.93 8.11994H2.07L1.23 10.1299H0L4.29 0.0649414H4.725L9.015 10.1299H7.77L6.93 8.11994ZM4.5 2.19494L2.52 7.06994H6.48L4.5 2.19494Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
