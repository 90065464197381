import { ImageListItemInterface } from "../../store";
import { getFileFormat } from "../../../common/utils";
import VideocamIcon from "@mui/icons-material/Videocam";

interface DesktopMediaElementInterface {
    el: ImageListItemInterface;
}

const DesktopMediaElement = ({ el }: DesktopMediaElementInterface) => {
    const parsedFile = getFileFormat(el.image);
    if (parsedFile.format === "image") {
        return (
            <div className="w-full h-full">
                {el.loaded && <img src={el.image} className="absolute object-cover w-full h-full blur-lg z-0" />}
                {el.loaded && (
                    <div className="w-full h-full flex justify-center absolute z-10">
                        <img src={el.image} />
                    </div>
                )}
            </div>
        );
    }
    return (
        <div className="flex justify-center relative h-full w-full bg-slate-200 dark:dark-surface-500">
            <video>
                <source src={el.image} type="video/mp4" />
            </video>
            <div className="absolute left-1 bottom-1 rounded-full bg-white/80 dark:dark-surface-300 px-0.5">
                <VideocamIcon fontSize="small" />
            </div>
        </div>
    );
};

export default DesktopMediaElement;
