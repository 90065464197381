import { MyProfileStore } from "../stores";
import { observer } from "mobx-react-lite";
import ProfileImageSkeleton from "../../../components/skeletons/ProfileImageSkeleton";
import { useNavigate } from "react-router-dom";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.css"

interface ColleaguesInterface {
    store: MyProfileStore;
}

const Colleagues = ({ store }: ColleaguesInterface) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <div
            className={`w-full h-full p-[18px] rounded-[28px] xl:p-[23px] ${
                store.colleaguesLoading ? "bg-slate-200 animate-pulse cursor-wait" : "bg-white dark:dark-surface-300"
            }`}
        >
            {!store.colleaguesError && (
                <div className="flex flex-col h-full">
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-base xl:text-lg">Мои коллеги</div>
                        <div
                            className="bg-[#C03A3A] rounded-full text-white flex justify-center items-center p-1.5 cursor-pointer dark:dark-primary-300"
                            onClick={() => setOpen(true)}
                        >
                            <OpenInFullRoundedIcon fontSize="small" className="!text-[15px] xl:!text-[20px]" />
                        </div>
                        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                            <DialogTitle>
                                <div className="flex justify-between items-center">
                                    <div className="font-bold xl:text-[24px]">Мои коллеги</div>
                                    <IconButton onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div className="flex flex-col gap-4">
                                    {store.colleagues.map((el, idx) => {
                                        return (
                                            <div key={el.id} className="flex items-center gap-3">
                                                <div
                                                    className="cursor-pointer shrink-0"
                                                    onClick={() => navigate(`/users/profile/${el.id}`)}
                                                >
                                                    <ProfileImageSkeleton image={el.profile.thumbnail} className="w-[50px] text-[50px]" />
                                                </div>
                                                <div>
                                                    <div
                                                        className="font-bold text-sm cursor-pointer xl:text-[16px] colleague-row"
                                                        onClick={() => navigate(`/users/profile/${el.id}`)}
                                                    >
                                                        {el.last_name} {el.first_name}
                                                    </div>
                                                    <div className="font-normal text-xs text-[#BCBCBC] xl:text-[14px] colleague-row">
                                                        {el.profile.position?.name}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div className="flex justify-center items-center h-full basis-full mt-2">
                        {store.colleagues.slice(0, 5).map((el, idx) => {
                            const size = 65;
                            return (
                                <div
                                    key={el.id}
                                    style={{ marginLeft: idx !== 0 ? -size / 3 : 0 }}
                                    title={`${el.last_name} ${el.first_name}`}
                                    className="cursor-pointer"
                                    onClick={() => navigate(`/users/profile/${el.id}`)}
                                >
                                    <ProfileImageSkeleton image={el.profile.thumbnail} className="w-[65px] text-[65px]" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(Colleagues);
